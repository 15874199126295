import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import KeenSlider, { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider';
import { Subject, takeUntil } from 'rxjs';
import { Image } from 'src/app/models/image';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: [
    './image-editor.component.scss',
    '../../../../node_modules/keen-slider/keen-slider.min.css'
  ],
})
export class ImageEditorComponent implements OnInit {
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  @ViewChild('thumbnailRef') thumbnailRef!: ElementRef<HTMLElement>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('fileInput2') fileInput2!: ElementRef;

  selectedIndex!: number;

  slides: string[] = ['1', '2', '3', '4', '5', '6'];

  isLoadingImages: boolean = false;

  public imagesUrl: Image[] = [];

  slider: KeenSliderInstance = null!;
  thumbnailSlider: KeenSliderInstance = null!;
  isLoadingImg: boolean = true;
  isStandAlone: boolean = false;
  isEditionMode: boolean = false;
  isLoading: boolean = false;
  confirmation!: ConfirmationService;

  private destroy$ = new Subject<boolean>();

  selectedImage: string | ArrayBuffer | null = null;

  constructor(
    private _datasheetService: DatasheetService,
    private _analyticsService: AnalyticsService,
    private _dataStorage: DataStorageService,
    private _translate: TranslateService,
    private _snack: SnackbarService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.isLoadingImages = true;
    this.setImageArray();
    this.isLoadingImages = false;

    this._datasheetService.isaMetaChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(isaMeta => {
        this.setImageArray();
      });

    this._datasheetService.imageChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
        this.setImageArray();
        this.renderize();
      });

    this.isStandAlone = this._datasheetService.getDatasheet().isStandAlone() || false;
  }

  private setImageArray() {
    this.imagesUrl = this._datasheetService.getModelImages();

    if( !this.imagesUrl.length ) {
      this._datasheetService.addImage({
        "id" : "",
        "location" : "",
        "url" :"",
        "format" : "",
        "width" : 0,
        "height" : 0,
      });
    }
  }

  ngAfterViewInit() {
    if( this.imagesUrl.length ) {
      this.initializeSliders();
    }
  }

  initializeSliders() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement);
    this.thumbnailSlider = new KeenSlider(
      this.thumbnailRef.nativeElement,
      {
        initial: 0,
        slides: {
          perView: 4.5,
          spacing: 10,
        },
      },
      [this.ThumbnailPlugin(this.slider)]
    );
  }

  ngOnDestroy() {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
    return (slider: KeenSliderInstance) => {
      function removeActive() {
        slider.slides.forEach((slide) => {
          slide.classList.remove('active');
        });
      }
      function addActive(idx: number) {
        slider.slides[idx].classList.add('active');
      }

      function addClickEvents() {
        slider.slides.forEach((slide, idx) => {
          slide.addEventListener('click', () => {
            main.moveToIdx(idx);
          });
        });
      }

      slider.on('created', () => {
        addActive(slider.track.details.rel);
        addClickEvents();
        main.on('animationStarted', (main) => {
          removeActive();
          const next = main.animator.targetIdx || 0;
          addActive(main.track.absToRel(next));
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
        });
      });
    };
  }

  updateSliders() {
    if (this.thumbnailSlider) {
      this.thumbnailSlider.destroy();
    }
    if (this.slider) {
      this.slider.destroy();
    }
    if( this.imagesUrl.length ) {
      this.initializeSliders();
    }
  }

  onImageError(event: any) {
    event.target.src = '/assets/images/homeImgPlaceholder.svg';
    this.isLoadingImg = false;
  }

  hideImageLoader() {
    this.isLoadingImg = false;
  }

  onFileSelected(event: Event, isFirst: boolean = false) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];

      let fileExtension = file.name.split('.').pop();
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.onload = async () => {
          // Fazer uma verificação adicional antes de adicionar a imagem
          if (!this.verifyImagesCount()) {
            this.showMaxImagesAlert();
            return;
          }

          const img: Image = {
            id: uuidv4(),
            location: "FW",
            url: reader.result as string,
            format: fileExtension!,
            width: image.width,
            height: image.height,
          };

          this._datasheetService.addImage(img, isFirst ? 0 : -1);

          if (this._datasheetService.getDatasheetUid()) {
            this.isLoading = true;
            try {
              const countUpld = await this._dataStorage.uploadImages(
                this._datasheetService.getDatasheet()
              );
              
              if (countUpld > 0) {
                this._datasheetService.notifyAddImage();
                await this.syncImageIsa();
                this.isLoading = false;
              } else {
                this.isLoading = false;
              }
            } catch (error) {
              this.isLoading = false;
              this._snack.failMessage(this._translate.instant('imageEditor.failUpload'));
            }
          } else {
            this._datasheetService.notifyAddImage();
          }

          this._analyticsService.trackSingleEvent("addImage");
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
    input.value = '';
  }

  renderize() {
    setTimeout(() => {
      this.updateSliders();
      this.emitResizeEvent();
    }, 50);
  }

  onAddImage(isFirst: boolean = false) {

    if( isFirst ) {
      this.fileInput2.nativeElement.click();
      return;
    }

    if (this.verifyImagesCount()) {
      this.fileInput.nativeElement.click();
    } else {
      this.showMaxImagesAlert();
    }
  }

  emitResizeEvent() {
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }

  onEditionMode() {
    this.isEditionMode = !this.isEditionMode;
  }

  onMove( index: number, direction: number ) {
    this._datasheetService.moveImage( index, direction );
    this._analyticsService.trackSingleEvent("moveImage");
    this.syncImageIsa();
  }

  async syncImageIsa(){
    const ds = this._datasheetService.getDatasheet();
    await this._dataStorage.syncIsaMeta(`${ds.fw?.isa_model_id}`, null, null, null, ds.getModelImages());
  }

  onDelete( imgUid: string, index: number) {
    this._datasheetService.markImageAsDeleted( imgUid );
    this.syncImageIsa();

    this._analyticsService.trackSingleEvent("removeImage");
  }

  verifyImagesCount(): boolean{
    if (!this._datasheetService.getDatasheet().verifyImagesCount()) {
      this._confirmationService.confirm({
        message: this._translate.instant('propertyEditorPage.maxImagesDialog.message2'),
        header: this._translate.instant('propertyEditorPage.maxImagesDialog.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: this._translate.instant('propertyEditorPage.maxImagesDialog.acceptLabel'),
        rejectButtonStyleClass: "hidden",
        accept: () => {
          this.confirmation.close();
        },
      });
      return false;
    }else{
      return true;
    }
  }

  showMaxImagesAlert() {
    this._confirmationService.confirm({
      message: this._translate.instant('propertyEditorPage.maxImagesDialog.message2'),
      header: this._translate.instant('propertyEditorPage.maxImagesDialog.title'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: this._translate.instant('propertyEditorPage.maxImagesDialog.acceptLabel'),
      rejectButtonStyleClass: "hidden",
      accept: () => {
        this.confirmation.close();
      },
    });
  }

}
