import { ReferenceType } from "./enums/reference-type";
import defaultReferences, { Reference } from "./reference";
import { v4 as uuidv4 } from 'uuid';

export class ReferenceErpUtil {

  static prepareReferenceToUse( reference: Reference, erpType: string ) : Reference {
    reference.erp = {
      external_id: reference.uid,
      type: erpType as "finished_product" | "raw_material" | "activity" | "group" | "measure"
    }

    reference.uid = uuidv4();

    for( let field of reference!.fields ) {
      if( field.name !== "amount" ) {
        field.read_only = true;
      }
    }
    return reference;
  }

  static material( jsonErpObj : any, amount: number ): Reference {
    const price: number = +jsonErpObj['value'] || 0;
    let material = JSON.parse( JSON.stringify(defaultReferences.material) );

    if( !jsonErpObj.hasOwnProperty('color') ) {
      jsonErpObj['color'] = "";
    }
    const colorIsString = typeof( jsonErpObj['color'] ) == 'string';
    let color: any = "";
    if( colorIsString ) {
      color = jsonErpObj['color'];
    } else {
      const isRgb = jsonErpObj['color'].hasOwnProperty('rgb');
      const isCmyk = jsonErpObj['color'].hasOwnProperty('cmyk');
      let colorValue: string = isRgb ? jsonErpObj['color']['rgb'] : isCmyk ? jsonErpObj['color']['rgb'] : "";
      let colorType = isCmyk ? 'CMYK' : 'RGB';
      color = {
        "name": jsonErpObj['color']['value'] || '',
        "type": colorType,
        "value": colorValue?colorValue.startsWith("#") ? colorValue : colorValue?"#"+colorValue : '' : '',
        "alpha": "255"
      }
    }

    material.uid = jsonErpObj['uid'];
    /*code*/     material.fields[0].value = jsonErpObj['name'];
    /*amount*/   material.fields[1].value = amount;
    if( colorIsString ) {
      /*color*/    material.fields[2].value = color;
    } else {
      /*color*/    material.fields[2].color = color;
    }
    /*cost*/     material.fields[3].value = +jsonErpObj['value'] || 0;
    /*desc*/     material.fields[4].value = jsonErpObj['description'];
    /*group*/    material.fields[5].value = jsonErpObj['product_group'];
    /*obs*/      material.fields[6].value = jsonErpObj['notes'];
    /*supplier*/ material.fields[7].value = jsonErpObj['supplier'];
    /*total*/    material.fields[8].value = Math.round(price * amount * 100) / 100;
    /*um*/       material.fields[9].value = jsonErpObj['measure_unit'];
    return ReferenceErpUtil.prepareReferenceToUse(material, "raw_material");
  }

  static activity( jsonErpObj : any, amount: number ): Reference {
    const price: number = +jsonErpObj['value'] || 0;
    let activity = JSON.parse( JSON.stringify(defaultReferences.activity) );
    activity.uid = jsonErpObj['uid'];
    /*code*/   activity.fields[0].value = jsonErpObj['name'];
    /*amount*/ activity.fields[1].value = amount;
    /*cost*/   activity.fields[2].value = price;
    /*desc*/   activity.fields[3].value = jsonErpObj['description'];
    /*machine*/activity.fields[4].value = jsonErpObj['machine'];
    /*obs*/    activity.fields[5].value = jsonErpObj['notes'];
    /*sector*/ activity.fields[6].value = jsonErpObj['sector'];
    /*total*/  activity.fields[7].value = Math.round(price * amount * 100) / 100;
    /*um*/     activity.fields[8].value = jsonErpObj['measure_unit'];
    return ReferenceErpUtil.prepareReferenceToUse(activity, "activity");
  }

  static measure( jsonErpObj : any, amount: number ): Reference {
    const price: number = +jsonErpObj['value'] || 0;
    let measure = JSON.parse( JSON.stringify(defaultReferences.measure) );
    /*code*/    measure.fields[0].value = jsonErpObj['name'];
    /*measure*/ measure.fields[1].value = jsonErpObj['value'];
    /*obs*/     measure.fields[2].value = jsonErpObj['notes'];
    return ReferenceErpUtil.prepareReferenceToUse(measure, "measure");
  }

  static generic( jsonErpObj : any, amount: number ): Reference {
    const price: number = +jsonErpObj['value'] || 0;
    let generic = JSON.parse( JSON.stringify(defaultReferences.generic) );
    /*code*/ generic.fields[0].value = jsonErpObj['name'];
    /*desc*/ generic.fields[1].value = jsonErpObj['description '];
    return ReferenceErpUtil.prepareReferenceToUse(generic, "group");
  }

  static defaultAttrNames( catType: string) : string[] {
    switch (catType) {
      case 'measures':
        return ['code', 'measure', 'obs', 'erpURL', 'type', 'name', 'value', 'notes', 'last_modified'];
      case 'materials':
        return ['code', 'amount', 'color', 'cost', 'desc', 'group', 'obs', 'supplier', 'total', 'um',
                'erpURL', 'type', 'name', 'description', 'notes', 
                'value', 'measure_unit', 'product_group', 'supplier', 'last_modified'
              ];
      case 'activities':
        return [ 'code', 'amount', 'cost', 'desc', 'machine', 'obs', 'sector', 'total', 'um',
                'erpURL', 'type', 'name', 'description', 'notes', 'images',
                'value', 'total', 'sector', 'machine', 'supplier',  'last_modified', 
                'measure_unit', 'colors',  
        ];
      case 'generics':
        return [ 'code', 'desc', 'erpURL', 'type', 'uid', 'name', 'last_modified'];
      default:
        return [
          'type', 'uid', 'name', 'reference', 'description', 'notes', 'time',
          'value', 'total', 'sector', 'machine', 'product_group', 'supplier',
          'measure_unit', 'images', 'values', 'order', 'collection', 'responsible',
          'erpURL'
        ];
    }
  }

  static customFieldType( value: any ) : string {
    switch (typeof value) {
      case "bigint": return "Number";
      case "string": return "Text";
      case "boolean": return "Boolean";
      case "number": return "FloatingNumber";
      default: return "Text";
    }
  }

  static categoryType( erpType: any ) : string {
    switch (erpType) {
      case "raw_material": return "materials";
      case "activity": return "activities";
      case "measure": return "measures";
      case "group": return "group";
      case "any": return "generics";
      default: return "materials";
    }
  }

  static referenceType( erpType: any ) : ReferenceType {
    switch (erpType) {
      case ReferenceType.Material: return ReferenceType.Material;
      case ReferenceType.Activity: return ReferenceType.Activity;
      case ReferenceType.Measures: return ReferenceType.Measures;
      case ReferenceType.Free: return ReferenceType.Free;
      default: return ReferenceType.Material;
    }
  }

}
