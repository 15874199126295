import defaultGarment, { Garment } from "./garment";
import defaultCategory, { Category } from "./category";
import defaultVariant, { Variant } from "./variant";
import { CategoryField } from "./category";
import { Field } from "./field";
import { Image } from "./image";
import { Reference } from "./reference";
import { isArray, number } from "mathjs";
import { Group } from "./groups";
import { Variation, VariationItem, VariationLevel } from "./Variation";
import { v4 as uuidv4 } from 'uuid';
import { ReferenceTarget } from "./referenceTarget";
import { ReferenceType } from "./enums/reference-type";

import { CustomFieldCreatorData, GeneretedCustomField } from "../components/custom-field-creator/custom-field-creator.component";

const TOTAL_LABEL = 'total';
const TOTAL_GARMENT_LABEL = 'max_total';

export interface ErpDiff {
  reference: Reference,
  erpRef : any,
  fieldsFw: Field[],
  fieldsErp: Field[],
}

export interface FW{
  datasheet_uid: string;
  datasheet_revision: number;
  datasheet_version: string;
  standalone?: boolean;
  lockedBy? : string,
  lockedAt? : string,
  lockedSystem? : string,
  isa_model_id: number;
  isa_company: string;
  isa_company_id: number;
  isa_company_cardcode: string;
  isa_shape: string;
  isa_owner_user: string;
  isa_kanbam: string;
  is_ideax_updated?: boolean;
  is_isa_unlocked?: boolean;
  currency?: string;
}

export interface MeasureType{
  area   : string;
  length : string;
  weight : string;
}

export class Datasheet {
  uid: string = '';
  fw?: FW;
  measure_units?: MeasureType;
  format_version: string = '';
  version: string = '';
  revision: number = 0;
  creation_user: string = '';
  creation_date: string = '';
  language?: string = '';
  last_modification_user: string = '';
  last_modification_date: string = '';
  variant_main_fields: string[] = [];
  categories: Category = {} as Category;
  garment: Garment = {} as Garment;

  constructor(params?: Partial<Datasheet>) {
    if( params ) {
      Object.assign(this, params);
      if( this.fw && !this.fw.hasOwnProperty("standalone")) {
        this.fw.standalone = false;
      }
    } else {
      this.fw = {
        datasheet_uid: "",
        datasheet_version: "FW-1.0.0",
        datasheet_revision: 8,
        standalone: true,
        isa_model_id: 0,
        isa_company: "",
        isa_company_id: 0,
        isa_company_cardcode: "",
        isa_shape: "",
        isa_owner_user: "",
        isa_kanbam: ""
      }

      this.categories = defaultCategory;
      this.garment = JSON.parse( JSON.stringify(defaultGarment) );
    }
  }

  public updateTotals = (): Datasheet => {
    this.updateAllTotalFields();

    console.log('>> DatasheetUtil.BeforeCalc', this);
    return this;
  }


  public getAllVariantsFromDatasheet = (): Variant[] => {
    const allVariants: Variant[] = [];

    const loopIntoVariantList = (level: any) => {
      if(level.hasOwnProperty('variants')) {
        for(let variant of level['variants']||[]) {
          allVariants.push(variant);
          loopIntoVariantList(variant);
        }
      }
      if(level.hasOwnProperty('sub-variants')) {
        for(let subVariant of level['sub-variants']||[]) {
          allVariants.push(subVariant);
          loopIntoVariantList(subVariant);
        }
      }
    }
    loopIntoVariantList(this.garment);

    return allVariants;
  }

  public getVariation() : Variation {

    const level0: Variant[] = [];
    const level1: Variant[] = [];
    const level2: Variant[] = [];

    let variation: Variation = {
      itens: [],
      level: VariationLevel.SINGLE
    };

    for( let variant of this.garment.variants ) {

      level0.push(variant);
      let x : VariationItem = {
        name: this.getField('code', variant.fields).value as string,
        variant: variant,
        category: this.variant_main_fields[0],
        children: [],
      }
      variation.level = this.garment.variants.length == 1 ? VariationLevel.SINGLE : VariationLevel.ONE;

      for( let subVar of variant['sub-variants'] || [] ) {
        level1.push( subVar);

        let y : VariationItem = {
          name: this.getField('code', subVar.fields).value as string,
          variant: subVar,
          category: this.variant_main_fields[1],
          children: [],
        }
        x.children!.push(y);
        variation.level = VariationLevel.TWO;

        for( let subSubVar of subVar['sub-variants'] || [] ) {
          level2.push( subSubVar);
          let z : VariationItem  = {
            name: this.getField('code', subSubVar.fields).value as string,
            category: this.variant_main_fields[2],
            variant: subSubVar,
            children: [] as any [],
          }
          y.children!.push(z);
          variation.level = VariationLevel.THREE;
        }
      }

      variation.itens.push(x);
    }

    return variation;
  }

  public getVariantList( level: number) : Variant[] {

    const levelvars: Variant[] = [];

    for( let variant of this.garment.variants ) {
      if( level == 1 ) {
        variant.level = 1;
        levelvars.push(variant);
        continue;
      }
      for( let subVar of variant['sub-variants'] || [] ) {
        if( level == 2 ) {
          subVar.level = 2;
          subVar.parentName = variant.fields[0].value as string;
          levelvars.push( subVar );
          continue;
        }

        for( let subSubVar of subVar['sub-variants'] || [] ) {
          if( level == 3 ) {
            subSubVar.level = 3;
            subSubVar.parentName = variant.fields[0].value + " - " + subVar.fields[0].value;
            levelvars.push( subSubVar );
          }
        }
      }
    }

    return levelvars;
  }

  private filterVariants(variants: Variant[], uid: string): Variant[] {
    return variants
      .filter(variant => variant.uid !== uid)
      .map(variant => ({
        ...variant,
        "sub-variants": variant["sub-variants"]
          ? this.filterVariants(variant["sub-variants"], uid)
          : variant["sub-variants"]
      }));
  }

  public deleteVariant( uid: string ) {
    this.garment.variants = this.filterVariants(this.garment.variants, uid);
  }

  public getUpdatedVariant = (uid: string): Variant | undefined => {
    const allVariants: Variant[] = this.getAllVariantsFromDatasheet();

    return allVariants.find((variant: Variant) => variant.uid === uid);
  }

  public getField = (name:string, fields: Field[]): Field => {
    for (let field of fields) {
      if (field.name == name){
        return field;
      }
    }

    // this case NEVER must happens
    const none: Field = {
      name: name,
      read_only: true,
      value: ''
    };
    return none;
  }

  public getCategoryField = (name:string, fields: CategoryField[]): CategoryField => {
    for (let field of fields) {
      if (field.name == name){
        return field;
      }
    }

    // this case NEVER must happens
    const none: CategoryField = {
      name: name,
      label: '',
      type: 'string',
      custom: true,
      options: [],
      properties: {},
    };
    return none;
  }

  public getCategoryFieldFromPath = (name:string, fieldPath: string): CategoryField => {

    if( fieldPath.indexOf("materials") >= 0  ) {
      return this.getCategoryField(name, this.categories.material.fields);
    } else if( fieldPath.indexOf("activities") >= 0  ) {
      return this.getCategoryField(name, this.categories.activity.fields);
    } else if( fieldPath.indexOf("measures") >= 0  ) {
      return this.getCategoryField(name, this.categories.measures.fields);
    } else if( fieldPath.indexOf("generics") >= 0  ) {
      return this.getCategoryField(name, this.categories.free.fields);
    } else if( fieldPath.indexOf("variants") >= 0  ) {
      return this.getCategoryField(name, this.categories.variant.fields);
    } else {
      return this.getCategoryField(name, this.categories.garment.fields);
    }
  }

  public getFieldValue = (name: string, fields: Field[], colorAsObj: boolean = false): any => {
    for (let field of fields||[]) {
      if (field.name === name) {
        if (field.hasOwnProperty('color') && field.color !== null) {
          return colorAsObj ? field.color : String(field.color!.name);
        }
        if (field.hasOwnProperty('value')) {
          return String(field.value);
        }
      }
    }
    return "";
  };

  public getFields = (fields: Field[], defaultFields: CategoryField[], hasDefault: boolean, hasCustom: boolean): Field[] => {
    let res = []
    for (let field of fields) {
      let cpField = Object.assign({}, field);
      for (let defField of defaultFields) {
        if (cpField.name == defField.name) {
          if (hasDefault && !defField.custom) {
            res.push( this.joynner(cpField, defField) );
            break;
          }
          if (hasCustom && defField.custom) {
            res.push( this.joynner(cpField, defField) );
            break;
          }
        }
      }
    }

    return res;
  }

  private joynner = (from: any, to: any): any => {
    let opt : any[] = [];

    if( to.hasOwnProperty('options') && !from.hasOwnProperty('options')) {
      from['options'] = [];
    }

    if(from.options &&  to.options)
      opt = [...from.options!, ...to.options!];

    let joyed = Object.assign(from, to);

    if(joyed.options)
      joyed.options = opt;

    return joyed;
  }

  public getDefaultFields = (fields: Field[], defaultFields: CategoryField[]): Field[] => {
    return this.getFields(fields, defaultFields, true, false);
  }

  public getCustomFields = (fields: Field[], defaultFields: CategoryField[]): Field[] => {
    return this.getFields(fields, defaultFields, false, true);
  }

  public getFieldsWithFormula = (fields: Field[]): Field[] => {
    return fields.filter((field: Field) => {
      return !!field.formula;
    });
  }

  public getImageFields = (myRef: Reference | Group ): Image[] => {

    let res: Image[] = [];

    if(myRef['images']) {
      res = myRef.images!;
    }

    const group = (myRef as Group).patterns || (myRef as Group).markers || [];
    if( group.length ) {
      for ( let sref of group ) {
        if( sref.images && sref.images.length) {
          res.push( ...sref.images! )
        }
      }
    }

    return res;
  }

  public getValueFromFieldAsNumber( fieldName: string, fields: Field[]) : number {

    let base = this.getField(fieldName, fields).value || '';
    if(typeof base === 'number') {
      return base;
    }
    if(typeof base === 'string') {
      base = base.replace(",", ".");
    }
    base = number(base);
    if( isNaN(base) ) {
      return 0;
    }
    return base;
  }

  private sumRef( references: Reference[] ) {
    let sum = 0;
    for( let ref of references ) {
      let cost =   this.getValueFromFieldAsNumber('cost', ref.fields);
      let amount = this.getValueFromFieldAsNumber('amount', ref.fields);
      sum += cost * amount;
    }
    return sum;
  }

  private sumEnt( entity: Garment | Variant, accGarment: number = 0, type : SumType = SumType.Both, baseCost: number= 0) {
    let sum = this.getSumRefs(entity, type);

    let sumL2 = 0;   let countL2 = 0;
    let sumL3 = 0;   let countL3 = 0;
      if ("sub-variants" in entity) {
        for (let subVariant of entity['sub-variants'] || []) {
            countL2++;
            sumL2 += this.getSumRefs(subVariant, type);

            for (let subSubVariant of subVariant['sub-variants'] || []) {
                countL3++;
                sumL3 += this.getSumRefs(subSubVariant, type);
            }
        }
    }

    if( countL2 ) {
      sum = sum + (sumL2/countL2);
    }
    if( countL3 ) {
      sum = sum + (sumL3/countL3);
    }
    sum += accGarment
    if( "sub-variants" in entity && type == SumType.Both){
      this.getField(TOTAL_LABEL, entity.fields).value = sum ;
    }
    return sum;
  }

  private updateAllTotalFields() {
    let sumModel = this.sumEnt( this.garment );
    let base = this.getValueFromFieldAsNumber('base_cost', this.garment.fields);
    sumModel = sumModel + base;

    let biggestVariantTotal  = 0.0;
    let biggestMaterialTotal = 0.0;
    let biggestActivityTotal = 0.0;

    if( this.garment.variants.length == 0 ) {
      biggestMaterialTotal = this.getSumRefs(this.garment, SumType.Material);
      biggestActivityTotal = this.getSumRefs(this.garment, SumType.Activity);
      biggestVariantTotal = sumModel;
    }
    for( let variant of this.garment.variants ) {
      let total = this.sumEnt( variant, sumModel );
      let matTotal = this.sumEnt(variant, this.getSumRefs(this.garment, SumType.Material), SumType.Material);
      let acttotal = this.sumEnt(variant, this.getSumRefs(this.garment, SumType.Activity), SumType.Activity);

      if( total > biggestVariantTotal ) {
        biggestVariantTotal = total;
      }
      if( matTotal > biggestMaterialTotal ) {
        biggestMaterialTotal = matTotal;
      }
      if(acttotal > biggestActivityTotal){
        biggestActivityTotal = acttotal
      }
    }

    sumModel = biggestVariantTotal;
    this.getField(TOTAL_GARMENT_LABEL, this.garment.fields).value = Math.round(sumModel * 100) / 100;
    this.getField('mat_max_cost', this.garment.fields).value = Math.round(biggestMaterialTotal * 100) / 100;
    this.getField('act_max_cost', this.garment.fields).value = Math.round(biggestActivityTotal * 100) / 100;
  }

  private getSumRefs(entity: any, type: SumType){
    let value = 0;

    switch (type) {
        case SumType.Material:
            value += this.sumRef(entity.materials || []);
            break;
        case SumType.Activity:
            value += this.sumRef(entity.activities || []);
            break;
        default:
            value += this.sumRef(entity.materials || []);
            value += this.sumRef(entity.activities || []);
            break;
    }

    return value;
    }


  private applyRecursiveOnFields<T>(callback: (...args: any[]) => T, fields: Field[], categories: CategoryField[]): T[] {
    const accumulatedResults: T[] = [];
    fields.forEach(field => {
        categories.forEach(catField => {
            if (field.name === catField.name) {
              let res = callback(field, catField);
              if(res) {
                accumulatedResults.push( res );
              }
            }
        });
    });
    return accumulatedResults;
}

  private applyRecursiveOnReference<T>(onFields: boolean, erpType: string, callback: (...args: any[]) => T, references: Reference[], categories: CategoryField[]): T[] {
    const accumulatedResults: T[] = [];

    (references ?? []).forEach(reference => {

      if( reference.erp ) {
        reference.erp.type = erpType as "finished_product" | "raw_material" | "activity" | "group" | "measure" ;
      }
      if( onFields ) {
        const resultOnMaterial = this.applyRecursiveOnFields(callback, reference.fields, this.categories.material.fields);
        accumulatedResults.push(...resultOnMaterial);
      } else {
        let res = callback(reference);
        if(res) {
          accumulatedResults.push( res );
        }
      }

    });

    return accumulatedResults;
}

  private applyRecursiveOnGarmentOrVariant<T>(onFields: boolean, callback: (...args: any[]) => T, variantOrGarment: Variant | Garment, categories: CategoryField[]): T[] {
    const accumulatedResults: T[] = [];

    if( onFields ) {
      const resultOnFields = this.applyRecursiveOnFields(callback, variantOrGarment.fields, categories);
      accumulatedResults.push(...resultOnFields);
    }

    const resultOnMaterial = this.applyRecursiveOnReference(onFields, 'raw_material', callback, variantOrGarment.materials, this.categories.material.fields);
    accumulatedResults.push(...resultOnMaterial);

    const resultOnActivity = this.applyRecursiveOnReference(onFields, 'activity', callback, variantOrGarment.activities, this.categories.material.fields);
    accumulatedResults.push(...resultOnActivity);

    const resultOnGeneric = this.applyRecursiveOnReference(onFields, 'group', callback, variantOrGarment.generics, this.categories.material.fields);
    accumulatedResults.push(...resultOnGeneric);

    const resultOnMeasure = this.applyRecursiveOnReference(onFields, 'measure', callback, variantOrGarment.measures, this.categories.material.fields);
    accumulatedResults.push(...resultOnMeasure);

    if ("sub-variants" in variantOrGarment) {
        (variantOrGarment["sub-variants"] ?? []).forEach(variant => {
            const resultOnSubVariant = this.applyRecursiveOnGarmentOrVariant(onFields, callback, variant, categories);
            accumulatedResults.push(...resultOnSubVariant);
        });
    }

    return accumulatedResults;
  }

  public applyRecursive<T>(onFields: boolean, callback: (...args: any[]) => T, ...args: any[]): T[] {
    const accumulatedResults: T[] = [];

    const resultOnGarment = this.applyRecursiveOnGarmentOrVariant(onFields, callback, this.garment, this.categories.garment.fields);
    accumulatedResults.push(...resultOnGarment);

    this.garment.variants.forEach(variant => {
        const resultOnVariant = this.applyRecursiveOnGarmentOrVariant(onFields, callback, variant, this.categories.variant.fields);
        accumulatedResults.push(...resultOnVariant);
    });

    return accumulatedResults;
  }

  public getAllReferences() : Reference[] {
    return this.applyRecursive( false, ( reference: Reference ) => {
      return reference;
    });
  }

  public getAllErpReferences() {
    return this.applyRecursive( false, ( reference: Reference ) => {
      if ( reference.erp )
        return reference;

      return null;
    });
  }

  public getAllColorReferences() {
    return this.applyRecursive( true, ( field: Field, category: CategoryField ) => {
      if ( category.type == 'Color' ) {
        return field;
      }

      return null;
    });
  }

  private translateErpFieldsName( fieldName : string) : string {
    const defaultNames: any  = {
      "code" : "", /* ignore code field */
      "cost" : "value",
      "desc" : "description",
      "group" : "product_group",
      "obs" : "notes",
      "um" : "measure_unit",
    };
    if( defaultNames.hasOwnProperty(fieldName) ) {
      return defaultNames[fieldName];
    }
    return fieldName;
  }

  private tranlateCostField( color: string, size: string, erpRef: any) {
    /**
     * If erp data cames with variation, we need knows what is the variation used.
     * When erp refecence variate, on erp data must have a:
     *
     *    "colors" : [
     *        { "code": "054" , "description": "" }
     *     ],
     *
     *    "prices" : [
     *        { "color": "054", "price": 1.11, "size": "P" }
     *             or
     *        { "color": "054", "price": 1.11 }    -- size is optional
     *     ]
     *
     * Idea set teh color attr as "[054] red", so we need parse it
     */

    const regex = /\[(.*?)\]/;
    const match = regex.exec(color);

    let myColorId = null;
    if (match && match[1]) {
      myColorId = match[1];
    } else {
      return erpRef.hasOwnProperty('value') ? erpRef.value : null;
    }

    for( let price of erpRef.prices ) {
      if( price.color == myColorId && price.size == size ) {
        return price.price
      }
    }

    for( let price of erpRef.prices ) {
      if( price.color == myColorId ) {
        return price.price
      }
    }

    return erpRef.hasOwnProperty('value') ? erpRef.value : null;
  }

  private valueIsTheSame( left : string | number | boolean, right: any) : boolean {

    if ( typeof left == 'string' && right !== Object(right) ) {
      const leftAsNumber = Number(left);
      const rightAsNumber = Number(right);
      if( !isNaN(leftAsNumber) && !isNaN(rightAsNumber) ) {
        return leftAsNumber === rightAsNumber;
      }
    }

    if ( typeof left == 'boolean' && typeof right == 'string' ) {
      return left === (right.toLowerCase() === 'true');
    }


    return left == right;
  }

  public applyDiff( diff: ErpDiff ) {

    let erpRefs = this.getAllErpReferences();
    erpRefs = erpRefs.filter(item => item!.erp!.external_id === diff.reference.erp!.external_id);

    for( let ref of erpRefs ) {

      for( let fieldErp of diff.fieldsErp) {
        let myField = ref!.fields.find( obj => obj.name === fieldErp.name );

        if( myField && myField.hasOwnProperty('value') ) {
          myField.value = fieldErp!.value;
          if( fieldErp.hasOwnProperty('options') ) {
            myField.options = fieldErp!.options;
          }
        }

        if( myField && myField.hasOwnProperty('color') ) {
          myField.color!.value = fieldErp!.value as string;
        }

        if( myField!.name == "cost" || myField!.name == "amount" ) {
          let total = ref!.fields.find( obj => obj.name === "total" );
          let cost = ref!.fields.find( obj => obj.name === "cost" );
          let amount = ref!.fields.find( obj => obj.name === "amount" );
          if( cost && amount ) {
            total!.value = (cost.value as number) * ( amount.value as number);
            this.updateTotals();
          }
        }
      }
    }
  }

  public compare( references: Reference[], erpData: any[]) : ErpDiff[] {
    let diffs: ErpDiff[] = [];

    for( let reference of references) {
      let erpUid = reference.erp!.external_id || '';
      if( erpUid.indexOf(':') >= 0  ) {
        erpUid = erpUid.split(':')[1];
      }
      const erpRefs = erpData.filter(item => (item.uid === erpUid || item.code === erpUid) );

      if(erpRefs.length == 0) {
        continue;
      }

      const erpRef = erpRefs[0];

      let diff : ErpDiff = {
        reference,
        erpRef,
        fieldsFw : [],
        fieldsErp : []
      }

      for( let field of reference.fields) {

        let erpValue = null;
        const key = this.translateErpFieldsName(field.name);
        let newKey = key.replace(` - ${reference.erp!.external_id}`, '').trim();

        if (erpRef.hasOwnProperty(key)) {
          erpValue = erpRef[key];
        }

        else if (Array.isArray(erpRef.custom_fields)) {
          const customField = erpRef.custom_fields.find((item: any) => item.name === newKey);
          if (customField) {
            erpValue = customField;
          }
        }
        else if (erpRef.hasOwnProperty(newKey)) {
          erpValue = erpRef[newKey];

        }

        if( field.name === 'cost' ) {
          const color = this.getField('color', reference.fields);
          const colorName = color.color?.name ? color.color?.name : color.value as string;

          let size = this.getField('size', reference.fields).value as string;
          if( !size ) {
            const sizeFldName = `size - ${reference.erp?.external_id}`;
            size = this.getField(sizeFldName, reference.fields).value as string;
          }
          let erpHasColors = erpRef.hasOwnProperty('colors') && erpRef.colors.length > 0;
          let erpHasPrices = erpRef.hasOwnProperty('prices') && erpRef.prices.length > 0;
          let erpHasVariants = erpRef.variants && erpRef.variants.length > 0;

          /* for millenial erp */
          if( erpRef.hasOwnProperty('value_') ) {
            erpValue = erpRef.value_;
          }

          if( erpHasVariants ) {
            for( const erpVar of erpRef.variants ) {

              const erpColorName = erpVar.color?.value || "";
              const erpSize = erpVar.size || "";

              if( colorName && size ) {
                if( colorName == erpColorName && size == erpSize ) {
                  erpValue =  erpVar.value;
                  break;
                }
              } else if( colorName && !size ) {
                if( colorName == erpColorName ) {
                  erpValue =  erpVar.value;
                  break;
                }
              } else if( !colorName && size ) {
                if( size == erpSize ) {
                  erpValue =  erpVar.value;
                  break;
                }
              }
            }
          } else if( erpHasColors && erpHasPrices && colorName ) {
            erpValue = this.tranlateCostField( colorName, size, erpRef );
          }
        }

        if (erpValue) {
          if (Array.isArray(erpValue) || typeof erpValue === 'object') {
            this.setCatFieldOptions(reference, erpValue, field.name);
          }

          if (erpValue.options && erpValue.options.length > 0) {
            continue;
          }
          const valueToCompare = this.getErpValue(erpValue);

          if (!this.valueIsTheSame(field.value!, valueToCompare)) {
            diff.fieldsFw.push(field);
            let edited = Object.assign({}, field);
            if (edited.hasOwnProperty('color')) {
              edited.color = valueToCompare;
            } else if (edited.hasOwnProperty('value')) {
              edited.value = valueToCompare;
            } else {
              edited['value'] = valueToCompare;
            }

            diff.fieldsErp.push(edited);
          }
          // console.log( '---name', field.name,
          //            '\n---  fw', field.value,
          //            '\n--- erp', erpValue,
          //            '\n--- ===', this.valueIsTheSame(field.value!, erpValue) ? 'igual' : 'diferente' );
        }
      }
      // console.log( '------------------- ' );
      if( diff.fieldsFw.length ) {
        diffs.push(diff);
      }
    }
    return diffs;
  }

  getErpValue(erpValue: any) {
    return (typeof erpValue === 'object' && erpValue !== null && 'value' in erpValue) ? erpValue.value : erpValue;
  }

  setCatFieldOptions( ref: any, erpRef: any,  value: any) {
    let path = this.getCategoryPath(ref.erp.type)
    let catFld = this.getCategoryField(value, path)

    if(catFld.options){
      catFld.options = erpRef.options
    }
  }

  private getCategoryPath(catType: string) {
    switch (catType) {
      case 'measure':
        return this.categories.measures.fields;
      case 'raw_material':
        return this.categories.material.fields;
      case 'activity':
        return this.categories.activity.fields;
      case 'generic':
        return this.categories.free.fields;
      default:
        return this.categories.garment.fields;
    }
  }

  public getAllReadOnlyReferences() {
    return this.applyRecursive( true, ( field: Field, category: CategoryField ) => {
      if ( field.read_only == false ) {
        return field;
      }

      return null;
    });
  }

  public setReadonly() {
    let fields = this.getAllReadOnlyReferences();
    for( let f of fields ) {
      f!.read_only = true;
    }
  }

  public hasVariants() {
    return this.garment.variants.length > 0;
  }

  public getCategoryDict() {

    let dict: any = {}
    for( let prop in this.categories ) {
      if (this.categories.hasOwnProperty(prop)) {
        const catType = this.categories[prop as keyof Category];

        for( let catField of catType!.fields ) {
          if( !dict.hasOwnProperty( catField.name ) ) {
            dict[catField.name] = catField.label;
          }
        }
      }
    }
    return dict;
  }

  public setIdeaxState(state: boolean){
    this.fw!.is_ideax_updated = state;
  }

  public steIsaUnlockState(state: boolean){
    this.fw!.is_isa_unlocked = state;
  }


  public createVariants( template: Variation) {

    if( !this.hasVariants() ) {
      this.garment.variants = []

      for( let item of template.itens || [] ) {
        let variant = item.variant;
        this.variant_main_fields.push(item.category);
        variant.fields[0].value = item.name;
        variant.uid = variant.uid || uuidv4();
        this.garment.variants.push(variant);

        for( let subItem of item.children || [] ) {
          let subVariant = subItem.variant;
          this.variant_main_fields.push(subItem.category);
          subVariant.uid = subVariant.uid || uuidv4();
          subVariant.fields[0].value = subItem.name;
          if( !variant.hasOwnProperty("sub-variants") ) {
            variant["sub-variants"] = []
          }

          let edtSubVar = variant["sub-variants"]!.find( svariant => svariant.uid === subVariant.uid );
          if( edtSubVar ) {
            edtSubVar.fields[0].value = subVariant.fields[0].value;
          } else {
            variant["sub-variants"]!.push(subVariant);
          }

          for( let subSubItem of subItem.children || [] ) {
            let subSubVariant = subSubItem.variant;
            this.variant_main_fields.push(subSubItem.category);
            subSubVariant.uid = subSubVariant.uid || uuidv4();
            subSubVariant.fields[0].value = subSubItem.name;
            if( !subVariant.hasOwnProperty("sub-variants") ) {
              subVariant["sub-variants"] = []
            }

            let edtSubSubVar = subVariant["sub-variants"]!.find( ssvariant => ssvariant.uid === subSubVariant.uid );
            if(edtSubVar) {
              edtSubSubVar = edtSubVar["sub-variants"]!.find( ssvariant => ssvariant.uid === subSubVariant.uid );
            }

            if( edtSubSubVar ) {
              edtSubSubVar.fields[0].value = subSubVariant.fields[0].value;
            } else {
              subVariant["sub-variants"]!.push(subSubVariant);
            }
          }

        }
      }

    }

  }

  public updateVariants( template: Variation ) : boolean {

    this.variant_main_fields = [];

    if( template.level == 0 ) {
      this.garment.variants = [];
      return true;
    }

    if( template.itens.length ) {
      this.variant_main_fields.push( template.itens[0].category );
      if( template.itens[0].children?.length ) {
        this.variant_main_fields.push( template.itens[0].children[0].category );
        if( template.itens[0].children[0].children?.length ) {
          this.variant_main_fields.push( template.itens[0].children[0].children[0].category );
        }
      }
    }

    const hasEdt = this.handleRemovedVariantsAndUpdateVariantNames(template);
    const hasAdd = this.handleAddNewVariants(template);
    return hasEdt || hasAdd;
  }

  private handleRemovedVariantsAndUpdateVariantNames( template: Variation ) : boolean {

    let removeVariantsUid = [];
    let hasChange = false;

    for( let variant of this.garment.variants ) {
      let itemVariant = template.itens.find( it => it.variant.uid == variant.uid );
      if( !itemVariant ) {
        removeVariantsUid.push( variant.uid );
        continue;
      }
      variant.fields[0].value = itemVariant.name;
      hasChange = hasChange || (variant.fields[0].value != itemVariant.name);

      for( let subVariant of variant["sub-variants"]||[] ) {
        if( template.level >= 2 && itemVariant.children!.length == 0 ) {
          removeVariantsUid.push( variant.uid );
          continue;
        }

        let itemSubVariant = itemVariant.children?.find( sit => sit.variant.uid == subVariant.uid );
        if( !itemSubVariant ) {
          removeVariantsUid.push( subVariant.uid );
          continue;
        }
        subVariant.fields[0].value = itemSubVariant.name;
        hasChange = hasChange || (subVariant.fields[0].value != itemSubVariant.name);

        for( let subSubVariant of subVariant["sub-variants"]||[] ) {
          if( itemSubVariant.children!.length == 0 ) {
            removeVariantsUid.push( subSubVariant.uid );
            continue;
          }

          let itemSubSubVariant = itemSubVariant.children?.find( ssit => ssit.variant.uid == subSubVariant.uid );
          if( !itemSubSubVariant ) {
            removeVariantsUid.push( subSubVariant.uid );
            continue;
          }
          subSubVariant.fields[0].value = itemSubSubVariant.name;
          hasChange = hasChange || (subSubVariant.fields[0].value != itemSubSubVariant.name);
        }
      }
    }

    for( const uid of removeVariantsUid ) {
      this.deleteVariant(uid);
      hasChange = true;
    }

    return hasChange;
  }

  private variationItemIsOk( level: number, currLevel: number, item: VariationItem) {

      switch (level) {
        case VariationLevel.ONE:
          return true;
        case VariationLevel.TWO:
        case VariationLevel.THREE:
          if( currLevel == level)
            return true;
          return item.children!.length > 0;

        default:
          return false;
      }
  }

  private handleAddNewVariants( template: Variation ) : boolean {

    let hasChange = false;
    let allVariantes = this.getAllVariantsFromDatasheet();

    for( let item of template.itens || [] ) {
      let variant = allVariantes.find( v => v.uid === item.variant.uid );

      if( !variant ) {
        // Add first level variants
        let isOk = this.variationItemIsOk( template.level, 1, item);

        if( template.level == VariationLevel.ONE || isOk ) {
          item.variant.fields[0].value = item.name;
          this.garment.variants.push( item.variant );
          hasChange = true;
        }
        for( let child of item.children || [] ) {
          if( !item.variant.hasOwnProperty('sub-variants') ) item.variant["sub-variants"] = [];
          child.variant.fields[0].value = child.name;
          item.variant["sub-variants"]!.push(child.variant);
          hasChange = true;
          for( let grandChild of child.children || [] ) {
            if( !child.variant.hasOwnProperty('sub-variants') ) child.variant["sub-variants"] = [];
            grandChild.variant.fields[0].value = grandChild.name;
            child.variant["sub-variants"]!.push(grandChild.variant);
            hasChange = true;
          }
        }
        continue;
      }

      for( let subItem of item.children || [] ) {
        let subVariant = allVariantes.find( v => v.uid === subItem.variant.uid );

        if( !subVariant ) {
          if( ! this.variationItemIsOk( template.level, 2, subItem) ) {
            continue;
          }
          let daddy = allVariantes.find( v => v.uid == item.variant.uid );
          if(!daddy) {
            continue;
          }
          let cloneVariant = JSON.parse( JSON.stringify(subItem.variant) );
          cloneVariant.uid = uuidv4();
          subItem.variant.uid = cloneVariant.uid;
          cloneVariant.fields[0].value = subItem.name;
          if( !daddy.hasOwnProperty("sub-variants") ) {
            daddy["sub-variants"] = [];
          }
          daddy["sub-variants"]!.push( cloneVariant );
          allVariantes.push(cloneVariant);
          hasChange = true;
        }

        for( let subSubItem of subItem.children || [] ) {
          let subSubVariant = allVariantes.find( v => v.uid === subSubItem.variant.uid );

          if( !subSubVariant ) {
            let granpa = allVariantes.find( v => v.uid == subItem.variant.uid );
            if(!granpa) {
              continue;
            }
            let cloneVariant = JSON.parse( JSON.stringify(subSubItem.variant) );
            cloneVariant.uid = uuidv4();
            cloneVariant.fields[0].value = subSubItem.name;
            if( !granpa.hasOwnProperty("sub-variants") ) {
              granpa["sub-variants"] = []
            }
            granpa["sub-variants"]!.push( cloneVariant );
            hasChange = true;
          }
        }
      }
    }

    return hasChange;
  }

  public isStandAlone() {
    return this.fw?.standalone;
  }

  public createCustomField(data: CustomFieldCreatorData) : [Field, CategoryField]|[] {

    if( data.parentFields.some(item => item.name.toLocaleLowerCase() === data.field.name.toLocaleLowerCase()) ) {
      return [];
    }

    const field: Field = {
      name: data.field.name,
      read_only: data.field.read_only || false,
      value: data.field.value || ""
    }

    data.parentFields.push(field);

    let catField: CategoryField = {
      name: data.field.name,
      label: data.label || data.field.name || "",
      custom: true,
      options: data.field.values,
      properties: {
        empty_valid: true,
        measure: data.field.measure || ""
      },
      type: data.field.type
    }

    switch (data.field.type) {
      case "Text":
          catField.properties.multi_line = data.field.multLineOrIntOrNow;
          break;
      case "DateTime":
          catField.properties.automatic_datetime = data.field.multLineOrIntOrNow;
          break;
      case "Currency":
          catField.properties.fractional_digits = 2;
          break;
      case "FloatingNumber":
          if (data.field.multLineOrIntOrNow) {
              catField.type = "Number";
          } else {
              catField.properties.fractional_digits = 4;
          }
          break;
      default:
          break;
  }

    data.parentCategoryField.push( catField );

    return [field, catField];
  }

  public removeCustomField(
    parentFields: Field[],
    parentCategoryField: CategoryField[],
    fieldName: string
  ): [number, number] {

    const fieldIndex = parentFields.findIndex(item => item.name === fieldName);

    if (fieldIndex === -1) {
      return [-1, -1]; // Retornar -1 se o campo não foi encontrado
    }
    parentFields.splice(fieldIndex, 1);

    const catFieldIndex = parentCategoryField.findIndex(item => item.name === fieldName);

    if (catFieldIndex !== -1) {
      parentCategoryField.splice(catFieldIndex, 1);
    }

    return [fieldIndex, catFieldIndex]; // Retornar o índice do campo removido
  }

  public editCustomField(data: CustomFieldCreatorData): number {
    const originalValue = this.getFieldValue(
      data.field.oldName!,
      data.parentFields,
      data.field.type === 'Color'
    );
    const [fieldIndex, catFieldIndex] = this.removeCustomField(data.parentFields, data.parentCategoryField, data.field.oldName!);

    if (fieldIndex === -1) {
      return -1;
    }

    const newField: Field = {
      name: data.field.name,
      read_only: false,
      ...(typeof originalValue === 'object' && originalValue !== null && data.field.type === 'Color'
        ? { color: originalValue }
        : { value: originalValue || "" })
    };

    let newCatField: CategoryField = {
      name: data.field.name,
      label: data.field.name,
      custom: true,
      options: data.field.values,
      properties: {
        empty_valid: true
      },
      type: data.field.type
    };
    if( data.field.measure ) {
      newCatField.properties.measure = data.field.measure;
    }

    switch (data.field.type) {
      case "Text":
        newCatField.properties.multi_line = data.field.multLineOrIntOrNow;
        break;
      case "DateTime":
        newCatField.properties.automatic_datetime = data.field.multLineOrIntOrNow;
        break;
      case "Currency":
        newCatField.properties.fractional_digits = 2;
        break;
      case "FloatingNumber":
        newCatField.properties.fractional_digits = 4;
        break;
      default:
        break;
    }

    data.parentFields.splice(fieldIndex, 0, newField);

    data.parentCategoryField.splice(catFieldIndex, 0, newCatField);

    return fieldIndex;
  }

  public isReadyToSave() : boolean {
    if( this.isStandAlone() ) {

      return this.fw!.isa_model_id > 0 &&
             this.fw!.isa_company_id > 0 &&
             this.fw!.isa_shape.length > 0 &&
             this.fw!.isa_kanbam.length > 0;
    }

    return true;
  }

  public verifyImagesCount(isSaving: boolean = false): boolean {
    const maxImages = 11;
    let currentCount = 0;

    if (this.garment.images) {
      currentCount = this.garment.images.filter(img => img.location != 'DELETE').length;
    }

    if (isSaving) {
      return currentCount <= maxImages;
    }
    return currentCount < maxImages;
  }

  private getReferenceList( entity: Garment | Variant, referenceType: string ) : Reference[] {
    switch (referenceType) {

      case "materials"      : return  entity.materials||[];
      case "activities"     : return  entity.activities||[];
      case "measures"       : return  entity.measures||[];
      case "generics"       : return  entity.generics||[];
      case "fashion_studio" : return  entity.fashion_studio||[];

      default:
        return [];
    }
  }

  public listPossibleTargetsToCopy( referenceId: string, referenceType: string): ReferenceTarget[] {
    const targets: ReferenceTarget[] = [];

    for (const r of this.getReferenceList(this.garment, referenceType)) {
        if (r.uid === referenceId) {
            return targets;
        }
    }

    const firstLevel = this.getVariantList(1);
    let targetsList = this.getCopiableTargets(referenceId, referenceType, firstLevel);

    if (targetsList.length === 0) {
        const secondLevel = this.getVariantList(2);
        targetsList = this.getCopiableTargets(referenceId, referenceType, secondLevel);
    }

    if (targetsList.length === 0) {
        const thirdLevel = this.getVariantList(3);
        targetsList = this.getCopiableTargets(referenceId, referenceType, thirdLevel);
    }

    return targetsList;
  }

  private getCopiableTargets(referenceId: string, referenceType: string, parent: Variant[]): ReferenceTarget[] {
    const targets: ReferenceTarget[] = [];
    let variation_name = "";

    // procura a referencia com referenceId
    for (const variant of parent) {
        for (const current of this.getReferenceList(variant, referenceType)) {
            if (current.uid === referenceId) {
                variation_name = current.variation_name ?? uuidv4();
                break;
            }
        }

        if (variation_name.length > 0) {
            break;
        }
    }

    if (variation_name.length > 0) {
        for (const variant of parent) {
            let canCopy = true;
            for (const current of this.getReferenceList(variant, referenceType)) {
                if (current.variation_name === variation_name) {
                    canCopy = false;
                    break;
                }
            }
            if (canCopy) {
                let target_name = variant.fields[0].value as string;
                const parentName = variant.parentName ?? "";
                if (parentName.length > 0) {
                    target_name = `${target_name} (${parentName})`;
                }

                targets.push({
                  target_uid: variant.uid,
                  target_name
                });
            }
        }
    }

    return targets;
  }

  public listPossibleTargetsToMove(referenceId: string, referenceType: string): ReferenceTarget[] {
    const targets: ReferenceTarget[] = [];
    let variation_name = "";


    const allVarLevels = [
      ...this.getVariantList( 1 ),
      ...this.getVariantList( 2 ),
      ...this.getVariantList( 3 ),
    ];

    const garmentRefs = this.getReferenceList(this.garment, referenceType);

    for (const r of garmentRefs) {
        if (r.uid === referenceId) {
            variation_name = r.variation_name ?? uuidv4();
            break;
        }
    }

    if (variation_name.length === 0) {
        // Se a ref nao foi encontrada em garment, procurar nas variantes
        for (const variant of allVarLevels) {
            for (const current of this.getReferenceList(variant, referenceType)) {
                if (current.uid === referenceId) {
                    variation_name = current.variation_name ?? "";
                    break;
                }
            }
            if (variation_name.length > 0) {
                break;
            }
        }
    }

    if (variation_name.length > 0) {
        for (const variant of allVarLevels) {
            let canCopy = true;
            for (const current of this.getReferenceList(variant, referenceType)) {
                if (current.variation_name === variation_name) {
                    canCopy = false;
                    break;
                }
            }
            if (canCopy) {
                let target_name = variant.fields[0].value as string;
                const parentName = variant.parentName ?? "";
                if (parentName.length > 0) {
                    target_name = `${target_name} (${parentName})`;
                }

                targets.push({
                  target_uid: variant.uid,
                  target_name,
                  level: variant.level
                });
            }
        }
    }
    return targets;
  }

  private _addReferenceOn( target: Garment|Variant, reference: Reference, refType: ReferenceType ) {
    switch (refType) {
      case ReferenceType.Material:
        target.materials.push( reference );
        break;
      case ReferenceType.Activity:
        target.activities.push( reference );
        break;
      case ReferenceType.Measures:
        target.measures.push( reference );
        break;
      case ReferenceType.Free:
        target.generics.push( reference );
        break;
    }

  }

  public copyReference( reference: Reference, target: ReferenceTarget, refType: ReferenceType ) : Reference {
    reference.variation_name = reference.variation_name || uuidv4();
    let referenceClone: Reference = JSON.parse( JSON.stringify(reference) );
    referenceClone.uid = uuidv4();
    referenceClone.variation_name = reference.variation_name;

    if( target.target_uid == this.garment.uid ) {
      this._addReferenceOn( this.garment, referenceClone, refType );
    } else {
      const variants = this.getAllVariantsFromDatasheet();
      let variant = variants.find( v => v.uid === target.target_uid );
      if( variant ) {
        this._addReferenceOn( variant, referenceClone, refType );
      }
    }
    return referenceClone;
  }

  private getByUid( uid: string) : Garment|Variant|undefined {
    if( this.garment.uid == uid) {
      return this.garment;
    }

    const allvars = this.getAllVariantsFromDatasheet();
    return allvars.find( v => v.uid == uid );
  }

  private variantsRelatives( variant: Variant, variantList: Variant[] ) : Variant[] {
    let res: Variant[] = [];

    // find first level for variant
    let firtLevel: Variant = this.garment.variants[0];
    for( const v of this.garment.variants ) {
      if( v.uid === variant.uid ) {
        firtLevel = v;
        break;
      }
      for( let sv of v["sub-variants"]||[] ) {
        if( sv.uid === variant.uid ) {
          firtLevel = v;
          break;
        }
        for( let ssv of sv["sub-variants"]||[] ) {
          if( ssv.uid === variant.uid ) {
            firtLevel = v;
            break;
          }
        }
      }
    }

    let found = variantList.find( v => v.uid === firtLevel.uid );
    if( found ) {
      res.push(found);
    }

    for( let child of firtLevel["sub-variants"]||[] ) {
      let foundChild = variantList.find( v => v.uid === child.uid );
      if( foundChild ) {
        res.push( foundChild );
      }

      for( let grandchild of child["sub-variants"]||[] ) {
        let foundGrandchild = variantList.find( v => v.uid === grandchild.uid );
        if( foundGrandchild ) {
          res.push( foundGrandchild );
        }
      }
    }

    return res;
  }

  public moveReference( currentLevel: number, parent: Reference[], reference: Reference, target: ReferenceTarget, referenceType: ReferenceType ) {
    reference.variation_name = reference.variation_name || uuidv4();

    if( target.target_uid == this.garment.uid ) {
      // References CAN NOT be moved to garment level
      return;
    }

    // remove reference from origin
    const index = parent.findIndex(r => r.uid === reference.uid);
    if (index > -1) {
      parent.splice(index, 1);
    }

    // add reference on destination
    let variantOrGarment = this.getByUid(target.target_uid);
    if( variantOrGarment ) {
      this._addReferenceOn( variantOrGarment, reference, referenceType );
    }

    // move all references with same variation
    let variantsOrigin = this.getVariantList(currentLevel);
    let variantsTarget = this.getVariantList(target.level!);

    for( let varOrig of variantsOrigin ) {
      let refsOrigin = this.getReferenceList(varOrig, referenceType);
      let indexWithVariationName = refsOrigin.findIndex( r => r.variation_name == reference.variation_name );
      if( indexWithVariationName >= 0 ) {
        const tmpRefs = refsOrigin.splice( indexWithVariationName, 1 );

        let relatives = this.variantsRelatives( varOrig, variantsTarget);

        let variantCandidate = relatives.find( v => v.fields[0].value == variantOrGarment!.fields[0].value );
        if( !variantCandidate ) {
          variantCandidate = relatives[0];
        }
        this._addReferenceOn( variantCandidate, tmpRefs[0], referenceType );
      }
    }
  }

  public variantLevel() : number {
    if( this.garment.variants.length == 0 ) {
      return 0;
    }

    let level = 0;
    if( this.garment.variants.length > 1 ) {
      level++;
    }

    if( this.garment.variants[0]["sub-variants"] && this.garment.variants[0]["sub-variants"].length ) {
      level++;
      if( this.garment.variants[0]["sub-variants"]![0]["sub-variants"] && this.garment.variants[0]["sub-variants"]![0]["sub-variants"].length ) {
        level++;
      }
    }

    return level;
  }

  public addImage( img: Image, position: number = -1 )  {
    if( !this.garment.images ) {
      this.garment.images = []
    }
    if( position > 0 ) {
      this.garment.images.splice(position, 0, img);
    } else if( position == 0) {
      this.garment.images[0] = img;
    } else {
      this.garment.images.push(img);
    }
  }

  public moveImage( index: number, direction: number ) : boolean {
    if( !this.garment.images || this.garment.images.length == 1) {
      return false;
    }
    const canMove = direction > 0
      ? (index >= 0 && index < this.garment.images.length - 1)
      : (index > 1 && index < this.garment.images.length);

    if ( canMove ) {
      const swapIndex = index + direction;
      const temp = this.garment.images[index];
      this.garment.images[index] = this.garment.images[swapIndex];
      this.garment.images[swapIndex] = temp;
    }
    return canMove;
  }

  public markImageAsDeleted( imgUid: string )  {

    if( !this.garment.images ) {
      return
    }

    let index = this.garment.images.findIndex( img => img.id == imgUid );
    if( index > 0 ) {
      if(this.garment.images[index].url?.startsWith("data:")) {
        this.garment.images.splice( index, 1 );
      } else {
        this.garment.images[index].location = "DELETE";
      }
    }

    if( index == 0 ) {
      this.garment.images[index].url = '';
    }
  }

  public getImages2Delete() : Image[]  {
    if( !this.garment.images || this.garment.images.length == 0 ) {
      return [];
    }

    const images2delete = this.garment.images.filter( img => img.location == 'DELETE' );
    this.garment.images = this.garment.images.filter( img => img.location != 'DELETE' );
    return images2delete;
  }

  public getImages2Upload() : Image[]  {
    if( !this.garment.images || this.garment.images.length == 0 ) {
      return [];
    }

    return this.garment.images.filter( img => (img.url && img.url!.startsWith('data:') && img.location != 'DELETE') );
  }

  public SetUrl2ImagesUploaded( uploadedImgs : Image[] )  {
    if( !this.garment.images || this.garment.images.length == 0 ) {
      return [];
    }

    return this.garment.images.filter( img => (img.url && img.url!.startsWith('data:') && img.location != 'DELETE') );
  }

  public getModelImages() : Image[] {
    let images: Image[] = [];

    if( this.garment.images && this.garment.images.length ) {
      images = this.garment.images.filter( img => img.location != 'DELETE' );
    }

    return images;
  }

  setLastModificationDate2Now() {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    this.last_modification_date = `${year}${month}${day}T${hours}${minutes}${seconds}`;
  }

  getMeasure(type: string): string {
    if (['area', 'length', 'weight'].includes(type)) {
      return this.measure_units?.[type as keyof MeasureType] ?? '';
    }
    return '';
  }

  public getAllMeasureValues(){
    let measures = this.measure_units;
    let currency = this.fw?.currency;

    const obj ={
      currency: currency,
      measureUnit: measures!.length ,
      areaUnit: measures!.area,
      weightUnit: measures!.weight
    }

    return obj
  }

  getCurrencySymbol(){
    const currency = this.fw?.currency;
    return this.currencySymbols[currency!] ?? ''
  }

  private currencySymbols: { [code: string]: string } = {
    'USD': '$',
    'BRL': 'R$',
    'EUR': '€',
    'GTQ': 'Q',
    'HNL': 'L',
    'PEN': 'S/',
    'PYG': '₲',
    'VES': 'Bs.',
    'GBP': '£',
    'ARS': '$',
    'BOB': 'Bs.',
    'CLP': '$',
    'COP': '$',
    'DOP': 'RD$',
    'UYU': '$U',
    'MXN': '$'
  };

  public getNextDefaultRefName( refTypeName: string ) {

    const getAllReferences = this.getAllReferences();
    const numbers = getAllReferences
      .map(material => {
        const regex = new RegExp(`^${refTypeName}\\s(\\d+)$`);
        const match = (material.fields[0].value! as string).match(regex);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter(num => num !== null) as number[];

    const nextNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
    return nextNumber;
  }

  public applyMeasureConvertion( originalValue: any, fieldName: string, fieldPath: string, toCurrentMeasure: boolean = false) {
    const catType = this.getCategoryFieldFromPath(fieldName, fieldPath);
    if( catType.properties && catType.properties.measure ) {
      const measureType = catType.properties.measure;
      let measureUnit;
      switch (measureType) {
        case 'area':
          measureUnit = this.measure_units?.area;
          break;
        case 'length':
          measureUnit = this.measure_units?.length;
          break;
        case 'weight':
          measureUnit = this.measure_units?.weight;
          break;
      }

      if(measureUnit) {
        if( typeof originalValue == 'string' ) {
          const originalValueAsNumber = +originalValue.replace(',', '.');
          if(toCurrentMeasure){
            return originalValueAsNumber / this.convertionFactor(measureUnit);
          }
          return originalValueAsNumber * this.convertionFactor(measureUnit);
        }
        return originalValue;
      }

    } else {
      return originalValue;
    }
  }

  private convertionFactor(measure: string): number {
    switch (measure) {
        case "mm": return 1.0;
        case "cm": return 10.0;
        case "m": return 1000.0;
        case "in": return 25.4;
        case "ft": return 304.8;
        case "yd": return 914.4;
        case "mg": return 0.001;
        case "g": return 1.0;
        case "kg": return 1000.0;
        case "oz": return 28.3495537;
        case "lb": return 453.592;
        case "mm²": return 1.0;
        case "cm²": return 100.0;
        case "m²": return 1000000.0;
        case "in²": return 645.16;
        case "ft²": return 92903.0;
        case "yd²": return 836127.0;
        default: return 1.0;
    }
  }

  getTargetPathToCopyOrMove( target_uid: string, referenceType: ReferenceType ) : string {
    if( this.garment.uid == target_uid ) {
      return `garment.${referenceType}`;
    } else {
      for( let variantIndex in this.garment.variants ) {
        const variant = this.garment.variants[variantIndex];
        if( variant.uid == target_uid ) {
          return `garment.variants.${variantIndex}.${referenceType}`;
        }

        for( let subVariantIndex in variant['sub-variants'] || [] ) {
          const subVariant = variant['sub-variants']![subVariantIndex];
          if( subVariant.uid == target_uid ) {
            return `garment.variants.${variantIndex}.sub_variants.${subVariantIndex}.${referenceType}`;
          }

          for( let subSubVariantIndex in subVariant['sub-variants'] || [] ) {
            const subSubVariant = subVariant['sub-variants']![subSubVariantIndex];
            if( subSubVariant.uid == target_uid ) {
              return `garment.variants.${variantIndex}.sub_variants.${subVariantIndex}.sub_variants.${subSubVariantIndex}.${referenceType}`;
            }
          }

        }

      }
    }

    return '';
  }

  setupLists() {
    this.garment.materials = this.garment.materials || [];
    this.garment.activities = this.garment.activities || [];
    this.garment.measures = this.garment.measures || [];
    this.garment.generics = this.garment.generics || [];

    for( let variant of this.garment.variants ) {
      variant.materials = variant.materials || [];
      variant.activities = variant.activities || [];
      variant.measures = variant.measures || [];
      variant.generics = variant.generics || [];

      for( let subVariant of variant["sub-variants"]||[] ) {
        subVariant.materials = subVariant.materials || [];
        subVariant.activities = subVariant.activities || [];
        subVariant.measures = subVariant.measures || [];
        subVariant.generics = subVariant.generics || [];

        for( let subSubVariant of subVariant["sub-variants"]||  [] ) {
          subSubVariant.materials = subSubVariant.materials || [];
          subSubVariant.activities = subSubVariant.activities || [];
          subSubVariant.measures = subSubVariant.measures || [];
          subSubVariant.generics = subSubVariant.generics || [];
        }
      }
    }
  }

  private concatLists( entity: Garment | Variant ) {

    if( !entity.materials )
      entity.materials = [];

    if( !entity.activities )
      entity.activities = [];

    if( !entity.generics )
      entity.generics = [];

    if( !entity.measures )
      entity.measures = [];

    for( let rfg of entity.reference_groups || [] ) {
      entity.materials.push( ...rfg.materials || [] );
      entity.activities.push( ...rfg.activities || [] );
      entity.generics.push( ...rfg.generics || [] );
      entity.measures.push( ...rfg.measures || [] );
    }
  }

  public handleReferencesGroups() {
    this.concatLists( this.garment );

    for( let variant of this.garment.variants ) {
      this.concatLists( variant );
      for( let subVar of variant['sub-variants'] || [] ) {
        this.concatLists( subVar );

        for( let subSubVar of subVar['sub-variants'] || [] ) {
          this.concatLists( subSubVar );
        }
      }
    }
  }

}

enum SumType {
  Material = 'material',
  Activity = 'activity',
  Both = 'both'
}

