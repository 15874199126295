<div class="dialog_container">
  <div class="dialog_container__header">
    <h2 mat-dialog-title class="dialog_container__header_tittle"> {{'erp.addRefForm.title' | translate}} </h2>
    <button mat-icon-button [mat-dialog-close]="applyAtLeast1x">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="dialog_container_content">
    <mat-stepper [linear]="true" class="stepper" linear animationDuration='0' #stepper [disableRipple]="true">
      <mat-step>
        <ng-template matStepLabel> {{'erp.addRefForm.step1.title' | translate}}</ng-template>

        <div class="stepper_1" [formGroup]="searchRefForm">
          <mat-form-field class="styledForm" appearance="outline">
            <mat-label>{{'erp.addRefForm.step1.category' | translate}}</mat-label>
            <mat-select formControlName="referenceType" panelClass="model-form__panel isa-med-elevation"
              disableOptionCentering [(ngModel)]="selectedReferenceType">
              <mat-option *ngFor="let item of referenceType" [value]="item" (click)="onChangeRefType(item)">{{item | translate}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="styledForm" appearance="outline">
            <mat-label>{{'erp.addRefForm.step1.code' | translate}}</mat-label>
            <input matInput formControlName="referenceCode"  (keydown.enter)="getReferences()">
          </mat-form-field>

          <mat-form-field class="styledForm" appearance="outline">
            <mat-label>{{'erp.addRefForm.step1.name' | translate}}</mat-label>
            <input matInput formControlName="referenceName" (keydown.enter)="getReferences()">
          </mat-form-field>

          <fieldset>
            <legend>{{'erp.addRefForm.step1.filter' | translate}}</legend>
            <div class="stepper_1_custom-filter" formGroupName="customFilter">
                <p-dropdown class="fixed styledForm"
                  [options]="advFilter"
                  formControlName="selectedFilter"
                  (onChange)="updateCustomValueValidator($event)"
                  placeholder="{{'erp.addRefForm.step1.filterName' | translate}}"
                  [editable]="true"
                  [showClear]="true"
                  [style]="{'height':'38px'}"
                  optionLabel="name" />

              <mat-form-field class="styledForm" style="width: 50%; background-color: #fff;" appearance="outline">
                <mat-label>{{'erp.addRefForm.step1.filterValue' | translate}}</mat-label>
                <input matInput formControlName="customValue" (keydown.enter)="getReferences()">
              </mat-form-field>
            </div>
          </fieldset>

          <div class="stepper_1_search">
            <button-filled [color]="'secondary'" [isLoading]="searchRefs" (click)="getReferences()">
              {{'erp.addRefForm.step1.search' | translate}}</button-filled>
          </div>
          <span class="stepper_1_content_title" *ngIf="referenceData">{{'erp.addRefForm.step1.selectAReference' | translate}}*</span>
          <div class="stepper_1_content" *ngIf="referenceData">
            <div class="stepper_1_content_item" *ngFor="let item of referenceData, let i = index"
              [ngClass]="{ 'selected': selectedIndex === i }"
              (click)="selectReference(i, item)"
              (dblclick)="selectReference(i, item);getFullReference()"
            >
              <span class="stepper_1_content_item_thumb"> <mat-icon>cloud_download</mat-icon></span>
              <span class="stepper_1_content_item_name">
                [{{item.uid}}] {{item.reference}} - <b>{{item.name}}</b> ({{item.description}})
                <i style="color: #12419b96;" *ngIf="item.collection||item.sector">
                  em: {{item.collection||item.sector}}/{{item.product_group||item.machine}}
                </i>
              </span>
            </div>

            <div style="text-align: center;" *ngIf="referenceData.length == 0">
              <img src="../../../assets/images/notFound.svg">
              <p class="message">
                {{ 'alerts.notFound.text' | translate }}
              </p>
            </div>

          </div>
          <div class="stepper_1_btn stepper_bottom">
            <button-filled matStepperNext (click)="getFullReference()" [isLoading]="isFetching"
              [disabled]="selectedIndex == null">
              {{ 'shared.actions.next.label' | translate }}</button-filled>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>{{'erp.addRefForm.step2.title' | translate}}</ng-template>
        <div class="stepper_2" *ngIf="selectedRef" [formGroup]="insertRefForm">
          <div class="stepper_2_refName" style="margin-bottom: 16px;">
            <span>
              [{{selectedRef.uid}}] {{selectedRef.reference}} - <b>{{selectedRef.name}}</b> - {{selectedRef.description}} <br>
              <i style="color: #12419b96;">{{'erp.addRefForm.step2.at' | translate}}: {{selectedRef.collection||selectedRef.sector}}/{{selectedRef.product_group||selectedRef.machine}}</i>
            </span>
            <span> <b>{{'cost' | translate}}:</b> {{selectedRef.value||0}}</span>
          </div>
          <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
            <mat-label>{{'erp.addRefForm.step2.variant' | translate}}</mat-label>
            <mat-select panelClass="model-form__panel isa-med-elevation" disableOptionCentering [(ngModel)]="selectRefVariant" [ngModelOptions]="{standalone: true}" (selectionChange)="onchangeSelectRefVariant()">
              <mat-option *ngFor="let item of selectedRef.variants" [value]="item">{{item.label||item}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="styledForm" appearance="outline" *ngIf="refType=='materials' || refType=='activities' || selectedReferenceType=='raw_material' || selectedReferenceType=='activity' ">
            <mat-label>{{'erp.addRefForm.step2.amount' | translate}}</mat-label>
            <input type="number" matInput formControlName="amount">
          </mat-form-field>

          <ng-container *ngIf="selectedRef.type != 'measure'">
            <div *ngFor="let field of customFieldOptions">
              <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
                <mat-label>{{field.label}}</mat-label>
                <mat-select panelClass="model-form__panel isa-med-elevation" disableOptionCentering [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let opt of field.options" [value]="opt">{{opt}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container  *ngIf="selectedRef.type === 'measure'">
            <div  *ngFor="let field of customFieldOptions">
              <mat-form-field class="styledForm" style="width: 100%;" appearance="outline">
                <mat-label>{{field.label | translate}}</mat-label>
                <mat-select
                  panelClass="model-form__panel isa-med-elevation"
                  disableOptionCentering
                  [(ngModel)]="field.value"
                  [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let opt of field.options" [value]="opt.value">{{ opt.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="stepper_2_btn stepper_bottom">
            <button-text mat-button color="primary" (click)="stepper.previous()">
              {{ 'shared.actions.previousStep.label' | translate }}
            </button-text>
            <button-filled matStepperNext (click)="step2Click()">
              {{ 'shared.actions.next.label' | translate }}
            </button-filled>
          </div>
        </div>
      </mat-step>


      <mat-step>
        <ng-template matStepLabel>{{'erp.addRefForm.step3.title' | translate}}</ng-template>
        <div class="stepper_3">

          <div *ngIf="!refType">
            <mat-checkbox class="" color="primary" [disabled]="variants.length == 0" (click)="insertInModelClick()" ngModel="insertInModel"></mat-checkbox>
            <span>
              {{'erp.addRefForm.step3.insertOnModel' | translate}}
            </span>
          </div>
          <div *ngIf="targetName">
            <mat-checkbox class="" color="primary" [disabled]="true" [checked]="true"></mat-checkbox>
            <span> Inserir em {{ targetName }} </span>
          </div>


          <fieldset class="variantFS" *ngIf="!insertInModel">
            <legend>{{'erp.addRefForm.step3.modelVariants' | translate}}</legend>

            <table class="table">
              <tr class="table__row" *ngFor="let value of variants ">
                <td>
                  <mat-checkbox class="check" disableRipple [value]="value.uid" (change)="onGarmentVariantClick($event, value.uid)"
                    class="table__data table__data--checkbox">
                  </mat-checkbox>
                </td>
                <td class="table__data">
                  {{ value.name }}
                </td>
              </tr>
            </table>
          </fieldset>
          <div class="stepper_3_controls stepper_bottom">
            <div class="left">
              <mat-checkbox color="primary" [(ngModel)]="keepWindowOpened"></mat-checkbox>
              <span> {{'erp.addRefForm.step3.keepWindowOpened' | translate}}</span>
            </div>
            <button-text mat-button color="primary" (click)="stepper.previous()">
              {{ 'shared.actions.previousStep.label' | translate }}
            </button-text>
            <button-filled [isLoading]="isloading" (click)="insertAndRestartClick()"> {{'general.apply' | translate}} </button-filled>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
