import { Injectable } from '@angular/core';
import { IntegrationServiceService } from './integration-service.service';
import { Observable, take, tap, forkJoin } from 'rxjs';
import { ErpConfigData, UserConfiguration } from '../models/integration-user-data';
import { LocalStorageService } from './localStorage.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DataStorageService } from './data-storage.service';
import { Datasheet } from '../models/datasheet';
import { Reference } from '../models/reference';
import { TranslateService } from '@ngx-translate/core';


export enum ErpServerType {
  none,
  audaces,
  default,
  millennium
}


export class ErpServer {
  public erp_type: ErpServerType;
  public version: string = "v1";
  public erp_cfg: UserConfiguration;

  constructor(
    private _localStorage: LocalStorageService,
    private _dataStorage: DataStorageService,
    _type: ErpServerType = ErpServerType.audaces
  ) {
    this.erp_type = _type;

    let cache = this.loadFromCache();

    if(cache != null) {
      this.erp_cfg = cache;
      this.setType( cache.erp.type );
    }else{
      this.erp_cfg = {
        user: '',
        erp: {
          type: "erp.types.none",
          ip: "",
          port: 0,
          user: {
            name: "",
            password: "",
          },
          timeout: 2*60*1000
        }
      }
      this.erp_type = ErpServerType.none;
    }

    this.version = "v1";
  }

  private getUserEmail(): string {
    const currentUserInfo = this._localStorage.get('0-ficha_web', 'session', false);
    return currentUserInfo.userData.email;
  }

  getAuthToken() {
    let erpType;
    let data = this.loadFromCache();
    if( data ) {
      erpType = data.erp.type;
    }else {
      erpType = this.erp_type;
    }

    if( erpType == ErpServerType.audaces || erpType == 'erp.types.audaces' ) {
      return this._localStorage.getAuthorizationToken();
    }

    if( this.erp_type == ErpServerType.default || erpType == 'erp.types.default') {
      const erpToken = this._localStorage.get('erp_token', 'session', true);
      if( erpToken ) {
        return erpToken['token'] || '';
      }
    }

    return '';
  }

  getAuthorizationHeader(): HttpHeaders {
    let erpType;
    let data = this.loadFromCache();
    if (data) {
      erpType = data.erp.type;
    } else {
      erpType = this.erp_type;
    }

    switch (erpType) {
      case ErpServerType.audaces:
      case 'erp.types.audaces':
      case ErpServerType.default:
      case 'erp.types.default':
        let token = this.getAuthToken();
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);

      case ErpServerType.millennium:
        return new HttpHeaders().set('WTS-Authorization', `${this.erp_cfg.erp.user.name}/${this.erp_cfg.erp.user.password!}`);

      default:
        return new HttpHeaders();
    }
  }

  getBodyParam(
    type: 'finished_product'|'raw_material'|'activity'|'group'|'measure'|'any',
    params: any = {}
  ): any {
    if (this.erp_type == ErpServerType.millennium) {

      let uid = params['reference'] || params['uid'] || '';
      const spltType = uid.split(':');
      uid = (spltType.length == 2) ? spltType[1] : spltType[0];

      let tipo = {
        'finished_product' : 'AC',
        'raw_material' : 'MP',
        'activity' : 'OP',
        'group' : 'MP',
        'measure' : 'AC',
        'any' : '',
      }
      return {
        'FORMAT' : 'JSON',
        'TEXT': uid,
        'TIPO' : tipo[type]
      };
    }

    if(this.erp_cfg.erp.customToken) {
      if( type != 'any' ) {
        return { type, api_key: this.erp_cfg.erp.customToken, ...params};
      } else {
        return { api_key: this.erp_cfg.erp.customToken, ...params};
      }
    }

    return ( type != 'any' ) ? {type, ...params} : { ...params};
  }


  setType( _type: string ) {
    switch (_type) {
      case "erp.types.audaces":
        this.erp_type = ErpServerType.audaces;
        this.erp_cfg.erp.type = _type;
        break;
      case "erp.types.default":
        this.erp_type = ErpServerType.default;
        this.erp_cfg.erp.type = _type;
        break;
      case "erp.types.millennium":
        this.erp_type = ErpServerType.millennium;
        this.erp_cfg.erp.type = _type;
        break;

      default:
        this.erp_type = ErpServerType.none;
        this.erp_cfg.erp.type = 'erp.types.none';
        this.erp_cfg.erp.user.password = '';
        break;
    }
  }

  getType() {
    switch (this.erp_type) {
      case ErpServerType.audaces:
        return "erp.types.audaces";

      case ErpServerType.default:
        return "erp.types.default";

      case ErpServerType.millennium:
        return "erp.types.millennium";

      default:
        return "erp.types.none"
    }
  }

  public getErpUrl(): string{
    let ip = this.erp_cfg.erp.ip;
    if( ip.endsWith("/") ) {
      ip = ip.slice(0,-1);
    }
    if( !ip.startsWith('http') ) {
      ip = `http://${ip}`;
    }

    let parts : any = [ip];
    if( this.erp_cfg.erp.port > 0) {
      parts.push(this.erp_cfg.erp.port);
    }

    return parts.join(":");
  }

  public setErpData(user: any, pass: any){
    this.erp_cfg.erp.user.name = user;
    this.erp_cfg.erp.user.password = pass;
  }

  public getErpData(){
    return this.erp_cfg;
  }

  public getUpdateAudacesUserEndpoint() {
    const erp_url= this.getErpUrl();
    return `${erp_url}/audaces/erp/api/${this.version}/user/`;
  }

  getLoginEndpointAddress( isIntegrationLogin = true ) {
    let erp_url = this.getErpUrl();
    switch (this.erp_type) {
      case ErpServerType.millennium:
        return `${erp_url}/api/login`
      case ErpServerType.audaces:
        if( isIntegrationLogin )
          return `${erp_url}/audaces/erp/api/${this.version}/user/integration_login/`;
        else
          return `${erp_url}/audaces/idea/api/${this.version}/user/login/`;

      default:
        return `${erp_url}/audaces/idea/api/${this.version}/user/login/`;
    }
  }

  getQueryEndpointAddress( ) {
    let erp_url = this.getErpUrl();
    switch (this.erp_type) {
      case ErpServerType.millennium:
        return `${erp_url}/api/millenium_audaces/componentes.busca`

      default:
        return `${erp_url}/audaces/idea/api/${this.version}/query/`;
    }
  }

  getCostTableEndpointAddress( ) {
    let erp_url = this.getErpUrl();
    switch (this.erp_type) {
      case ErpServerType.millennium:
        return `${erp_url}/api/millenium/tabelas_preco/lista_custo?ordem=0`

      default:
        throw new Error("Cost table is just available on Millennium ERP");
    }
  }

  erpCacheData(): ErpConfigData | undefined  {
    return this.loadFromCache()?.erp;
  }

  updateCache( erpData: ErpConfigData, service: any): void {
    this.erp_cfg.erp = erpData;
    this.setType(service);
    this.saveToCache();
  }

  private saveToCache() : boolean {
    this._localStorage.set('erp_cfg', this.erp_cfg, 'local', true);
    return true;
  }

  private loadFromCache() : UserConfiguration | null {
    let data = this._localStorage.get('erp_cfg', 'local', true);

    if(data) {
      return data;
    }
    return null;
  }

  public getErpConfigFromServer(): void {
    const cacheData = this.loadFromCache();
    if(cacheData == null) {
      this._dataStorage.getErpConfiguration()
      .pipe(
        take(1),
        tap(x => console.log(x))
      )
      .subscribe(
        (serverData) => {
          if(serverData != null) {
            this.erp_cfg = serverData;
            this.saveToCache();
          }
        },
        (err: Error) => {
          console.warn(err);
        }
      );
    }
  }

  public saveErpConfigInServer(erpData: ErpConfigData, selectedType: string): void {
    let invalidatePass = erpData.type !== selectedType;
    erpData.type = selectedType;
    const userData = this.erp_cfg;
    userData.user = this.getUserEmail();
    userData.erp = erpData;
    this._dataStorage.setErpConfiguration(userData)
      .pipe(
        take(1),
        tap((data) => {
          this.erp_cfg.uid = data.result;
          console.log(this.erp_cfg);
        })
      )
      .subscribe();
  }

  isConfigured() : boolean {

    if( !this.erp_cfg.erp.ip ||
        !this.erp_cfg.erp.user.name
    ) {
      return false;
    }

    switch (this.erp_type) {
      case ErpServerType.audaces:
        return this.erp_cfg.erp.apiAudaces!.credential !== '' && this.erp_cfg.erp.apiAudaces!.dictionary !== '';

      case ErpServerType.millennium:
        return !this.erp_cfg.erp.costTable;

      case ErpServerType.none:
        return false;
    }

    return true;
  }
}


@Injectable({
  providedIn: 'root',
})
export class ErpServiceService {
  public erpServer: ErpServer = new ErpServer(this._localStorage, this._dataStorage);

  constructor(
    private _integration: IntegrationServiceService,
    private _localStorage: LocalStorageService,
    private _dataStorage: DataStorageService,
    private _translate: TranslateService,
    ) {}

  public audacesApiLogin(): Observable<boolean>{
    const data = this.erpServer.erpCacheData();
    return this._integration.integrationLogin( this.erpServer.getLoginEndpointAddress(), data!);
  }

  public saveToken(tokenObj: any){

    if( !tokenObj ) {
      this._localStorage.remove('erp_token', 'session');
      return;
    }

    let expiration = Date.now() + tokenObj.expires_in

    let erpToken = {
      token: tokenObj.access_token,
      expiration_time : expiration
    }
    this._localStorage.set('erp_token', erpToken, 'session', true)
  }

  public isTokenValid(): boolean {
    const data = this._localStorage.get('erp_token', 'session', true);

    if (data && data.expiration_time) {
      if (typeof data.expiration_time === 'number' && data.expiration_time > Date.now()) {
        return true;
      }
    }
    return false;
  }

  public login(): Promise<any> {
    if( this.erpServer.erp_type == ErpServerType.millennium )
      return this.loginMillennium();

    return this.loginDefault();
  }

  public updateAudacesErpUser () {
    this.updateUserFromAudacesErp();
  }

  public setCacheCustomList(list: string[]) {
    this._localStorage.set('erp_custom_list', list, 'session');
  }

  public getCacheCustomList(): string[] {
    return this._localStorage.get('erp_custom_list', 'session') || [];
  }

  public setCacheCostTable(list: string[]) {
    this._localStorage.set('erp_cost_table', list, 'session');
  }

  public getCacheCostTable(): string[] {
    return this._localStorage.get('erp_cost_table', 'session') || [];
  }

  private loginDefault(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.erpServer.getLoginEndpointAddress();

      let headerParams = new HttpHeaders();
      let bodyParams : any = {};
      let isAudacesErp = this.erpServer.erp_type == ErpServerType.audaces;
      if( isAudacesErp ) {
        headerParams = this._localStorage.getHeadersWithAuthorization();
      }

      bodyParams['username'] = this.erpServer.erp_cfg.erp.user.name;
      bodyParams['password'] = this.erpServer.erp_cfg.erp.user.password!;

      this._integration.postRequest(this.erpServer, url, bodyParams, isAudacesErp)
        .pipe(take(1))
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.ok) {
              this.saveToken(response.body);

              // for audaces erp api
              if(response.body.hasOwnProperty("dictionaries")) {
                this.setCacheCustomList(response.body.dictionaries);
              }

              resolve(response);
            } else {
              reject(response);
            }
          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  private updateUserFromAudacesErp() {
    return new Promise((resolve, reject) => {
      const url = this.erpServer.getUpdateAudacesUserEndpoint();

      let headerParams = new HttpHeaders();
      let bodyParams : any = {};

      headerParams = this._localStorage.getHeadersWithAuthorization();

      bodyParams['username'] = this.erpServer.erp_cfg.erp.user.name;
      bodyParams['dictionary'] = this.erpServer.erp_cfg.erp.apiAudaces?.dictionary;
      bodyParams['credential'] = this.erpServer.erp_cfg.erp.apiAudaces?.credential;

      this._integration.postRequest(this.erpServer, url, bodyParams)
        .pipe(
          take(1),
          tap((data) => console.log(data)
          ))
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.ok) {
              resolve(response);
            } else {
              reject(response);
            }
          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  private parseXmlToJson(xml: Document): any {
    const parseElement = (element: Element) => {
      const obj: any = { "m:type": element.getAttribute('m:type') };
      const childNodes = element.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];
          const nodeName = childNode.nodeName;
          const childValue = getChildNodeValue(childNode);
          obj[nodeName] = childValue;
      }
      return obj;
    };

    const getChildNodeValue = (node: Node): string => {
      if (node.hasChildNodes()) {

        for( let i =0; i < node.childNodes.length; i++) {
          let child: any = node.childNodes[i];
          let txt = child.nodeValue.trim();
          if( txt.length > 0 ) {
            return txt;
          }
        }
      }
      return '';
    };

    const rootElement = xml.documentElement;
    const elements = Array.from(rootElement.getElementsByTagName('d:element'));
    const result = elements.map(parseElement);
    return result;
  }

  private millenniumCostTable(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.erpServer.getCostTableEndpointAddress();
      let headerParams = {
        'WTS-Authorization': `${this.erpServer.erp_cfg.erp.user.name}/${this.erpServer.erp_cfg.erp.user.password!}`,
        'Content-Type' : 'text/xml; charset=utf-8'
      };

      this._integration.getRequest(this.erpServer, url, {}, new HttpHeaders(headerParams), false)
        .pipe(take(1))
        .subscribe(
          (xmlData: any) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlData.trim(), 'text/xml');
            const result = this.parseXmlToJson(xmlDoc);
            resolve(result);

          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  private loginMillennium(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.erpServer.getLoginEndpointAddress();
      let headerParams = this.erpServer.getAuthorizationHeader();

      this._integration.getRequest(this.erpServer, url, {}, headerParams)
        .pipe(take(1))
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.ok) {
              this.millenniumCostTable()
                .then(respTable => {
                  resolve(respTable);
                })
                .catch(error => {
                  reject(error);
                });

            } else {
              reject(response);
            }
          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  getReferences(
      type: 'finished_product'|'raw_material'|'activity'|'group'|'measure'|'any',
      params: any = {}
    ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.erpServer.getQueryEndpointAddress();
      let bodyParam = this.erpServer.getBodyParam(type, params);
      this._integration.getRequest(this.erpServer, url, bodyParam)
        .pipe(take(1))
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.ok) {
              resolve(response);
            } else {
              reject(response);
            }
          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  createVariantList( erpRef: any) {

    interface ERPColorV1516 {
      uid: string,
      rgb?: string,
      cmyk?: string,
      value: number,
    }
    interface ERPVariantV1516 {
      name: string,
      label: string,
      color: ERPColorV1516,
      value: number,
    }

    let colors = erpRef['colors'] || [];
    const colorLabel = this._translate.instant('color');
    const colorsMap = colors.map( (color: { code: any; description: any; }) => `${colorLabel}: [${color.code}] ${color.description}`)

    let sizes = erpRef['sizes'] || [];
    const sizeLabel = this._translate.instant('size');
    const sizeMap = sizes.map( (size: { uid: any }) => `${sizeLabel}: ${size.uid}`)

    let variantsName = []

    if( colorsMap.length ) {
      for (let color of colorsMap) {
        if( sizeMap.length == 0) {
          variantsName.push(`${color}`);
        }
        for (let size of sizeMap) {
          variantsName.push(`${color}; ${size}`);
        }
      }
      return variantsName;
    }

    for (let size of sizeMap) {
      variantsName.push(`${size}`);
    }

    const mainVarLabel = this._translate.instant('mainVariant');
    return variantsName.length? variantsName : [`${mainVarLabel}`];

  }

  handleV13V14Props(reference: any, selectedRefName: string) {

    /**
    variantions on v13 'n v14 format:
    {
      "colors": [
        {
          "description": "string",
          "uid": "string"
        }
      ],
      "sizes": [
        {
          "uid": "string"
        }
      ],
      "prices": [
        {
          "color": "string",
          "price": "string",
          "size": "string"
        }
      ]
    }
    */

    let hasColor = reference.colors && reference.colors.length > 0;
    let hasSize = reference.sizes && reference.sizes.length > 0;
    let hasPrice = reference.prices && reference.prices.length > 0;

    if(hasColor || hasSize) {
      let parts = selectedRefName.split("; ");
      if( hasColor ) {
        reference['color'] = parts[0].split(":")[1].trim();
      }
      let index = hasColor ? 1 : 0;
      if( hasSize ) {
        reference['size'] = parts[index].split(":")[1].trim();
      }
    }

    if( hasPrice ) {
      let color = reference['color'].match(/\[(.*?)\]/)?.[1] || "";
      let size = reference['size'];

      let resultado;
      if(hasColor && hasSize) {
        resultado = reference.prices.find((item: { color: string; size: string; }) => item.color === color && item.size === size);
      }
      if(hasColor && !hasSize) {
        resultado = reference.prices.find((item: { color: string; }) => item.color === color);
      }
      if(!hasColor && hasSize) {
        resultado = reference.prices.find((item: { size: string; }) => item.size === size);
      }

      if (resultado) {
          reference['value'] = resultado.price;
      }
    }
  }

  handleV15V16Props(reference: any, selectedRef: any) {

    /**
    variant v15 e v16 format:
    selectedRef = {
      "name": "variante 2",
      "value": 11.15,
      "label": "variante Verde M",
      "color": {
          "uid": "0100",
          "rgb": "00FF00",
          "value": "Verde"
      },
      "size": "M",
      "composition": "15% silk"
    }
    */

    if(!reference.hasOwnProperty('variants')) {
      return;
    }

    if( selectedRef.hasOwnProperty('color') ) {
      reference['color'] = selectedRef.color;
    }

    if( selectedRef.hasOwnProperty('size') ) {
      reference['size'] = selectedRef.size;
    }

    if( selectedRef.hasOwnProperty('value') && selectedRef.value ) {
      reference['value'] = selectedRef.value;
    }

  }

  isConfigured() : boolean {
    return this.erpServer.isConfigured();
  }

  public doLogin(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.erpServer.getLoginEndpointAddress(false);

      let bodyParams : any = {};

      this.erpServer.erp_cfg.erp.user.name = username;
      bodyParams['username'] = username;
      bodyParams['password'] = password;

      this._integration.postRequest(this.erpServer, url, bodyParams, false)
        .pipe(take(1))
        .subscribe(
          (response: HttpResponse<any>) => {
            if (response.ok) {
              this.saveToken(response.body);
              resolve(response);
            } else {
              reject(response);
            }
          },
          (error: HttpResponse<any>) => {
            reject(error); // Handle subscription error
          }
        );
    });
  }

  public erpErrorMessage( code: number ) : string {
    let connectionMessage = 'erp.messages.error';
    switch (code) {
      case -1:
        return "";
      case 200:
        connectionMessage = "erp.messages.success";
        break;
      case 0:
        connectionMessage = "erp.messages.error";
        break;

      case 400:
        connectionMessage = 'erp.messages.error400'
        break;
      case 401:
      case 403:
        connectionMessage = 'erp.messages.error401'
        break;
      case 404:
        connectionMessage = 'erp.messages.error404'
      break;
    }

    if( code >= 300 && code < 400) {
      connectionMessage = 'erp.messages.error300';
    } else if( code >= 500 ) {
      connectionMessage = 'erp.messages.error500';
    }

    return connectionMessage;
  }

  scanDatasheet( updateRefs: Reference[] ) {
    let funcsUpdate = []
    for( let ref of updateRefs) {
      let uid = ref.erp!.external_id;
      funcsUpdate.push( this.getReferences(ref.erp!.type!, {uid}) );
    }
    const res = forkJoin(funcsUpdate);
    return res;

  }

  getResult(body: any) {

    if( typeof body == 'object' ) {
      if( body.hasOwnProperty('length') ) {
        return body;
      }
      if( body.hasOwnProperty('value') ) {
        return body.value;
      }
    }
    return [];
  }
}
