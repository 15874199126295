import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { DatasheetService } from 'src/app/services/datasheet.service';
import { MatStepper } from '@angular/material/stepper';
import { ErpServiceService } from 'src/app/services/erp-service.service';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { ReferencesService } from 'src/app/services/references.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageDialogComponent, MessageDialogType } from '../message-dialog/message-dialog/message-dialog.component';
import { DialogErpLoginComponent } from '../erp-login-form/erp-login-form.component';
import { Reference } from 'src/app/models/reference';
import { ReferenceType } from 'src/app/models/enums/reference-type';
import { ReferenceErpUtil } from 'src/app/models/referenceErpUtil';
import { CustomFieldCreatorData } from '../custom-field-creator/custom-field-creator.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CategoryField } from 'src/app/models/category';
import { v4 as uuidv4 } from 'uuid';
import { typeOf } from 'mathjs';

interface AdvFilter {
  name: string;
  code: string;
}

@Component({
  selector: 'app-add-reference-dialog',
  templateUrl: './add-reference-dialog.component.html',
  styleUrls: ['./add-reference-dialog.component.scss'],
})
export class AddReferenceDialogComponent implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;
  public selectedReferenceType: string = 'raw_material';
  public referenceType: string[] = [ 'raw_material', 'activity', 'measure', 'generic', 'group', 'any']; // ['finished_product', 'raw_material', 'activity', 'group', 'measure'];
  public selectRefVariant: any;
  public variants: any[] = [];
  public selectedVariants: any[] = [];

  public searchRefForm: FormGroup;
  public searchCustomfieldsForm: FormGroup;
  public insertRefForm: FormGroup;
  public referenceData: any;
  public isloading: boolean = false;
  public isFetching: boolean = false;
  public isLoadingItem: boolean = false;
  public searchRefs: boolean = false;

  public insertInModel: boolean = true;
  public keepWindowOpened: boolean = true;

  public customFieldOptions: any[] = [];
  public garmentVariant: string[] = [""];

  selectedIndex: number | null = null;
  selectedRef: any;
  datasheetUid: string = "";
  variantionName: string = "";

  applyAtLeast1x = false;

  targetList?: any;
  targetName?: string;
  refType?: string;

  public default_keys: string[] = [
    'type',
    'uid',
    'name',
    'reference',
    'description',
    'notes',
    'time',
    'value',
    'total',
    'sector',
    'machine',
    'product_group',
    'supplier',
    'measure_unit',
    'images',
    'values',
    'order',
  ];


  advFilter: AdvFilter[] | undefined;
  selectedFilter: AdvFilter | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _datasheetService: DatasheetService,
    private _erpService: ErpServiceService,
    private _snack: SnackbarService,
    private translate: TranslateService,
    private _localStorage: LocalStorageService,
    private _referencesServices: ReferencesService,
    private _dialogRef: MatDialogRef<AddReferenceDialogComponent>,
    private _route: ActivatedRoute,
    private _dialog: DialogService,
    private _analyticsService: AnalyticsService,
  ) {
    this.targetList = this.data.targetList || null;
    this.targetName = data.targetName || null;
    this.refType = data.refType || null;

    if(this.refType) {
      this.keepWindowOpened = false;
      switch (this.refType) {
        case ReferenceType.Material:
          this.selectedReferenceType = 'raw_material';
          break;
        case ReferenceType.Activity:
          this.selectedReferenceType = 'activity';
          break;
        case ReferenceType.Measures:
          this.selectedReferenceType = 'measure';
          break;
        case ReferenceType.Free:
          this.selectedReferenceType = 'any';
          break;
      }
      this.referenceType = [ this.selectedReferenceType, "group" ];
    }

    this.searchRefForm = this._fb.group({
      referenceType: ['', [Validators.required]],
      referenceCode: [''],
      referenceName: [''],
      customFilter: this._fb.group(
        {
          selectedFilter: [{ value: undefined }],
          customValue: [{ value: '', disabled: true }],
        }
      ),
    });

    this.insertRefForm = this._fb.group({
      amount: [1, [Validators.required]],
      color: ['', [Validators.required]],
      size: ['', [Validators.required]],
    });

    this.searchCustomfieldsForm = this._fb.group({});

    this._route.queryParams.subscribe(params => {
      this._erpService.erpServer.version = params['v'] || 'v1';
    });
  }

  ngOnInit(): void {
    this.getVariants();
    let tp = this._localStorage.get('erp_current_ref_type');
    if(tp && !this.refType) {
      this.selectedReferenceType = tp;
    }

    this.advFilter = [
      { name: this.translate.instant('erp.forms.filter.product_group') , code: 'product_group' },
      { name: this.translate.instant('erp.forms.filter.supplier') , code: 'supplier' },
      { name: this.translate.instant('erp.forms.filter.description') , code: 'description' },
    ];

    this.selectedFilter = {name:'', code:''};
  }

  onChangeRefType(item: string) {
    this.selectedReferenceType = item;
    this._localStorage.set('erp_current_ref_type', this.selectedReferenceType);
  }

  updateCustomValueValidator($event: any) {

    if( typeof $event.value == 'string' ) {
      this.selectedFilter = {
        name: $event.value,
        code: $event.value
      };
    } else {
      this.selectedFilter = $event.value;
    }

    const customFilterGroup = this.searchRefForm.get(
      'customFilter'
    ) as FormGroup;
    if( this.selectedFilter && this.selectedFilter.code ) {
      customFilterGroup.get('customValue')?.enable();
      customFilterGroup
        .get('customValue')
        ?.setValidators([Validators.required]);
    } else {
      customFilterGroup.get('customValue')?.disable();
      customFilterGroup.get('customValue')?.clearValidators();
    }

    customFilterGroup.get('customValue')?.updateValueAndValidity();
  }

  selectReference(i: number, ref: any) {
    this.selectedIndex = i;
    this.selectedRef = ref;
  }

  private getQueryParams() : any {
    let params: any = {};

    let reference = this.searchRefForm.get('referenceCode')!.value;
    let description = this.searchRefForm.get('referenceName')!.value;
    let custonFilter = (this.searchRefForm.get('customFilter')! as FormGroup);
    let customKey = this.selectedFilter?.code || ''
    let customValue = custonFilter.get('customValue')!.value;

    if(description)
      params = { ...params, description };

    if(reference)
      params = { ...params, reference };

    if(customKey && customValue)
      params = { ...params, [customKey]:customValue };

    return params;
  }

  public getReferences() {

    if( !this.searchRefForm.valid ) {
      Object.keys(this.searchRefForm.controls).forEach(key => {
        this.searchRefForm.controls[key].markAsTouched();
      });

      this.translate.get('alerts.verifyFields.text').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
      return;
    }

    let refType = this.searchRefForm.get('referenceType')!.value;

    if( !this._erpService.isConfigured() ) {
      this._dialog.displayDialog(MessageDialogComponent, {"type": MessageDialogType.ERP_NOT_CONFIGURED}, '560px');
      return;
    }

    this._analyticsService.trackStartEvent("searchFromERP");

    this.searchRefs = true;

    this._erpService.getReferences(refType, this.getQueryParams())
    .then(response => {
      this.referenceData = response.body;
      this.searchRefs = false;

      this._analyticsService.trackEndEvent("searchFromERP");
    })
    .catch(error => {
      this._analyticsService.trackEndEvent("searchFromERP");
      this.searchRefs = false;
      if( error.status == 0 )
      {
        const dialogRef = this._dialog.displayDialog(MessageDialogComponent, {"type": MessageDialogType.ERP_BAD_CONFIGURED}, '560px');
        return;
      }
      else if( error.status == 401 || error.status == 403 )
      {
        const dialogRef = this._dialog.displayDialog(DialogErpLoginComponent, null, '560px');
        dialogRef.subscribe(result => {
          if(result) {
            this.getReferences();
          }
        });
      }
      else
      {
        this._snack.failMessage(this.translate.instant('erp.addRefForm.step1.findFail') + error.message);
      }
      console.error(error);
    });
  }


  step2Click() {

    if( this.insertRefForm.controls['amount'].status !== "VALID" ) {
      this.translate.get('erp.messages.fieldAmountMandatory').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
      this.insertRefForm.controls['amount'].markAsTouched();
      return;
    }

    if( this.customFieldOptions.length && this.selectedRef.type == 'measure'){
      for( let cf of this.customFieldOptions ) {
        if( this.selectedRef.hasOwnProperty(cf.label) ) {
          this.selectedRef.value = cf.value;
        }
      }
    }
    if (this.selectedRef.custom_fields) {
      for (let cf of this.customFieldOptions) {
        const customField = this.selectedRef.custom_fields.find((field: any) => field.name === cf.label);
        if (customField) {
          customField.value = cf.value;
          customField.options = cf.options
        }
      }
    }
    if( this.customFieldOptions.length && this.selectedRef ) {
      for( let cf of this.customFieldOptions ) {
        if( this.selectedRef.hasOwnProperty(cf.label) ) {
          this.selectedRef[cf.label].value = cf.value;
        }
      }
    }

    if( this.refType == ReferenceType.Material || this.selectedReferenceType == 'raw_material' ) {
      this.selectedRef['amount'] = + this.insertRefForm.controls['amount'].value;
    }

    if( this.refType == ReferenceType.Activity || this.selectedReferenceType == 'activity'  ) {
      this.selectedRef['time'] = + this.insertRefForm.controls['amount'].value;
    }

    this.stepper.next();
  }

  public getFullReference() {
    this.isFetching = true;

    if( !this.selectedRef ) {
      this.translate.get('erp.messages.selectAReference').subscribe((trans: string) => {
        this._snack.failMessage( trans );
      });
      return;
    }

    let uid = this.selectedRef.uid;
    let refType = this.searchRefForm.get('referenceType')!.value;

    this._analyticsService.trackStartEvent("getRefFromERP");

    this._erpService.getReferences(refType, {uid})
    .then(response => {
      this._analyticsService.trackEndEvent("getRefFromERP");

      const uniqueNumber = Math.floor(Math.random() * 100000);
      this.variantionName = `fw ${refType} ${uniqueNumber}`;

      this.selectedRef = response.body[0];

      this.customFieldOptions = [];
      for(let prop in this.selectedRef) {
        let attr = this.selectedRef[prop];

        if( prop === 'time' ) {
          this.insertRefForm.controls['amount'].setValue( +attr );
        }

        if (prop === 'custom_fields' && Array.isArray(attr)){
          for(let item of attr){
            if( typeof item === 'object' && item.options ) {
              this.customFieldOptions.push(
                {
                  label: item.name,
                  editable: item.editable,
                  options: item.options,
                  type: item.type,
                  value: item.value
                }
              )
            }
          }
        }
        if( !attr ) {
          continue;
        }
        if (typeof attr === 'object' && this.selectedRef.type === 'measure') {
          const options = [];
          const order = attr.order ? attr.order.split(';') : Object.keys(attr);

          for (const key of order) {
            if (attr[key] !== undefined) {
              options.push({
                label: `${attr[key]} ${this.selectedRef.measure_unit} - (${key})`,
                value: attr[key],
                measure_unit: this.selectedRef.measure_unit
              });
            }
          }

          this.customFieldOptions.push({
            label: prop,
            editable: attr.editable,
            options: options,
            type: attr.type,
            value: attr.value
          });
        }
        if( typeof attr === 'object' && attr.hasOwnProperty("options") ) {
          this.customFieldOptions.push(
            {
              label: prop,
              editable: attr.editable,
              options: attr.options,
              type: attr.type,
              value: attr.value
            }
          )
        }

      }

      if ( !this.selectedRef.variants ) {
        this.selectedRef['variants'] = this._erpService.createVariantList(this.selectedRef);
      }

      if( this.selectedRef.variants && this.selectedRef.variants.length ) {
        this.selectRefVariant = this.selectedRef['variants'][0];
        this.onchangeSelectRefVariant();
      }

      if (this.selectedRef.hasOwnProperty("time")) {
        let time = +this.selectedRef["time"];
        this.insertRefForm.controls['amount'].setValue(time);
      }

      this.isFetching = false;
      this.stepper.next();
    })
    .catch(error => {
      this._analyticsService.trackEndEvent("getRefFromERP");
      console.error(error);
      this.isFetching = false;
    });
  }

  private getVariants() {
    this._datasheetService.dataSheetChanged
      .pipe(take(1))
      .subscribe((datasheet) => {
        this.datasheetUid = datasheet.fw!.datasheet_uid;
        const variants = datasheet.garment.variants;
        this.selectRefVariant = "";
        for( let variant of datasheet.garment.variants) {
          let variantName = datasheet.getFieldValue('code', variant.fields);
          this.variants.push({
            uid: variant.uid,
            name: variantName,
          });
          if(variant.hasOwnProperty('sub-variants')) {
            for( let subVariant of variant['sub-variants']!) {
              let subVariantName = datasheet.getFieldValue('code', subVariant.fields);
              this.variants.push({
                uid: subVariant.uid,
                name: `${variantName} - ${subVariantName}`,
              });

              if(subVariant.hasOwnProperty('sub-variants')) {
                for( let subSubVariant of subVariant['sub-variants']!) {
                  let subSubVariantName = datasheet.getFieldValue('code', subSubVariant.fields);
                  this.variants.push({
                    uid: subSubVariant.uid,
                    name: `${variantName} - ${subVariantName} - ${subSubVariantName}`,
                  });
                }
              }
            }
          }
        }

      });
  }

  insertInModelClick() {
    this.insertInModel = !this.insertInModel;

    this.garmentVariant.length = 0;
    if(this.insertInModel) {
      this.garmentVariant.push("");
    }
  }

  private insertReference(keepInserting = false) {
    if (this.garmentVariant.length === 0) {
      this.translate.get('erp.messages.selectAVariantOrModel').subscribe((trans: string) => {
        this._snack.failMessage(trans);
      });
      this.isloading = false;
      return;
    }

    const catType = ReferenceErpUtil.categoryType(this.selectedReferenceType);

    if (this.targetList) {
      if (this.selectedRef.items && Array.isArray(this.selectedRef.items)) {
        this.selectedRef.items.forEach((item :any)  => {
          this._erpService.handleV13V14Props(this.selectedRef, this.selectRefVariant);
          this._erpService.handleV15V16Props(this.selectedRef, this.selectRefVariant);
          this.insertSingleReference(item, ReferenceErpUtil.categoryType(item.type), keepInserting);
        });
      } else {
        this._erpService.handleV13V14Props(this.selectedRef, this.selectRefVariant);
        this._erpService.handleV15V16Props(this.selectedRef, this.selectRefVariant);
        this.insertSingleReference(this.selectedRef, catType, keepInserting);
      }

      this.handleKeepEditing( keepInserting );
      return;
    }

    this.isloading = true;



      this._referencesServices
        .addReference(
          this.datasheetUid,
          this.garmentVariant,
          JSON.stringify(this.selectedRef),
          catType,
          this.variantionName
        )
        .pipe(take(1))
        .subscribe({
          next: (resp) => {
            this.applyAtLeast1x = true;
            this.isloading = false;
            this.handleKeepEditing(keepInserting);

            this.variants = this.variants.filter(v => !this.garmentVariant.includes(v.uid));
          },
          error: (error) => {
            this.isloading = false;
            console.log(error);

            this.translate.get('erp.messages.insertFail').subscribe((trans: string) => {
              this._snack.failMessage(trans);
            });
          }
        });

  }

  private insertSingleReference(ref: any, catType: string, keepInserting: boolean) {
    let reference = this.createReferenceFromErp(ref, catType);
    let target = this.getTargetListByCategoryType(catType);
    const defaultAttr = ReferenceErpUtil.defaultAttrNames(catType);
    let createdCF: CustomFieldCreatorData[] = [];

    const [parentFieldsPath, seedName] = this._datasheetService.getReferencePath(
      ReferenceErpUtil.referenceType(this.refType),
      target.length,
      this.data.variantIndexes[0],
      this.data.variantIndexes[1],
      this.data.variantIndexes[2]
    );

    this.processCustomFields(ref, defaultAttr, reference, parentFieldsPath, createdCF, catType);

    this.addReferenceToDatasheet(reference, createdCF, target, catType);
  }

  private processCustomFields(
    ref: any,
    defaultAttr: string[],
    reference: any,
    parentFieldsPath: string,
    createdCF: CustomFieldCreatorData[],
    catType: string
  ) {
    for (const erpKey in ref) {
      if (defaultAttr.includes(erpKey)) continue;

      const erpData = ref[erpKey];

      if (erpKey === 'custom_fields' && Array.isArray(erpData)) {
        this.handleCustomFieldsArray(erpData, reference, parentFieldsPath, createdCF, catType);
      } else if (typeof erpData === 'object' && erpData !== null && !Array.isArray(erpData)) {
        this.handleObjectField(erpKey, erpData, reference, parentFieldsPath, createdCF, catType);
      } else if (this.isPrimitiveField(erpData)) {
        this.handlePrimitiveField(erpKey, erpData, reference, parentFieldsPath, createdCF, catType);
      }
    }

    if( typeof this.selectRefVariant === 'object' ) {
      for (const attrName in this.selectRefVariant) {
        const attribute = this.selectRefVariant[attrName];
        if( attribute && typeof attribute == 'object' && attrName != 'color' ) {
          this.handleObjectField(attrName, attribute, reference, parentFieldsPath, createdCF, catType);
        }
      }
    }
  }

  private handleCustomFieldsArray(
    fieldsArray: any[],
    reference: any,
    parentFieldsPath: string,
    createdCF: CustomFieldCreatorData[],
    catType: string
  ) {
    for (const field of fieldsArray) {
      this.createCustomField(
        field.name || field.Name,
        field.type,
        field.value,
        !this.parseBoolean(field.editable),
        field.options || [],
        reference,
        parentFieldsPath,
        createdCF,
        catType
      );
    }
  }

  private handleObjectField(
    erpKey: string,
    erpData: any,
    reference: any,
    parentFieldsPath: string,
    createdCF: CustomFieldCreatorData[],
    catType: string
  ) {

    /*
    erp field format (for material or activity)

    "XXX": {
      "editable" : "True",
      "type" : "string",
      "value" : ""
    }

    */
    if( erpData.type ) {
      this.createCustomField(
        erpData.name || erpKey,
        erpData.type,
        erpData.value,
        !this.parseBoolean(erpData.editable),
        erpData.options || [],
        reference,
        parentFieldsPath,
        createdCF,
        catType
      );
      return;
    }


    /*
    erp field format (for measure):

    "values" : {
      "P" : "5",
      "M" : "6",
      "G" : "7",
      "order" : "P;M;G"
    }

    */
    for (const key in erpData) {
      if (erpData.hasOwnProperty(key)) {
        const value = erpData[key];

        if( key == 'order' || !value ) {
          continue;
        }

        this.createCustomField(
          key,
          "Text",
          value,
          !this.parseBoolean(erpData.editable),
          erpData.options || [],
          reference,
          parentFieldsPath,
          createdCF,
          catType
        );
      }
    }

  }

  private handlePrimitiveField(
    erpKey: string,
    erpData: any,
    reference: any,
    parentFieldsPath: string,
    createdCF: CustomFieldCreatorData[],
    catType: string
  ) {
    this.createCustomField(
      erpKey,
      ReferenceErpUtil.customFieldType(erpData),
      erpData,
      true,
      [],
      reference,
      parentFieldsPath,
      createdCF,
      catType
    );
  }

  private createCustomField(
    name: string,
    type: string,
    value: any,
    readOnly: boolean,
    values: any[],
    reference: any,
    parentFieldsPath: string,
    createdCF: CustomFieldCreatorData[],
    catType: string
  ) {

    const customFieldData: CustomFieldCreatorData = {
      parentFields: reference.fields,
      parentFieldsPath,
      parentCategoryField: [],
      field: {
        name : `${name} - ${reference.erp?.external_id}`,
        type,
        value,
        read_only: readOnly,
        multLineOrIntOrNow: false,
        values
      },
      parentName: reference.fields[0].value as string,
      label: name
    };

    if (values.length > 0) {
      customFieldData.field.value = values.includes(value) ? value : '';
    }

    const data = this._datasheetService.createCustomField(customFieldData, catType);
    if (data) {
      createdCF.push(data);
    }
  }

  private parseBoolean(value: any): boolean {
    return value === true || value?.toString().toLowerCase() === 'true';
  }

  private isPrimitiveField(erpData: any): boolean {
    return (
      typeof erpData === 'boolean' ||
      typeof erpData === 'bigint' ||
      typeof erpData === 'number' ||
      typeof erpData === 'string'
    );
  }

  private addReferenceToDatasheet(
    reference: any,
    createdCF: CustomFieldCreatorData[],
    target: any[],
    catType: string
  ) {
    let usedCatFld: CategoryField[] = [];
    let catPath = "";
    for( const customFldData of createdCF ) {
      if( !customFldData.createdCustomField ) {
        continue;
      }

      usedCatFld.push(customFldData.createdCustomField.categoryField);
      catPath = customFldData.parentCategoryFieldPath!;
    }

    this._datasheetService.addReference(
      target,
      reference,
      this.data.variantIndexes,
      ReferenceErpUtil.referenceType(this.refType),
      '',
      catPath,
      usedCatFld
    );
  }

  private getTargetListByCategoryType(catType: string): any[] {
    switch (catType) {
      case 'measures':
        return this.targetList!.measures;
      case 'materials':
        return this.targetList!.materials;
      case 'activities':
        return this.targetList!.activities;
      case 'generics':
        return this.targetList!.generics;
      default:
        return [];
    }
  }

  insertAndRestartClick() {
    this._analyticsService.trackStartEvent("insertFromERP");
    this.insertReference(this.keepWindowOpened);
    this._analyticsService.trackEndEvent("insertFromERP");
  }

  onGarmentVariantClick( event: MatCheckboxChange, variant: string ) {
    const index = this.garmentVariant.indexOf(variant);
    if ( event.checked && index === -1) {
      this.garmentVariant.push(variant);
    }
    if ( !event.checked && index >= 0) {
      this.garmentVariant.splice(index, 1);
    }
  }

  private handleKeepEditing( keepInserting: boolean ) {
    if(!keepInserting) {
      this.selectedRef = null;
      this.selectedIndex = null;
      this._dialogRef.close();
    }
  }

  private createReferenceFromErp( jsonRefObj: any, erpType: string ) : Reference {
    let reference: Reference;
    const amount: number = this.insertRefForm.controls['amount'].value || 0;
    switch (erpType) {
      case ReferenceType.Material:
        reference = ReferenceErpUtil.material(jsonRefObj, amount);
        break;
      case ReferenceType.Activity:
        reference = ReferenceErpUtil.activity(jsonRefObj, amount);
        break;
      case ReferenceType.Measures:
        reference = ReferenceErpUtil.measure(jsonRefObj, amount);
        break;
      case ReferenceType.Free:
        reference = ReferenceErpUtil.generic(jsonRefObj, amount);
        break;
      default:
        reference = ReferenceErpUtil.generic(jsonRefObj, amount);
        break;
    }
    return reference!;
  }

  onchangeSelectRefVariant() {
    this._erpService.handleV13V14Props(this.selectedRef, this.selectRefVariant);
    this._erpService.handleV15V16Props(this.selectedRef, this.selectRefVariant);

    if( this.selectRefVariant.hasOwnProperty('value') && this.selectRefVariant.value !== null ) {
      this.selectedRef['value'] = this.selectRefVariant.value;
    }
  }


}
