import { ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Datasheet } from '../models/datasheet';
import { Garment } from '../models/garment';
import defaultVariant, { Variant } from '../models/variant';
import { Reference } from '../models/reference';
import { CategoryType, ReferenceType } from '../models/enums/reference-type';
import { ReferenceListDataModel } from '../models/ReferenceListDataModel';
import { StatusType } from '../models/enums/status-type';
import { LockParam } from '../models/lockParam';
import { Variation, VariationLevel } from '../models/Variation';
import { CategoryField } from '../models/category';
import { Field } from '../models/field';
import { IsaMetadata } from '../models/isaMetadata';
import { CustomFieldCreatorData, CustomFieldEdited, CustomFieldRemoved } from '../components/custom-field-creator/custom-field-creator.component';
import { ReferenceTarget } from '../models/referenceTarget';
import { v4 as uuidv4 } from 'uuid';
import { Image } from '../models/image';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { EditedDatasheetProp } from '../models/editionParams';
import { LocalStorageService } from './localStorage.service';


@Injectable({
  providedIn: 'root'
})

export class DatasheetService {

  // DataSheet
  //     |__ Garment
  //         |__ activities?: Activity[],
  //         |__ materials?: Material[],
  //         |__ variants: Variant[],
  //             |__ activities?: Activity[],
  //             |__ materials?: Material[],
  //             |__ sub_variants?: Variant[],
  //                 |__ activities?: Activity[],
  //                 |__ materials?: Material[],


  dataSheetChanged =  new ReplaySubject<Datasheet>(1);
  private datasheet = {} as Datasheet;
  private datasheet_lst: Datasheet[] = [];
  private current_variant_index: number = 0;

  private status: string = StatusType.SAVED;
  statusChanged = new Subject<string>();
  isaMetaChanged = new ReplaySubject<IsaMetadata>(1);
  sendIsaMetaChanged = new Subject<IsaMetadata>();
  imageChanged = new Subject<boolean>();

  datasheetPropsChanged = new Subject<EditedDatasheetProp[]>();
  datasheetPropsSaved = new Subject<string>();


  defaultConfigs ={
    language: undefined,
    currency: "BRL",
    measureUnit: 'mm',
    areaUnit: 'mm²',
    weightUnit: 'mg'
  }

  constructor( private _storage: LocalStorageService ) { }

  private applyFieldChanges(initialData: Garment | Variant | Reference, alteredData: any, pathBase: string, seedName: string) : EditedDatasheetProp|null {

    let edition: EditedDatasheetProp|null = null;
    let originalColorValue: any = null;

    Object.keys(alteredData).forEach(key => {
      let field = this.datasheet.getField(key, initialData.fields);
      const insertedEntity = this.datasheet.getField('code', initialData.fields).value as string;

      let isColor = this.alteredDataIsColor(alteredData, key);

      if( isColor ) {
        if(field.hasOwnProperty('value') ) {
          delete field["value"];
        }
        if( alteredData[key].hasOwnProperty("value") && alteredData[key].value ) {
          const colorValue = alteredData[key].value;
          if( colorValue.startsWith("#") ) {
            alteredData[key]['type'] = "hex";
          } else {
            alteredData[key]['type'] = colorValue.split("(")[0].toUpperCase();
          }
        }
        if( field.color ) {
          originalColorValue = field.color;
        }
        field['color'] = alteredData[key];
      }
                                                                                     //  convert to seconds
      if(field.hasOwnProperty('value') ) {                                           //       v
        const newValue = ( alteredData[key] instanceof Date ) ? alteredData[key].getTime() / 1000 : alteredData[key];

        if( field.value != newValue ) {
          const index = initialData.fields.findIndex(r => r.name === key);
          edition = {
            path : `${pathBase}.${index}.value`,
            newValue: this.datasheet.applyMeasureConvertion(newValue, key, pathBase),
            oldValue: field.value,
            baseEntity: field.name,
            seedType: this.getSeedType(pathBase),
            insertedEntity,
            seedName,
            _action: 'edit'
          }
          field.value = newValue;
        }
      }
      
      if( field.color && this.colorDataWasAltered(field.color) ) {
        const newValue = alteredData[key];

        if( !this.isEqual(newValue, originalColorValue) ) {
          const index = initialData.fields.findIndex(r => r.name === key);
          edition = {
            path : `${pathBase}.${index}.value`,
            newValue,
            oldValue: originalColorValue,
            baseEntity: field.name,
            seedType: this.getSeedType(pathBase),
            insertedEntity,
            seedName,
            _action: 'edit'
          }
          field.color = newValue;
        }
      }
    });

    if(edition && this.datasheet.fw?.datasheet_uid) {
      this.datasheetPropsChanged.next( [edition] );
    }

    return edition;
  }

  private isEqual(newValue: any, originalValue: any): boolean {

    if( newValue && !originalValue ) {
      return false;
    }

    if( originalValue?.color ) {
      if( originalValue.color?.name != newValue.name || originalValue.color?.value != newValue.value ) {
        return false;
      }
    }

    for (const key in originalValue) {
      if (originalValue[key] !== newValue[key]) {
        return false;
      }
    }

    return true;
}

  private getSeedType( path: string ) : string {

    const refListNames = ["materials", "activities", "measures", "generics",
      "patterns", "markers", "fashion_studio", "images", "reference_groups",
      "pattern_groups", "marker_groups"];

    for( const n of refListNames ) {
      if( path.indexOf(n) >=0 ) {
        return 'reference';
      }
    }

    if( path.indexOf('sub_variants') >=0 || path.indexOf('sub-variants') >=0 ) {
      return 'subVariants';
    }

    if( path.indexOf('variants') >=0 ) {
      return 'variants';
    }

    return "garment";
  }

  private alteredDataIsColor( alteredData: any, key: string ) {
    return (
      typeof alteredData[key] === 'object' &&
      !Array.isArray(alteredData[key]) &&
      alteredData[key].hasOwnProperty('alpha') &&
      alteredData[key].hasOwnProperty('name') &&
      alteredData[key].hasOwnProperty('type') &&
      alteredData[key].hasOwnProperty('value')
    );
  }

  private colorDataWasAltered( alteredData: any ) {
    return (
      alteredData['name'] ||
      alteredData['value']
    );
  }

  private applyChanges(initialData: Garment | Variant | Reference, alteredData: any) {
    Object.keys(initialData).forEach(key => {
      if (alteredData[key]) {
        initialData[key as keyof typeof initialData] = alteredData[key];
      }
    });

    this.setStatus(false);
    // test to see the dataSheet obj (service) being changed
    console.log(">> changes applied on garment|variant obj", this.getDatasheet());
  }

  private notifyDatasheet() {
    console.debug('>>>>>>>> [notifyDatasheet] this.datasheet', this.datasheet);

    this.dataSheetChanged.next(this.datasheet);
    this.statusChanged.next(this.status);
  }

  public getAllDatasheets() {
    return this.datasheet_lst;
  }

  public setDataSheet( datasheet: Datasheet, handleRefGroups: boolean = false ) {
    console.log('>> DatasheetService.setDataSheet', datasheet);
    this.datasheet = this._createInstanceOfDatasheet(datasheet);
    
    if( handleRefGroups ) {
      this.datasheet.handleReferencesGroups();
    }

    this.notifyDatasheet();

    if( this.datasheet.garment.images?.length ) {
      this.imageChanged.next(true);
    }
  }

  public clearDatasheet( ) {
    this.datasheet = new Datasheet();
  }

  private _createInstanceOfDatasheet = (object: any): Datasheet => {
    return new Datasheet( object );
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses são base 0
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  }

  public createDatasheet( isaMeta: IsaMetadata={} ) : Datasheet {
    this.setDataSheet( new Datasheet() );

    this._setIsaMeta(isaMeta);

    this.datasheet.creation_user = isaMeta.username || '';
    this.datasheet.creation_date = this.formatDate(new Date());
    this.datasheet.last_modification_user = isaMeta.username || '';
    this.datasheet.last_modification_date = this.formatDate(new Date());

    this.datasheet.language = localStorage.getItem(LocalStorageKeys.USER_CURRENT_LANGUAGE) || ''

    const configs = this.getConfigs();

    this.datasheet.measure_units ={
      area: configs.areaUnit,
      length: configs.measureUnit,
      weight: configs.weightUnit
    }

    this.datasheet.fw!.currency = configs.currency;

    this.notifyDatasheet();

    return this.getDatasheet();
  }

  private getConfigs(){
    const configs = this._storage.get(LocalStorageKeys.USER_CONFIGS, "local", false) || this.defaultConfigs
    return configs
  }

  public setIsaMeta(isaMeta: IsaMetadata, markAsModified: boolean = true ) {
    this._setIsaMeta(isaMeta);
    this.isaMetaChanged.next(isaMeta);

    if( markAsModified ) {
      this.status = StatusType.MODIFIED;
    }
    this.notifyDatasheet();
  }

  private _setIsaMeta(isaMeta: IsaMetadata) {
    if( !this.datasheet.fw ) {
      return;
    }

    const hasIsaModelId = this.datasheet.fw?.isa_model_id ? true : false;
    
    if(isaMeta.username)
      this.datasheet.fw!.isa_owner_user = isaMeta.username;
    if(isaMeta.modelId)
      this.datasheet.fw!.isa_model_id = +isaMeta.modelId;
    if(isaMeta.kanbam)
      this.datasheet.fw!.isa_kanbam = isaMeta.kanbam;
    if(isaMeta.shape)
      this.datasheet.fw!.isa_shape = isaMeta.shape;
    if(isaMeta.companyName)
      this.datasheet.fw!.isa_company = isaMeta.companyName;
    if(isaMeta.companyid)
      this.datasheet.fw!.isa_company_id =  isaMeta.companyid;

    this.datasheet.getField('code', this.datasheet.garment.fields).value = isaMeta.modelName;

    this.datasheet.getField('desc', this.datasheet.garment.fields).value = isaMeta.description;

    this.datasheet.getField('resp', this.datasheet.garment.fields).value = isaMeta.username;
    this.datasheet.getField('resp', this.datasheet.garment.fields).read_only = true;

    this.datasheet.getField('collection', this.datasheet.garment.fields).value = isaMeta.collection;
    this.datasheet.getField('collection', this.datasheet.garment.fields).read_only = true;

    if(isaMeta.variationNames||[].length) {
      let variation: Variation = {
        level: VariationLevel.ONE,
        itens : []
      }
      for( let name of isaMeta.variationNames! ) {
        variation.itens.push({
          name,
          variant: JSON.parse(JSON.stringify(defaultVariant)),
          category: 'ISA',
          children: []
        });
      }
      this.datasheet.createVariants(variation);
    }

    // backup das urls das imagens
    let imgBkp: Image[] = JSON.parse( JSON.stringify(this.datasheet.garment.images || []) );

    // zerando o array
    this.datasheet.garment.images = [];

    for( let img of isaMeta.images || [] ) {
      this.datasheet.garment.images.push({
        id: uuidv4(),
        url: img,
        format: '',
        height: 0,
        width:0,
        location: 'ISA',
      });
    }

    if( !hasIsaModelId ) {
      for( let img of imgBkp ) {
        this.datasheet.garment.images.push(img);
      }
    }

    this.imageChanged.next(true);
  }

  public getDatasheet() : Datasheet {
    return this.datasheet;
  }

  public getDatasheetUid() : string {
    return this.datasheet.fw?.datasheet_uid!;
  }

  public getGarment() : Garment {
    return this.datasheet.garment;
  }

  public setGarment(newGarment: any) {

    if( newGarment['code'] || newGarment['desc'] ) {
      let isaMeta: IsaMetadata = {
        modelId: this.datasheet.fw!.isa_model_id
      };
      if( newGarment['code'] && newGarment['code'] != this.datasheet.garment.fields[0].value  ) {
        isaMeta.modelName = newGarment['code'];
      }
      if( newGarment['desc'] && newGarment['desc'] != this.datasheet.garment.fields[2].value ) {
        isaMeta.description = newGarment['desc'];
      }

      this.sendIsaMetaChanged.next(isaMeta);
    }

    this.applyFieldChanges(
      this.datasheet.garment,
      newGarment,
      'garment.fields',
      'garment'
    );

    this.applyChanges(this.datasheet.garment, newGarment);
    this.notifyDatasheet();
  }

  public getVariant(indexVariant: number) : Variant | undefined {
    this.current_variant_index = indexVariant;

    if ( indexVariant >= 0 && indexVariant < this.datasheet.garment.variants.length ) {
      return this.datasheet.garment.variants[indexVariant];
    }
    return undefined;
  }

  public getCurrentVariant() : Variant {
    return this.datasheet.garment.variants[this.current_variant_index];
  }

  public setVariant(indexVariant: number, variant: Variant) : void {

    let currentVariant = this.getVariant(indexVariant);

    if ( currentVariant === undefined ) {
      return;
    }

    this.applyFieldChanges(
      currentVariant, variant,
      `garment.variants.${indexVariant}.fields`,
      currentVariant.fields[0].value as string
    );
    this.applyChanges(currentVariant, variant);

    console.log('>>>>>>>> setVariant', variant);
    this.notifyDatasheet();
  }

  public getSubVariant(indexVariant: number, indexSubVariant: number ) : Variant | undefined {
    const variante = this.getVariant(indexVariant);
    if( variante && indexSubVariant >= 0 && variante['sub-variants'] && indexSubVariant < variante['sub-variants'].length ) {
      return variante['sub-variants'][indexSubVariant];
    }

    return undefined;
  }

  public getSubSubVariant(indexVariant: number, indexSubVariant: number, indexSubSubVariant: number ) : Variant | undefined {
    const subVariante = this.getSubVariant(indexVariant, indexSubVariant);

    if( subVariante && indexSubSubVariant >= 0 && subVariante['sub-variants'] && indexSubSubVariant < subVariante['sub-variants'].length ) {
      return subVariante['sub-variants'][indexSubSubVariant];
    }

    return undefined;
  }

  public setSubVariant(indexVariant: number, indexSubVariant: number, subVariant: Variant) : void {

    let currentSubVariant = this.getSubVariant(indexVariant, indexSubVariant);

    if ( currentSubVariant === undefined ) {
      return;
    }

    this.applyFieldChanges(
      currentSubVariant,
      subVariant,
      `garment.variants.${indexVariant}.sub_variants.${indexSubVariant}.fields`,
      currentSubVariant.fields[0].value as string
    );

    console.log('>>>>>>>> setSubVariant', subVariant);
    this.notifyDatasheet();
  }

  public setSubSubVariant(indexVariant: number, indexSubVariant: number, indexSubSubVariant: number, subSubVariant: Variant) : void {

    let currentSubSubVariant = this.getSubSubVariant(indexVariant, indexSubVariant, indexSubSubVariant);

    if ( currentSubSubVariant === undefined ) {
      return;
    }

    this.applyFieldChanges(
      currentSubSubVariant,
      subSubVariant,
      `garment.variants.${indexVariant}.sub_variants.${indexSubVariant}.sub_variants.$${indexSubSubVariant}].fields`,
      currentSubSubVariant.fields[0].value as string
    );

    console.log('>>>>>>>> setSubSubVariant', subSubVariant);
    this.notifyDatasheet();
  }

  public setReference(currentRef : Reference, newRef: object, refecenceBasePath: string, refecenceBaseName: string ) : void {
    this.applyFieldChanges(currentRef, newRef, refecenceBasePath, refecenceBaseName);
    this.notifyDatasheet();
  }

  public getReference(
    referenceType: ReferenceType,
    indexReference: number,
    indexVariant?: number,
    indexSubVariant?: number,
    indexSubSubVariant?: number
  ): Reference | undefined {
    let reference: Reference[] | undefined;
    if (indexVariant === undefined) {
      reference = this.datasheet.garment[referenceType];
    } else if (indexSubVariant === undefined) {
      reference = this.datasheet.garment.variants[indexVariant][referenceType];
    } else if (indexSubSubVariant === undefined) {
      reference = this.datasheet.garment.variants[indexVariant]['sub-variants']![indexSubVariant][referenceType];
    } else {
      reference = this.datasheet.garment.variants[indexVariant]['sub-variants']![indexSubVariant]['sub-variants']![indexSubSubVariant][referenceType];
    }
    return reference?.[indexReference];
  }

  public getReferencePath(
    referenceType: ReferenceType,
    indexReference: number,
    indexVariant?: number,
    indexSubVariant?: number,
    indexSubSubVariant?: number,
    asList: boolean = false
  ): [string, string] {
    let resp:[string, string] = ['',''];
    if (indexVariant === undefined) {
      resp = [
        `garment.${referenceType}.${indexReference}.fields`,
        'garment'
      ]
    } else if (indexSubVariant === undefined) {
      resp = [
        `garment.variants.${indexVariant}.${referenceType}.${indexReference}.fields`,
        this.datasheet.garment.variants[indexVariant].fields[0].value as string
      ];
    } else if (indexSubSubVariant === undefined) {
      resp = [
        `garment.variants.${indexVariant}.sub_variants.${indexSubVariant}.${referenceType}.${indexReference}.fields`,
        this.datasheet.garment.variants[indexVariant]['sub-variants']![indexSubVariant]!.fields[0].value as string

      ];
    } else {
      resp = [
        `garment.variants.${indexVariant}.sub_variants.${indexSubVariant}.sub_variants.${indexSubSubVariant}.${referenceType}.${indexReference}.fields`,
        this.datasheet.garment.variants[indexVariant]['sub-variants']![indexSubVariant]!['sub-variants']![indexSubSubVariant]!.fields[0].value as string
      ];
    }

    if( asList ) {
      resp[0] = resp[0].split(referenceType)[0] + referenceType;
    }

    return resp;
  }


  public getReferenceTargetNameType(
    indexVariant?: number,
    indexSubVariant?: number,
    indexSubSubVariant?: number
  ): [string, string, string] {

    if (indexSubSubVariant !== undefined) {
      return [
        this.datasheet.garment.variants[indexVariant!].fields[0].value as string + " - " +
        this.datasheet.garment.variants[indexVariant!]["sub-variants"]![indexSubVariant!].fields[0].value as string + " - " +
        this.datasheet.garment.variants[indexVariant!]["sub-variants"]![indexSubVariant!]["sub-variants"]![indexSubSubVariant!].fields[0].value as string,
        'Variant',
        'SubVariant'
      ];
    } else if (indexSubVariant !== undefined) {
      return [
        this.datasheet.garment.variants[indexVariant!].fields[0].value as string + " - " +
        this.datasheet.garment.variants[indexVariant!]["sub-variants"]![indexSubVariant].fields[0].value as string,
        'Variant',
        'SubVariant'
      ];
    } else if (indexVariant !== undefined) {
      return [
        this.datasheet.garment.variants[indexVariant].fields[0].value as string,
        'Variant',
        'Variant',
      ]
    } else {
      return [
        this.datasheet.garment.fields[0].value as string,
        'Modelo',
        ''
      ];
    }
  }

  private cloneReference( reference: Reference, referenceType: ReferenceType) {
    let cp:Reference = {
      uid: reference.uid,
      id_idea: reference.id_idea,
      local: reference.local,
      fields: []
    }

    let catType = CategoryType[referenceType];
    let catFields = this.getDatasheet().categories[catType]!.fields;
    for(let field of reference.fields) {
      if( field.name == "erpURL" || field.name == "last_modified" ) {
        continue;
      }
      let catgr = this.datasheet.getCategoryField(field.name, catFields);
      let cpField = Object.assign({}, field);
      cpField.name = catgr.label;
      cp.fields.push(cpField);
    }

    return cp;
  }

  private _getRefListFromVariant(referenceType: ReferenceType, variant_obj: Variant, prefix:string = ''): ReferenceListDataModel[] {
    let rldm: ReferenceListDataModel[] = [];

    if(!variant_obj.hasOwnProperty(referenceType))
      return rldm;

    for( let ref of variant_obj[referenceType]!){
      rldm.push({
        checked: false,
        parentName: prefix + this.datasheet.getFieldValue('code', variant_obj.fields),
        parentId: variant_obj.uid,
        referenceId: ref.uid,
        reference: this.cloneReference(ref, referenceType)
      });
    }
    return rldm;
  }

  public getReferenceListDataModel(referenceType: ReferenceType, variantId: string): ReferenceListDataModel[]  {
    let rldm: ReferenceListDataModel[] = [];

    if(this.datasheet.garment.hasOwnProperty(referenceType)) {
      for( let ref of this.datasheet.garment[referenceType]!){
        rldm.push({
          checked: false,
          parentName: 'Modelo',
          parentId: this.datasheet.fw?.datasheet_uid!,
          referenceId: ref.uid,
          reference: this.cloneReference(ref, referenceType)
        });
      }
    }

    let rldmSv: ReferenceListDataModel[] = [];
    let rldmSsv: ReferenceListDataModel[] = [];
    for( let variant of this.datasheet.garment.variants){
      if(variant.uid != variantId){
        continue
      }

      rldm = rldm.concat( this._getRefListFromVariant(referenceType, variant) );
      if(variant['sub-variants']){
        let variantName = this.datasheet.getFieldValue('code', variant.fields);
        for( let subvariant of variant['sub-variants']){
          let subvariantName = this.datasheet.getFieldValue('code', subvariant.fields);
          rldmSv = rldmSv.concat( this._getRefListFromVariant(referenceType, subvariant, `${variantName} - `) );

          if(subvariant['sub-variants']){
            for( let subsubvariant of subvariant['sub-variants']) {
              rldmSsv = rldmSsv.concat( this._getRefListFromVariant(referenceType, subsubvariant, `${variantName} - ${subvariantName} - `) );
            }
          }
        }
      }
    }

    return rldm.concat(rldmSv, rldmSsv);
  }

  public getMaterial(indexMaterial: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Material, indexMaterial, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getActivity(indexActivity: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Activity, indexActivity, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getMeasure(indexMeasure: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Measures, indexMeasure, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getGeneric(indexGeneric: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Free, indexGeneric, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getPattern(indexPattern: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Pattern, indexPattern, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getMarker(indexMarker: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.Marker, indexMarker, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getFashionStd(indexFashionStd: number, indexVariant?: number, indexSubVariant?: number, indexSubSubVariant?: number ) : Reference | undefined {
    return this.getReference(ReferenceType.FashionStd, indexFashionStd, indexVariant, indexSubVariant, indexSubSubVariant);
  }

  public getStatus() {
    return this.status;
  }

  public setStatus(saved:boolean) {
    this.status = saved ? StatusType.SAVED : StatusType.MODIFIED;
    this.statusChanged.next(this.status);
  }

  public getKanbamAndShape(): { isa_kanbam: string, isa_shape: string } | null {
    const { isa_kanbam, isa_shape } = this.datasheet.fw!;
      return { isa_kanbam, isa_shape };
 }
 public getIsaModelId(): number{
  return this.datasheet.fw?.isa_model_id!;
 }

  private clearDatasheetCache() {
    this.datasheet = {} as Datasheet;
  }

  public leaveRouteWithoutSaving() {
    this.clearDatasheetCache();
    this.notifyDatasheet();
  }

  public isLocked( userMail: string): LockParam | null {
    const lockedBy = this.datasheet.fw?.lockedBy || "";
    const lockedAt = this.datasheet.fw?.lockedAt || "";
    const lockedSystem = this.datasheet.fw?.lockedSystem || "";
    if( lockedBy.indexOf( userMail ) >= 0 ) {
      return null;
    }

    if( !lockedAt && !lockedBy && !lockedSystem ) {
      return null;
    }

    return {
      lockedAt,
      lockedBy,
      lockedSystem
    };
  }

  public setDatasheetReadonly() {
    this.datasheet.setReadonly();

    this.notifyDatasheet();
  }

  public isDatasheetStandalone() : boolean {
    return this.datasheet.isStandAlone() || false;
  }

  public updateDatasheetStatus(status: any): void {
    if (status) {
        this.datasheet.setIdeaxState(status.status.is_ideax_updated);
        this.datasheet.steIsaUnlockState(status.status.is_isa_unlocked);

    }
    this.notifyDatasheet();
  }

  public isIdeaxUpdated(): any {
    return this.datasheet.fw?.is_ideax_updated;
  }

  public setUnlockState(){
    this.datasheet.fw!.is_isa_unlocked = false;
  }

  public createVariants( template: Variation) {

    console.log('input variation', template);
    this.datasheet.createVariants(template);
    console.log('generated variation', this.datasheet.getVariation() );

    if( this.datasheet.fw?.datasheet_uid ) {
      this.notifyVariantAdded(this.datasheet.garment.variants, true);
    }

    // this.status = StatusType.MODIFIED;
    this.notifyDatasheet();
  }

  public updateVariants( template: Variation) {

    // console.log('input variation', template);
    const hasChange = this.datasheet.updateVariants(template);
    // console.log('generated variation', this.datasheet.getVariation() );

    if( hasChange && this.datasheet.fw?.datasheet_uid ) {
      this.notifyVariantAdded(this.datasheet.garment.variants, false);
    }

    // this.status = StatusType.MODIFIED;
    this.notifyDatasheet();
  }

  private notifyVariantAdded( variants: Variant[], isAddVariant: boolean) {

    const edition: EditedDatasheetProp = {
      path : 'garment.variants',
      newValue: variants,
      oldValue: '',
      baseEntity: '',
      seedType: 'Modelo',
      targetType: 'Modelo',
      insertedEntity: '',
      seedName: 'Modelo',
      _action: isAddVariant ? 'addVariants' : 'editVariants'
    }

    if( this.datasheet.fw?.datasheet_uid ) {
      this.datasheetPropsChanged.next([edition]);
    }
  }

  public createCustomField( data: CustomFieldCreatorData, categoryKey: string = "" ) : CustomFieldCreatorData|null {

    switch (categoryKey) {
      case 'garment':
        data.parentCategoryField = this.datasheet.categories.garment.fields;
        data.parentCategoryFieldPath = "categories.garment.fields";
        break;
      case 'variant':
        data.parentCategoryField = this.datasheet.categories.variant.fields;
        data.parentCategoryFieldPath = "categories.variant.fields";
        break;
      case 'materials':
        data.parentCategoryField = this.datasheet.categories.material.fields;
        data.parentCategoryFieldPath = "categories.material.fields";
        break;
      case 'activities':
        data.parentCategoryField = this.datasheet.categories.activity.fields;
        data.parentCategoryFieldPath = "categories.activity.fields";
        break;
      case 'measures':
        data.parentCategoryField = this.datasheet.categories.measures.fields;
        data.parentCategoryFieldPath = "categories.measures.fields";
        break;
      case 'generics':
        data.parentCategoryField = this.datasheet.categories.free.fields;
        data.parentCategoryFieldPath = "categories.free.fields";
        break;
      default:
        break;
    }

    const [field, categoryField] = this.datasheet.createCustomField(data);
    if( field && categoryField ) {
      data.createdCustomField = {
        field,
        categoryField
      }
      this.notifyDatasheet();
      return data;
    }

    return null;
  }

  public notifyCustomFieldAdded( data: CustomFieldCreatorData[] ) {

    let editions: EditedDatasheetProp[] = [];
    for( let customFieldData of data ) {
      customFieldData.createdCustomField!.parentCategoryFieldPath = customFieldData.parentCategoryFieldPath!;
      customFieldData.createdCustomField!.parentName = customFieldData.parentName!;

      const edition: EditedDatasheetProp = {
        path : customFieldData.parentFieldsPath!,
        newValue: customFieldData.createdCustomField,
        oldValue: '',
        baseEntity: customFieldData.field.name,
        seedType: this.getSeedType(customFieldData.parentFieldsPath!),
        insertedEntity: '',
        seedName: customFieldData.parentName||'',
        _action: 'addCustomField'
      }
      editions.push(edition);
    }

    if( editions.length == 0 ) {
      return;
    }

    if( this.datasheet.fw?.datasheet_uid ) {
      this.datasheetPropsChanged.next(editions);
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
  }

  public editCustomField( data: CustomFieldCreatorData ) : boolean {

    const fieldIndex = this.datasheet.editCustomField(data);
    if( fieldIndex >= 0 ) {
      const catIndex = data.parentCategoryField.findIndex(cf => cf.name == data.field.name);
      this.notifyCustomFieldEdited({
        fieldName: data.field.name,
        fieldOldName: data.field.oldName||'',
        categoryField: data.parentCategoryField[catIndex],
        parentFieldsPath: `${data.parentFieldsPath}.${fieldIndex}`,
        parentCategoryFieldPath: `${data.parentCategoryFieldPath}.${catIndex}`,
        parentName: data.parentName!
      });

      this.notifyDatasheet();
      return true;
    }

    return false;
  }

  public removeCustomField(
      parentFields: Field[],
      parentCategoryField: CategoryField[],
      fieldName: string,
      parentFieldsPath: string,
      parentCategoryFieldPath: string,
      parentName: string
  ) : boolean {

    if( this.datasheet.removeCustomField(parentFields, parentCategoryField, fieldName) ) {

      this.notifyCustomFieldRemoved({
        fieldName,
        parentFieldsPath,
        parentCategoryFieldPath,
        parentName
      });

      this.notifyDatasheet();
      return true;
    }

    return false;
  }

  public notifyCustomFieldRemoved( removed: CustomFieldRemoved ) {

    if( this.datasheet.fw?.datasheet_uid ) {
      const targetType = this.getSeedType(removed.parentFieldsPath);
      const edition: EditedDatasheetProp = {
        path : removed.parentFieldsPath,
        newValue: removed,
        oldValue: '',
        baseEntity: removed.fieldName,
        seedType: targetType == 'garment' ? 'Modelo' : targetType,
        insertedEntity: '',
        seedName: removed.parentName,
        _action: 'removeCustomField'
      }
      this.datasheetPropsChanged.next( [edition] );
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
  }

  public notifyCustomFieldEdited( edited: CustomFieldEdited ) {

    if( this.datasheet.fw?.datasheet_uid ) {
      const targetType = this.getSeedType(edited.parentFieldsPath);
      const edition: EditedDatasheetProp = {
        path : edited.parentFieldsPath,
        newValue: edited,
        oldValue: edited.fieldOldName,
        baseEntity: edited.fieldName,
        seedType: targetType == 'garment' ? 'Modelo' : targetType,
        insertedEntity: '',
        seedName: edited.parentName,
        _action: 'editedCustomField'
      }
      this.datasheetPropsChanged.next( [edition] );
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
  }

  public addReference(
    list: Reference[],
    reference: Reference,
    variantIndexes: number[],
    refType: ReferenceType,
    refTypeName: string = "",
    categoriesPath: string = "",
    categoriesFields: CategoryField[] = []
  ) : Reference {
    let referenceClone = JSON.parse(JSON.stringify(reference));
    referenceClone.uid = uuidv4();
    const nextIndex = this.datasheet.getNextDefaultRefName( refTypeName );
    if( refTypeName.length ) {
      referenceClone.fields[0].value = `${refTypeName} ${nextIndex}`;
      referenceClone.variation_name = `${refTypeName}${nextIndex}-${referenceClone.uid}`;
    } else {
      referenceClone.variation_name = `${refTypeName}-${referenceClone.uid}`;
    }
    list.push( referenceClone );
    if( categoriesFields.length && categoriesPath.length ) {
      let targetCats;
      if( categoriesPath.indexOf("material") !== -1 )
        targetCats = this.datasheet.categories.material.fields;
      else if( categoriesPath.indexOf("activity") !== -1 )
        targetCats = this.datasheet.categories.activity.fields;
      else if( categoriesPath.indexOf("measures") !== -1 )
        targetCats = this.datasheet.categories.measures.fields;
      else if( categoriesPath.indexOf("free") !== -1 )
        targetCats = this.datasheet.categories.free.fields;

      if( targetCats ) {
        for( const catfld of categoriesFields ) {
          if ( !targetCats.find( x => x.name == catfld.name ) ) {
            targetCats.push( catfld );
          }
        }
      }
    }
    this.datasheet.updateTotals();
    this.notifyDatasheet();

    const [pathBase, seedName] = this.getReferencePath( refType, list.length-1, variantIndexes[0], variantIndexes[1], variantIndexes[2], true );
    const [targetName, seedType, targetType] = this.getReferenceTargetNameType( variantIndexes[0], variantIndexes[1], variantIndexes[2] );

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : pathBase,
        newValue: referenceClone,
        seedName,
        targetName,
        baseEntity: referenceClone.fields[0].value as string,
        targetType,
        seedType,
        oldValue: "",
        insertedEntity: '',
        categoriesPath,
        categoriesFields,
        _action: 'addReference'
      }
      this.datasheetPropsChanged.next([edition]);
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }

    return referenceClone;
  }

  public removeReference( list: Reference[], reference: Reference, variantIndexes: number[], refType: ReferenceType ) {

    const [pathBase, seedName] = this.getReferencePath( refType, list.length-1, variantIndexes[0], variantIndexes[1], variantIndexes[2], true );
    const [targetName, seedType, targetType] = this.getReferenceTargetNameType( variantIndexes[0], variantIndexes[1], variantIndexes[2] );

    const index = list.findIndex(r => r.uid === reference.uid);
    if (index > -1) {
      list.splice(index, 1);
      this.datasheet.updateTotals();
      this.notifyDatasheet();
    }



    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : pathBase,
        newValue: reference.uid,
        targetName,
        baseEntity: reference.fields[0].value as string,
        targetType,
        seedName: seedName=='garment'?'':seedName,
        seedType,
        oldValue: "",
        insertedEntity: '',
        _action: 'removeReference'
      }
      this.datasheetPropsChanged.next([edition]);
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
  }

  public copyReference( reference: Reference, target: ReferenceTarget, refType: ReferenceType, variantIndexes: number[]) {

    const referenceClone = this.datasheet.copyReference( reference, target, refType);

    const [targetName, seedType, targetType] = this.getReferenceTargetNameType( variantIndexes[0], variantIndexes[1], variantIndexes[2] );

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : this.datasheet.getTargetPathToCopyOrMove(target.target_uid, refType),
        newValue: referenceClone,
        seedName: target.target_name,
        targetName: target.target_name,
        baseEntity: referenceClone.fields[0].value as string,
        targetType,
        seedType,
        oldValue: "",
        insertedEntity: '',
        _action: 'addReference'
      }
      this.datasheetPropsChanged.next([edition]);
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
  }

  public moveReference( currentLevel: number, parent: Reference[], reference: Reference, target: ReferenceTarget, refType: ReferenceType, variantIndexes: number[]) {

    this.datasheet.moveReference( currentLevel, parent, reference, target, refType);

    const [targetName, seedType, targetType] = this.getReferenceTargetNameType( variantIndexes[0], variantIndexes[1], variantIndexes[2] );

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : this.datasheet.getTargetPathToCopyOrMove(target.target_uid, refType),
        newValue: reference,
        seedName: target.target_name,
        targetName,
        baseEntity: reference.fields[0].value as string,
        targetType,
        seedType,
        oldValue: "",
        insertedEntity: '',
        _action: 'moveReference'
      }
      this.datasheetPropsChanged.next([edition]);
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }

    this.imageChanged.next(true);
  }

  public addImage( img: Image, position: number = -1 ) {
    this.datasheet.addImage(img, position);
  }

  public async notifyAddImage( ) {

    if( this.datasheet.garment.images?.length ) {
      this.imageChanged.next(true);
    }

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : 'garment.images',
        newValue: this.datasheet.garment.images,
        oldValue: '',
        baseEntity: '',
        seedType: 'Modelo',
        targetType: 'Modelo',
        insertedEntity: '',
        seedName: 'Modelo',
        _action: 'addImage'
      }

      this.datasheetPropsChanged.next([edition]);

    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }

  }

  public moveImage( index: number, direction: number ) {
    if( !this.datasheet.moveImage( index, direction ) ) {
      return;
    }

    this.imageChanged.next(true);

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : 'garment.images',
        newValue: this.datasheet.garment.images,
        oldValue: '',
        baseEntity: '',
        seedType: 'Modelo',
        targetType: 'Modelo',
        insertedEntity: '',
        seedName: 'Modelo',
        _action: 'moveImage'
      }

      this.datasheetPropsChanged.next([edition]);

    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }

  }

  public markImageAsDeleted( imgUid: string ) {
    this.datasheet.markImageAsDeleted(imgUid);

    this.imageChanged.next(true);

    if( this.datasheet.fw?.datasheet_uid ) {
      const edition: EditedDatasheetProp = {
        path : 'garment.images',
        newValue: this.datasheet.garment.images,
        oldValue: '',
        baseEntity: '',
        seedType: 'Modelo',
        targetType: 'Modelo',
        insertedEntity: '',
        seedName: 'Modelo',
        _action: 'removeImage'
      }

      this.datasheetPropsChanged.next([edition]);

    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }

  }

  public getModelImages() : Image[] {
    return this.datasheet.getModelImages();
  }

  public updateDatasheetTotals() : Datasheet {
    const index_max_total = this.datasheet.garment.fields.findIndex(r => r.name === 'max_total');
    const index_mat_max_cost = this.datasheet.garment.fields.findIndex(r => r.name === 'mat_max_cost');
    const index_act_max_cost = this.datasheet.garment.fields.findIndex(r => r.name === 'act_max_cost');

    const old_max_total    = this.datasheet.garment.fields[index_max_total].value;
    const old_mat_max_cost = this.datasheet.garment.fields[index_mat_max_cost].value;
    const old_act_max_cost = this.datasheet.garment.fields[index_act_max_cost].value;

    const ds = this.datasheet.updateTotals();

    const new_max_total    = this.datasheet.garment.fields[index_max_total].value;
    const new_mat_max_cost = this.datasheet.garment.fields[index_mat_max_cost].value;
    const new_act_max_cost = this.datasheet.garment.fields[index_act_max_cost].value;

    let edt: EditedDatasheetProp[] = [];
    if( old_max_total != new_max_total ) {
      edt.push({
        newValue: new_max_total,
        oldValue: old_max_total,
        path: `garment.fields.${index_max_total}.value`,
        baseEntity: this.datasheet.garment.fields[index_max_total].name,
        seedType: 'garment',
        seedName: 'garment',
        insertedEntity: '',
        _action: 'edit'
      });
    }
    if( old_mat_max_cost != new_mat_max_cost ) {
      edt.push({
        newValue: new_mat_max_cost,
        oldValue: old_mat_max_cost,
        path: `garment.fields.${index_mat_max_cost}.value`,
        baseEntity: this.datasheet.garment.fields[index_mat_max_cost].name,
        seedType: 'garment',
        seedName: 'garment',
        insertedEntity: '',
        _action: 'edit'
      });
    }
    if( old_act_max_cost != new_act_max_cost ) {
      edt.push({
        newValue: new_act_max_cost,
        oldValue: old_act_max_cost,
        path: `garment.fields.${index_act_max_cost}.value`,
        baseEntity: this.datasheet.garment.fields[index_act_max_cost].name,
        seedType: 'garment',
        seedName: 'garment',
        insertedEntity: '',
        _action: 'edit'
      });
    }

    if( edt.length && this.datasheet.fw?.datasheet_uid ) {
      this.datasheetPropsChanged.next( edt );
    } else {
      this.status = StatusType.MODIFIED;
      this.notifyDatasheet();
    }
    return ds;
  }

  notifyDatasheetPropsSaved( start: boolean, fail: boolean = false ) {

    if( fail ) {
      this.datasheetPropsSaved.next('fail');
      return;
    }

    if( start ) {
      this.datasheetPropsSaved.next('start');
    } else {
      this.datasheetPropsSaved.next('stop');
    }
  }


}
