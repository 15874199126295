import { Component, OnInit, ViewChild, AfterViewInit, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable, pairwise, startWith, take, takeUntil, Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

import { DatasheetService } from '../../services/datasheet.service';
import { Variant } from '../../models/variant';
import { Datasheet } from '../../models/datasheet';

import { MatSidenav } from '@angular/material/sidenav';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Field } from 'src/app/models/field';
import { StatusType } from 'src/app/models/enums/status-type';
import { Variation } from 'src/app/models/Variation';
import { DialogService } from 'src/app/services/dialog.service';
import { VariantManangerComponent } from '../variant-mananger/variant-mananger.component';
import { AdvancedVariantManangerComponent } from '../advanced-variant-mananger/advanced-variant-mananger.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { IsaService } from 'src/app/services/isa.service';
import { IsaCollectionsDialogComponent } from '../isa-collections-dialog/isa-collections-dialog.component';
import { SnackbarService } from '../snack-bar/snack-bar-service';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldCreatorComponent, CustomFieldCreatorData, GeneretedCustomField } from '../custom-field-creator/custom-field-creator.component';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CategoryField } from 'src/app/models/category';


@Component({
  selector: 'app-datasheet-stepper',
  templateUrl: './datasheet-stepper.component.html',
  styleUrls: ['./datasheet-stepper.component.scss'],
})
export class DatasheetStepperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;
  @ViewChild('drawer') drawer!: MatSidenav;
  @ViewChild('drawer', { read: ElementRef }) drawer1Element!: ElementRef;
  private closeDrawerSub!: Subscription;
  private drawerOpenIntent = false;

  private destroy$ = new Subject<boolean>();

  public datasheet!: Datasheet;

  public showCustomFields: boolean = false;
  public customFieldValues!: FormGroup;
  public customFieldWithOptions: Field[] = [];
  public standardValuesGarment!: FormGroup;

  public standardValuesVariant!: FormGroup;
  public selectedVariant!: Variant;
  public selectedVariantIndex: number = 0;
  public showVariantCustomFields: boolean = false;
  public variantCustomFieldValues!: FormGroup;
  public variantCustomFieldWithOptions: Field[] = [];

  public standardValuesSubvariant!: FormGroup;
  public selectedSubvariant?: Variant;
  public selectedSubVariantIndex: number = 0;

  public standardValuesSubSubvariant!: FormGroup;
  public selectedSubSubvariant?: Variant;
  public selectedSubSubVariantIndex: number = 0;

  public ReferenceDataInput: any;

  public isProcessing: boolean = false;
  public hasChanges: boolean = false;
  items: MenuItem[] = [
    {
      label: this._translate.instant('shared.actions.advancedManager.label'),
      command: () => {
          this.onMenuClickShowAdvMananger();
      }
  },
  ]

  confirmation!: ConfirmationService;

  private createdVariation?: Variation;

  public editionMode: boolean = false;

  constructor(
    private _datasheetService: DatasheetService,
    private _dataStorage: DataStorageService,
    private _fb: FormBuilder,
    private _dialogService: DialogService,
    public _dialog: MatDialog,
    private _localStorageService: LocalStorageService,
    private _isaService: IsaService,
    private _snack: SnackbarService,
    private _translate: TranslateService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {

    this.selectedVariantIndex = 0;
    this.selectedSubVariantIndex = 0;

    const ds = this._datasheetService.getDatasheet();
    this.initDatasheet( ds );
    this.createdVariation = ds.getVariation();

    this.hasChanges = this.datasheet.fw?.datasheet_uid ? this.hasChanges : true;

    this._datasheetService.statusChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        this.hasChanges = status === StatusType.SAVED ? false : true;
      });

    this._datasheetService.dataSheetChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(dt => {
        this.initDatasheet(dt);
      });

    if( this._datasheetService.getDatasheet().isStandAlone() ) {
      this.editionMode = true;
    }

    this._datasheetService.datasheetPropsSaved
    .pipe(takeUntil(this.destroy$))
    .subscribe(status => {
      this.isProcessing = (status == 'start');
      this.hasChanges = status != 'stop';
    });

    this.closeDrawerSub = this._dialogService.closeDrawer$.subscribe(() => {
      if (this.drawer.opened) {
        this.drawer.close();
      }
    });
  }


  private initDatasheet( ds: Datasheet ) {
    this.datasheet = ds;

    this.createGarmentStandardValuesForm(this.datasheet);
    this.createGarmentCustomValuesForm(this.datasheet);
    if( this.datasheet.hasVariants() ) {
      this.setVariant(this.selectedVariantIndex);

      this.setSubvariant(
        this.selectedVariantIndex,
        this.selectedSubVariantIndex
      );

      this.setSubSubvariant(
        this.selectedVariantIndex,
        this.selectedSubVariantIndex,
        this.selectedSubSubVariantIndex
      );
    }

    this._datasheetService.statusChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(status => {
      this.hasChanges = status === StatusType.SAVED ? false : true;
    });

    this._datasheetService.isaMetaChanged
    .pipe(takeUntil(this.destroy$))
    .subscribe(isaMeta => {
      this.datasheet = this._datasheetService.getDatasheet();
      this.createGarmentStandardValuesForm(this.datasheet);
    });
  }

  ngAfterViewInit() {
    if (this.stepper) {
      this.stepper._getIndicatorType = () => 'number';
    }
  }

  private createGarmentStandardValuesForm(datasheet: Datasheet): void {
    const code = this.datasheet.getField('code', datasheet.garment.fields);
    const collection = this.datasheet.getField('collection', datasheet.garment.fields);
    const desc = this.datasheet.getField('desc', datasheet.garment.fields);
    const resp = this.datasheet.getField('resp', datasheet.garment.fields);
    const obs = this.datasheet.getField('obs', datasheet.garment.fields);
    const base_cost = this.datasheet.getField('base_cost', datasheet.garment.fields);
    const total = this.datasheet.getField('total', datasheet.garment.fields);
    const avgTotal = this.datasheet.getField('avg_total', datasheet.garment.fields);
    const matAvgCost = this.datasheet.getField('mat_avg_cost', datasheet.garment.fields);
    const actAvgCost = this.datasheet.getField('act_avg_cost', datasheet.garment.fields);
    const maxTotal = this.datasheet.getField('max_total', datasheet.garment.fields);
    const matMaxCost = this.datasheet.getField('mat_max_cost', datasheet.garment.fields);
    const actMaxCost = this.datasheet.getField('act_max_cost', datasheet.garment.fields);

    this.standardValuesGarment = this._fb.group({
      code: [{ value: code.value, disabled: code.read_only},{ updateOn: 'blur' }],
      collection: [{ value: collection.value, disabled: collection.read_only }, { updateOn: 'blur' }],
      desc: [{ value: desc.value, disabled: desc.read_only }, { updateOn: 'blur' }],
      resp: [{ value: resp.value, disabled: resp.read_only }, { updateOn: 'blur' }],
      obs: [{ value: obs.value, disabled: obs.read_only }, { updateOn: 'blur' }],
      base_cost: [{ value: base_cost.value, disabled: base_cost.read_only }, { updateOn: 'blur' }],
      total: [{ value: total.value, disabled: total.read_only }, { updateOn: 'blur' }], //*
      avgTotal: [{ value: avgTotal.value, disabled: avgTotal.read_only }, { updateOn: 'blur' }],
      matAvgCost: [{ value: matAvgCost.value, disabled: matAvgCost.read_only }, { updateOn: 'blur' }],
      actAvgCost: [{ value: actAvgCost.value, disabled: actAvgCost.read_only }, { updateOn: 'blur' }],
      maxTotal: [{ value: maxTotal.value, disabled: maxTotal.read_only }, { updateOn: 'blur' }],
      matMaxCost: [{ value: matMaxCost.value, disabled: matMaxCost.read_only }, { updateOn: 'blur' } ],
      actMaxCost: [{ value: actMaxCost.value, disabled: actMaxCost.read_only }, { updateOn: 'blur' }]
    });

    this.standardValuesGarment.valueChanges
    .pipe(
      startWith(this.standardValuesGarment.value),
      pairwise(),
      takeUntil(this.destroy$)
    )
    .subscribe(([prevValue, nextValue]) => {
      const changedControl = this.getChangedControl(prevValue, nextValue);

      if (!this.updatingTotalFromVariant) {
        console.log('>> garment changes: ', nextValue);
        this.hasChanges = true;
        this._datasheetService.setGarment(nextValue);

        if( changedControl == 'base_cost' ) {
          this.notifyGarmentBaeCostChange();
        }
      }
    });
  }

  private getChangedControl(prevValue: any, nextValue: any): string | null {
    for (const key in nextValue) {
      if (nextValue.hasOwnProperty(key) && prevValue[key] !== nextValue[key]) {
        return key;
      }
    }
    return null;
  }

  private updatingTotalFromVariant = false;

  private createCustomValuesForm(customFields: Field[]): FormGroup {
    const formControls: any = {};

    customFields.forEach((customField) => {
      if(customField.type === 'Color'){
        const colorFormGroup = new FormGroup({
          name: new FormControl(customField.color?.name, { updateOn: 'blur' }),
          value: new FormControl(customField.color?.value, { updateOn: 'blur' }),
          alpha: new FormControl(customField.color?.alpha, { updateOn: 'blur' }),
          type: new FormControl(customField.color?.type, { updateOn: 'blur' })
        });
        if( customField.read_only ) {
          colorFormGroup.disable();
        }
        formControls[customField.name] = colorFormGroup;
      }else{
        formControls[customField.name] = new FormControl({
          value: customField.value ,
          disabled: customField.read_only,
        }, { updateOn: 'blur' });
      }
    });

    const customFieldValues = new FormGroup(formControls);

    customFieldValues.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.hasChanges = true;
        customFields.forEach((field) => {
          const changedValue = changes[field.name];
          if (changedValue !== undefined) {
            if(field.type === 'Color'){
              field.color = { ...changedValue };
            }else{
              field.value = changedValue;
            }
          }
        });
      });
    return customFieldValues;
  }

public notifyGarmentBaeCostChange = () => {
  this._datasheetService.updateDatasheetTotals();

  if( !this.selectedVariant ) {
    return;
  }

  const updatedVariant = this.datasheet.getUpdatedVariant(this.selectedVariant.uid);
  this.updatingTotalFromVariant = true;
  this.standardValuesVariant.patchValue({
    ['total']: this.datasheet.getFieldValue('total', updatedVariant!.fields),
  });
  this.updatingTotalFromVariant = false;
}
  public notifyReferenceTotalChanges = (): void => {
    this.datasheet = this._datasheetService.updateDatasheetTotals();
    const updatedVariant = this.datasheet.getUpdatedVariant(this.selectedVariant?.uid||'');
    if( updatedVariant ) {
      this.standardValuesVariant.patchValue({
        ['total']: this.datasheet.getFieldValue('total', updatedVariant!.fields),
      });
      if(!!this.selectedSubvariant) {
        const updatedSubVariant = this.datasheet.getUpdatedVariant(this.selectedSubvariant!.uid);
        this.standardValuesSubvariant.patchValue({
          ['total']: this.datasheet.getFieldValue('total', updatedSubVariant!.fields)
        });
      }
      if(!!this.selectedSubSubvariant) {
        const updatedSubSubVariant = this.datasheet.getUpdatedVariant(this.selectedSubSubvariant!.uid);
        this.standardValuesSubSubvariant.patchValue({
          ['total']: this.datasheet.getFieldValue('total', updatedSubSubVariant!.fields)
        });
      }
    }
    this.standardValuesGarment.patchValue({
      ['matMaxCost']: this.datasheet.getFieldValue('mat_max_cost', this.datasheet.garment.fields),
      ['actMaxCost']: this.datasheet.getFieldValue('act_max_cost', this.datasheet.garment.fields)
    });
  };

  private createGarmentCustomValuesForm(datasheet: Datasheet): void {
    let customFields: Field[] = this.datasheet.getCustomFields(
      datasheet.garment.fields,
      datasheet.categories.garment.fields
    );

    this.customFieldValues = this.createCustomValuesForm(customFields);
    this.customFieldWithOptions = customFields.filter((cf) => cf.options);

    this.customFieldValues.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.hasChanges = true;
        console.log('>> garment custom fields changes: ', changes);
        this._datasheetService.setGarment(changes);
      });
  }

  private createVariantCustomValuesForm(variantIndex: number): void {
    let customFields: Field[] = this.datasheet.getCustomFields(
      this.datasheet.garment.variants[variantIndex].fields,
      this.datasheet.categories.variant.fields
    );

    this.variantCustomFieldValues = this.createCustomValuesForm(customFields);
    this.variantCustomFieldWithOptions = customFields.filter((cf) => cf.options);

    this.variantCustomFieldValues.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.hasChanges = true;
        this._datasheetService.setVariant(variantIndex, changes);
      });
  }

  hasCustomFields(customFieldValues: FormGroup): boolean {
    if (customFieldValues && customFieldValues.controls) {
      return Object.keys(customFieldValues.controls).length > 0;
    }
    return false;
  }

  public setVariant(indexVariant: number): void {

    if( !this.datasheet.hasVariants() ) {
      return;
    }

    this.selectedVariantIndex = indexVariant;
    this.selectedVariant = this._datasheetService.getVariant(indexVariant)!;
    this.createVariantCustomValuesForm(indexVariant )
    const name = this.datasheet.getFieldValue('code', this.selectedVariant.fields);
    const total = this.datasheet.getFieldValue('total', this.selectedVariant.fields);
    let variantChanged = 0;

    this.standardValuesVariant = this._fb.group({
      code: name,
      total: [{ value: total ?? '', disabled: true }],
    });

    this.standardValuesVariant.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.hasChanges = true;
        variantChanged = indexVariant;
        console.log('>> variant changes: [' + indexVariant + ']', changes);
        this._datasheetService.setVariant(indexVariant, changes);
      });

    this.standardValuesVariant.get('total')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        // garment
        this.standardValuesGarment.patchValue({
          ['maxTotal']: this.datasheet.getFieldValue('max_total', this.datasheet.garment.fields)
        });
    });

    if( this.selectedVariant['sub-variants'] && this.selectedVariant['sub-variants'].length > 0 ) {
      this.setSubvariant(indexVariant, this.selectedSubVariantIndex);
    } else {
      this.selectedSubvariant = undefined;
      this.selectedSubSubvariant = undefined;
    }
  }

  public setSubvariant(indexVariant: number, indexSubVariant: number): void {
    this.selectedSubvariant = this._datasheetService.getSubVariant(
      indexVariant,
      indexSubVariant
    )!;

    this.selectedSubVariantIndex = indexSubVariant;

    if (this.selectedSubvariant !== undefined) {
      const name = this.datasheet.getFieldValue('code', this.selectedSubvariant.fields);
      const total = this.datasheet.getFieldValue('total', this.selectedSubvariant.fields);

      this.standardValuesSubvariant = this._fb.group({
        code: name,
        total: [{ value: total ?? '', disabled: true }],
      });

      this.standardValuesSubvariant.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((changes) => {
          this.hasChanges = true;
          console.log(
            '>> subvariant changes: v[' +
              indexVariant +
              ']sbv[' +
              indexSubVariant +
              ']',
            changes
          );
          this._datasheetService.setSubVariant(
            indexVariant,
            indexSubVariant,
            changes
          );
        });
      this.setSubSubvariant(indexVariant, this.selectedSubVariantIndex, this.selectedSubSubVariantIndex);
    }
  }

  public setSubSubvariant(
    indexVariant: number,
    indexSubVariant: number,
    indexSubSubVariant: number
  ): void {
    this.selectedSubSubvariant = this._datasheetService.getSubSubVariant(
      indexVariant,
      indexSubVariant,
      indexSubSubVariant
    )!;

    this.selectedSubSubVariantIndex = indexSubSubVariant;

    if (this.selectedSubSubvariant !== undefined) {
      const name = this.datasheet.getFieldValue('code', this.selectedSubSubvariant!.fields);
      const total = this.datasheet.getFieldValue('total', this.selectedSubSubvariant!.fields);

      this.standardValuesSubSubvariant = this._fb.group({
        code: [name, [Validators.required, Validators.pattern(/^[^.:;@&]+$/)]],
        total: [{ value: total ?? '', disabled: true }]
      });

      this.standardValuesSubSubvariant.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((changes) => {
          this.hasChanges = true;
          console.log(
            '>> subsubvariant changes: v[' +
              indexVariant +
              ']sbv[' +
              indexSubVariant +
              ']ssbv[' +
              indexSubSubVariant +
              ']',
            changes
          );
          this._datasheetService.setSubSubVariant(
            indexVariant,
            indexSubVariant,
            indexSubSubVariant,
            changes
          );
        });
    }
  }

  public SelectedReferenceEvent(
    referenceData: { ReferenceType: string; ReferenceIndex: number },
    objectType: number

  ) {
    this.drawerOpenIntent = true;
    switch (objectType) {
      case 0:
        this.ReferenceDataInput = { referenceData };
        this.drawer.opened = true;
        document.body.classList.add('no-scroll');
        break;
      case 1:
        this.ReferenceDataInput = {
          referenceData,
          variantIndex: this.selectedVariantIndex,
        };
        this.drawer.opened = true;
        document.body.classList.add('no-scroll');
        break;
      case 2:
        this.ReferenceDataInput = {
          referenceData,
          variantIndex: this.selectedVariantIndex,
          subVariantIndex: this.selectedSubVariantIndex,
        };
        this.drawer.opened = true;
        document.body.classList.add('no-scroll');
        break;
      case 3:
        this.ReferenceDataInput = {
          referenceData,
          variantIndex: this.selectedVariantIndex,
          subVariantIndex: this.selectedSubVariantIndex,
          subSubVariantIndex: this.selectedSubSubVariantIndex,
        };
        this.drawer.opened = true;
        document.body.classList.add('no-scroll');
        break;
    }
  }

  public closeDrawer() {
    this.drawer.opened = false;
    document.body.classList.remove('no-scroll');
  }

  ngOnDestroy(): void {
    this.closeDrawer();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeDrawerSub.unsubscribe();
    this._datasheetService.clearDatasheet();
  }

  public onFinish(): void {
    if (!this.datasheet.verifyImagesCount(true)) {
      this._confirmationService.confirm({
        message: this._translate.instant('propertyEditorPage.maxImagesDialog.message'),
        header: this._translate.instant('propertyEditorPage.maxImagesDialog.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: this._translate.instant('propertyEditorPage.maxImagesDialog.acceptLabel'),
        rejectLabel: this._translate.instant('propertyEditorPage.maxImagesDialog.rejectLabel'),
        rejectButtonStyleClass: "hidden",
        accept: () => {
          this.confirmation.close();
        },
        reject: () => {
          this.confirmation.close();
        }
      });
      return;
    }

    if (this.datasheet.isReadyToSave()) {
      this.isProcessing = true;
      this._dataStorage.storeDataSheet(() => {
        this.isProcessing = false;

        if (!this._datasheetService.isDatasheetStandalone()) {
          this._datasheetService.setDatasheetReadonly();
          this.editionMode = false;
        }
      },
      (error: any) => {
        this.isProcessing = false;
        if (!this._datasheetService.isDatasheetStandalone()) {
          this.editionMode = false;
        }
      });
    } else {
      this.confirmation = this._confirmationService.confirm({
        message: this._translate.instant('propertyEditorPage.addToIsaDialog.message'),
        header: this._translate.instant('propertyEditorPage.addToIsaDialog.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: this._translate.instant('propertyEditorPage.addToIsaDialog.acceptLabel'),
        rejectLabel: this._translate.instant('propertyEditorPage.addToIsaDialog.rejectLabel'),
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.isaIntegration();
          this.confirmation.close();
        },
        reject: () => {
          this.confirmation.close();
        }
      });
    }
  }


  showVariantManager() {
    this._dialogService.displayDialog(
      VariantManangerComponent,
      this.createdVariation,
      '80%',
      '90%',
      'dialog-responsive'
    ).subscribe((result: Variation) => {
      if( typeof result == 'object' ) {
        this.createdVariation = result;
        this.setVariant(0);
        this.notifyReferenceTotalChanges();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.drawerOpenIntent) {
      this.drawerOpenIntent = false;
      return;
    }

    if( !this.drawer.opened ) {
      return;
    }

    const clickedInsideDrawer1 = this.drawer1Element.nativeElement.contains(event.target);
    const clickedInsideDrawer2 = event.x < document.body.clientWidth - 380;

    const hasModal = this.clickOutisideWasOnModal(event);

    if (!clickedInsideDrawer1 && clickedInsideDrawer2 && this.drawer.opened && !hasModal) {
      this.drawer.close();
    }

  }

  private clickOutisideWasOnModal(event: MouseEvent) {

    function loop( el: any ) : boolean {
      if( el.parentElement ) {
        if ( el.parentElement.className == 'dialog_container' || el.parentElement.className == 'cdk-overlay-container' ) {
          return true;
        }
        return loop(el.parentElement);
      }
      return false;
    }

    return loop(event.target);
  }

  showAdvancedManager() {
    this._dialogService.displayDialog(
      AdvancedVariantManangerComponent,
      this.createdVariation,
      '80%',
      '90%',
      'dialog-responsive'
    ).subscribe((result: Variation) => {
      if( typeof result == 'object' ) {
        this.createdVariation = result;
        this.setVariant(0);
        this.notifyReferenceTotalChanges();
      }
    });
  }

  showManager() {
    this.showVariantManager();
  }

  onMenuClickShowAdvMananger() {
    if (this.hasVariation()) {
      this.showAdvancedManager();
    } else {
      this._snack.warnMessage(this._translate.instant('propertyEditorPage.noVariation'));
    }
  }

  public hasVariation() {
    if( !this.datasheet.isStandAlone() ) {
      return false;
    }

    const hasVariants = this.datasheet.hasVariants();

    if( hasVariants && !this.selectedVariant) {
      this.setVariant(0);
    }

    return hasVariants;
  }

  isaIntegration() {
    this._dialogService.displayDialog(
      IsaCollectionsDialogComponent,
      {},
      '80%',
      '90%',
      'dialog-responsive'
    );
  }


  public createCustomField(parent: any) {
    this.openCustom().subscribe((field: GeneretedCustomField) => {
      if (field) {
        const customFieldData = this.createCustomFieldCreatorData(parent, field);
        const data = this._datasheetService.createCustomField(customFieldData);
        if(data) {
          this._datasheetService.notifyCustomFieldAdded( [data] );
        }

        if (data) {
          if (parent === 'garment') {
            this.createGarmentCustomValuesForm(this.datasheet);
          } else if (parent === 'variant') {
            this.createVariantCustomValuesForm(this.selectedVariantIndex);
          }
          this._dialog.closeAll();
        }
        else{
          this._snack.warnMessage(this._translate.instant('propertyEditorPage.duplicatedCustomField'));
        }
      }
    });
  }

  private createCustomFieldCreatorData(parent: any, field: GeneretedCustomField): CustomFieldCreatorData {
    let parentFields: any;
    let parentCategoryField: any;
    let parentFieldsPath = "";
    let parentCategoryFieldPath = "";
    let parentName = "";

    if (parent === 'garment') {
      parentFields = this.datasheet.garment.fields;
      parentFieldsPath = 'garment.fields';
      parentCategoryField = this.datasheet.categories.garment.fields;
      parentCategoryFieldPath = "categories.garment.fields";
      parentName = "garment";
    } else if (parent === 'variant') {
      parentFields = this.selectedVariant.fields;
      parentFieldsPath = `garment.variants.${this.selectedVariantIndex}.fields`;
      parentCategoryField = this.datasheet.categories.variant.fields;
      parentCategoryFieldPath = "categories.variant.fields";
      parentName = this.selectedVariant.fields[0].value as string;
    }

    const customFieldCreatorData: CustomFieldCreatorData = {
      parentFields,
      parentCategoryField,
      field,
      parentFieldsPath,
      parentCategoryFieldPath
    };

    return customFieldCreatorData;
  }

  private openCustom(data?: any): Observable<GeneretedCustomField> {
    const dialogRef = this._dialog.open(CustomFieldCreatorComponent, {
      width: '35%',
      data: data
    });

    return new Observable(observer => {
      const instance = dialogRef.componentInstance;
      instance.fieldCreated.subscribe((field: GeneretedCustomField) => {
        observer.next(field);
      });

      dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }

  onEditField(event: { field: Field, parent: string }): void {
    this.openCustom(event.field).subscribe((editedField: any) => {
      if (editedField) {
        let created = this.createCustomFieldCreatorData(event.parent, editedField)
        const edited = this._datasheetService.editCustomField(created);
        if(edited){
          if (event.parent === 'garment') {
            this.createGarmentCustomValuesForm(this.datasheet);
          } else if (event.parent === 'variant') {
            this.createVariantCustomValuesForm(this.selectedVariantIndex);
          }
          this._dialog.closeAll();
        }
      }
    });
  }

  onDeleteField(event: { name: string, parent: string }): void {
    let parentFields: Field[];
    let parentCategoryFields: CategoryField[];

    let parentFieldsPath = '';
    let parentCategoryFieldPath = '';
    let parentName = '';

    if (event.parent === 'garment') {
      parentFields = this.datasheet.garment.fields;
      parentFieldsPath = 'garment.fields';
      parentCategoryFields = this.datasheet.categories.garment.fields;
      parentCategoryFieldPath = 'categories.garment.fields';
    } else if (event.parent === 'variant') {
      parentFields = this.selectedVariant.fields;
      parentFieldsPath = `garment.variants.${this.selectedVariantIndex}.fields`;
      parentCategoryFields = this.datasheet.categories.variant.fields;
      parentCategoryFieldPath = `categories.variant.fields`;
      parentName = this.selectedVariant.fields[0].value! as string;
    } else {
      this._snack.warnMessage(this._translate.instant('propertyEditorPage.IsaCollectionError'));
      return;
    }

    const result = this._datasheetService.removeCustomField(
      parentFields,
      parentCategoryFields,
      event.name,
      parentFieldsPath,
      parentCategoryFieldPath,
      parentName
    );

    if (result) {
      if (event.parent === 'garment') {
        this.createGarmentCustomValuesForm(this.datasheet);
      } else if (event.parent === 'variant') {
        this.createVariantCustomValuesForm(this.selectedVariantIndex);
      }
    } else {
      this._snack.warnMessage(this._translate.instant('propertyEditorPage.notRemovedCustom'));
    }
  }
}
