export class Utils {

  static formatNumberOrText(input: string): string {

    if (typeof input !== 'string' || input === null || input === undefined) {

      return input;
    }

    if( input.indexOf(".") == -1 && input.indexOf(",") == -1 ) {
      return input;
    }

    const floatRegex = /^[+-]?(\d+([.,]\d*)?|[.,]\d+)$/;

    if (floatRegex.test(input)) {
      const standardized = input.replace(',', '.');
      const formatted = parseFloat(standardized).toFixed(2);

      return formatted.replace('.', ',');
    }

    return input;
  }

}
