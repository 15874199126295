import { Reference } from "./reference";
import { Category, CategoryField } from "./category";
import { Datasheet } from "./datasheet";
import { Garment } from "./garment";
import { Variant } from "./variant";
import { Image } from "./image";
import { DatasheetService } from "../services/datasheet.service";
import { Utils } from "./utils";

export class ReferenceTab {
  material: TableData;
  activity: TableData;
  generic: TableData;
  measure: TableData;
  pattern: TableData;
  pattern_group: TableData;
  marker: TableData;
  marker_group: TableData;
  group: TableData;
  fashion_studio: TableData;

  constructor() {
    this.material = new TableData("Materiais");
    this.activity = new TableData("Atividades");
    this.generic = new TableData("Referencias Genéricas");
    this.measure = new TableData("Medidas");
    this.pattern = new TableData("Moldes");
    this.pattern_group = new TableData("Grupo de Moldes");
    this.marker = new TableData("Encaixes");
    this.marker_group = new TableData("Grupo de Encaixes");
    this.group = new TableData("Grupos");
    this.fashion_studio = new TableData("Fashion Studio");
  }
}

export class ImageTab {
  visible:boolean;
  images: ImageData[];
  visibleIndex:number[];

  constructor() {
    this.images = [];
    this.visibleIndex = [];
    this.visible = true; //property to show/hidden all the table
  }

  protected toggleImageVisibility(){
    this.visible = !this.visible;
  }

  changeImageVisibility(){
    this.toggleImageVisibility();
  }
}

export class ImageData {
  origin: string;
  url: string;
  label: string;

  constructor(origin: string, url: string, label: string) {
    this.origin = origin;
    this.url = url;
    this.label = label;
  }
}

export class RowData {
  row: string[];

  constructor(row: string[]) {
    this.row = row;
  }
}

export class TableData {
  header: string[];
  headerNames: string[];
  headerIndex:number[];
  rows: RowData[];
  origin: string;
  title: string;
  variantNames: string;
  visible:boolean;

  constructor(title: string) {
    this.header = [];
    this.headerNames = [];
    this.headerIndex = []; //whitelist for table header/columns index, just values on this array will be rendered
    this.rows = [];
    this.origin = "";
    this.title = title;
    this.variantNames = "";
    this.visible = true; //property to show/hidden all the table
  }

  protected selectAll(fromArray: number[], namesArray: string[]|RowData[]) {
    this.selectNone(fromArray);
    for(let i = 0; i < namesArray.length; i++) {
      fromArray.push(i);
    }
  }

  protected selectNone(fromArray: number[]) {
    fromArray.splice(0, fromArray.length);
  }

  protected toggleTableVisibility(){
    this.visible = !this.visible
  }

  protected isAllSelected(fromArray: number[], namesArray: string[]|RowData[]){
    if (fromArray.length === namesArray.length) {
      return 'checked';
    } else if (fromArray.length > 0) {
      return 'indeterminate';
    } else {
      return 'unchecked';
    }
  }

  selectAllHeader() {
    this.selectAll(this.headerIndex, this.header);
  }

  selectNoneHeader() {
    this.selectNone(this.headerIndex);
  }

  changeTableVisibility(){
    this.toggleTableVisibility();
  }

  verifyAllchecked(){
    return this.isAllSelected(this.headerIndex, this.headerNames)
  }
}

export class VariantTableData extends TableData {
  variantIndex: number[];

  constructor(title: string) {
    super(title);
    this.variantIndex = [];
  }

  selectAllVariant() {
    this.selectAll(this.variantIndex, this.rows);
  }

  selectNoneVariant() {
    this.selectNone(this.variantIndex);
  }

  selectAllVariants() {
    this.selectAll(this.variantIndex, this.rows);
  }

  selectNoneVariants() {
    this.selectNone(this.variantIndex);
  }
  verifyAllVariantchecked(){
    return this.isAllSelected(this.variantIndex, this.rows)
  }
}

export class PrintData {
  logo: string;
  creation_date: string;
  model: TableData;
  modelSummary: TableData;
  modelDesc: TableData;
  modelObs: TableData;
  modelReferences: ReferenceTab;
  dataSheetInstance!: Datasheet

  variants: VariantTableData;
  variantReferences: ReferenceTab[];

  references: ReferenceTab;

  imagesModel: ImageTab;
  imagesReference: ImageTab;

  headerLogo?: string;
  footerLog?: string;

  constructor( dataSheet: Datasheet ) {
    this.dataSheetInstance = Object.assign(new Datasheet(), dataSheet)
    this.logo = "";
    this.creation_date = dataSheet.creation_date;
    this.model = new TableData("Modelo");
    this.modelSummary = new TableData("Modelo");
    this.modelDesc = new TableData("Descrição");
    this.modelObs = new TableData("Observação");
    this.modelReferences = new ReferenceTab();
    this.variants = new VariantTableData("Variantes");
    this.variants = new VariantTableData("Variantes");

    this.variantReferences = [];

    this.references = new ReferenceTab();
    this.imagesModel = new ImageTab();
    this.imagesReference = new ImageTab();

    for(let key in dataSheet.categories){
      switch (key) {
        case "activity":
          this.setHeaderList(
            [
              this.modelReferences.activity,
              this.references.activity
            ],
            dataSheet.categories.activity.fields
          );
          break;
        case "free":
          this.setHeaderList(
            [
              this.modelReferences.generic,
              this.references.generic
            ],
            dataSheet.categories.free.fields
          );
          break;
        case "material":
          this.setHeaderList(
            [
              this.modelReferences.material,
              this.references.material
            ],
            dataSheet.categories.material.fields
          );
          break;
        case "measures":
          this.setHeaderList(
            [
              this.modelReferences.measure,
              this.references.measure
            ],
            dataSheet.categories.measures.fields
          );
          break;
        case "pattern":
          this.setHeaderList(
            [
              this.modelReferences.pattern,
              this.references.pattern
            ],
            dataSheet.categories.pattern!.fields || []
          );
          break;
        case "pattern_group":
          this.setHeaderList(
            [
              this.modelReferences.pattern_group,
              this.references.pattern_group
            ],
            dataSheet.categories.pattern_group!.fields || []
          );
          break;
        case "marker":
          this.setHeaderList(
            [
              this.modelReferences.marker,
              this.references.marker
            ],
            dataSheet.categories.marker!.fields || []
          );
          break;
        case "marker_group":
          this.setHeaderList(
            [
              this.modelReferences.marker_group,
              this.references.marker_group
            ],
            dataSheet.categories.marker_group!.fields || []
          );
          break;
        case "group":
          this.setHeaderList(
            [
              this.modelReferences.group,
              this.references.group
            ],
            dataSheet.categories.group!.fields || []
          );
          break;
        case "fashion_studio":
          this.setHeaderList(
            [
              this.modelReferences.fashion_studio,
              this.references.fashion_studio
            ],
            dataSheet.categories.fashion_studio!.fields || []
          );
          break;
        case "garment":
          this.setHeaderSummaryList(this.model, dataSheet.categories.garment.fields, ["code","collection","resp", "desc", "obs", "amount","cost", "total", "mat_total", "act_total" ], true);
          this.setHeaderSummaryList(this.modelSummary, dataSheet.categories.garment.fields, ["code","collection","resp"]);
          this.setHeaderSummaryList(this.modelDesc, dataSheet.categories.garment.fields, ["desc"]);
          this.setHeaderSummaryList(this.modelObs, dataSheet.categories.garment.fields, ["obs"]);
          break;
        case "variant":
          this.setHeaderList(
            [this.variants],
            dataSheet.categories.variant.fields
          );
          for( let i = 0; i < dataSheet.garment.variants.length; i++) {
            this.setRowList( this.variants, [dataSheet.garment.variants[i]], dataSheet.categories.variant.fields || [] );
            this.variants.variantIndex.push(i);
          }

          break;

        default:
          break;
      }
    }

    this.setRowList( this.model, [dataSheet.garment], dataSheet.categories.garment.fields || [] );
    this.setRowList( this.modelSummary, [dataSheet.garment], dataSheet.categories.garment.fields || [] );
    this.setRowList( this.modelDesc, [dataSheet.garment], dataSheet.categories.garment.fields || [] );
    this.setRowList( this.modelObs, [dataSheet.garment], dataSheet.categories.garment.fields || [] );

    this.setRowList( this.modelReferences.material,       dataSheet.garment.materials,   dataSheet.categories.material.fields || [] );
    this.setRowList( this.modelReferences.activity,       dataSheet.garment.activities,  dataSheet.categories.activity.fields || [] );
    this.setRowList( this.modelReferences.measure,        dataSheet.garment.measures,    dataSheet.categories.measures.fields || [] );
    this.setRowList( this.modelReferences.generic,        dataSheet.garment.generics,    dataSheet.categories.free.fields || []);
    this.setRowList( this.modelReferences.fashion_studio, dataSheet.garment.fashion_studio!, dataSheet.categories.fashion_studio?.fields || [] );
    this.setRowList( this.modelReferences.group,          dataSheet.garment.reference_groups!, dataSheet.categories.group!.fields || [] );
    this.setRowList( this.modelReferences.pattern,        dataSheet.garment.patterns!, dataSheet.categories.pattern!.fields || [] );
    this.setRowList( this.modelReferences.pattern_group,  dataSheet.garment.pattern_groups!, dataSheet.categories.pattern_group!.fields || [] );
    this.setRowList( this.modelReferences.marker,         dataSheet.garment.markers!, dataSheet.categories.marker!.fields || [] );
    this.setRowList( this.modelReferences.marker_group,   dataSheet.garment.marker_groups!, dataSheet.categories.marker_group!.fields || [] );

    for( let i = 0; i < dataSheet.garment.variants.length; i++) {
      let variant = dataSheet.garment.variants[i];

      let refTab = new ReferenceTab();
      this.variantReferences.push( refTab );

      this.setHeaderList( [refTab.material],       dataSheet.categories.material.fields || []);
      this.setHeaderList( [refTab.activity],       dataSheet.categories.activity.fields || []);
      this.setHeaderList( [refTab.measure],        dataSheet.categories.measures.fields || []);
      this.setHeaderList( [refTab.generic],        dataSheet.categories.free.fields || []);
      this.setHeaderList( [refTab.fashion_studio], dataSheet.categories.fashion_studio?.fields || []);
      this.setHeaderList( [refTab.group],          dataSheet.categories.group!.fields || []);
      this.setHeaderList( [refTab.pattern],        dataSheet.categories.pattern!.fields || []);
      this.setHeaderList( [refTab.pattern_group],  dataSheet.categories.pattern_group!.fields || []);
      this.setHeaderList( [refTab.marker],         dataSheet.categories.marker!.fields || []);
      this.setHeaderList( [refTab.marker_group],   dataSheet.categories.marker_group!.fields || []);


      refTab.material.title      = " Variante " + variant.fields[0].value;
      refTab.activity.title      = " Variante " + variant.fields[0].value;
      refTab.measure.title       = " Variante " + variant.fields[0].value;
      refTab.generic.title       = " Variante " + variant.fields[0].value;
      refTab.fashion_studio.title            = " Variante " + variant.fields[0].value;
      refTab.pattern_group.title = " Variante " + variant.fields[0].value;
      refTab.marker_group.title  = " Variante " + variant.fields[0].value;

      refTab.material.variantNames      = String(variant.fields[0].value);
      refTab.activity.variantNames      = String(variant.fields[0].value);
      refTab.measure.variantNames       = String(variant.fields[0].value);
      refTab.generic.variantNames       = String(variant.fields[0].value);
      refTab.fashion_studio.variantNames            = String(variant.fields[0].value);
      refTab.pattern_group.variantNames = String(variant.fields[0].value);
      refTab.marker_group.variantNames  = String(variant.fields[0].value);


      this.setRowList( refTab.material,      variant.materials, dataSheet.categories.material.fields || []  );
      this.setRowList( refTab.activity,      variant.activities, dataSheet.categories.activity.fields || []  );
      this.setRowList( refTab.measure,       variant.measures, dataSheet.categories.measures.fields || []  );
      this.setRowList( refTab.generic,       variant.generics, dataSheet.categories.free.fields || []  );
      this.setRowList( refTab.fashion_studio,variant.fashion_studio!, dataSheet.categories.fashion_studio?.fields || [] );
      this.setRowList( refTab.pattern_group, variant.pattern_groups!, dataSheet.categories.pattern_group!.fields || [] );
      this.setRowList( refTab.marker_group,  variant.marker_groups!, dataSheet.categories.marker_group!.fields || [] );


      let subvars = variant["sub-variants"]! || [];
      for( let j = 0; j < subvars.length; j++) {
        let subVariant = subvars[j];

        refTab.material.variantNames += " " + String(subVariant.fields[0].value);

        this.setRowList( refTab.material,      subVariant.materials, dataSheet.categories.material.fields || []  );
        this.setRowList( refTab.activity,      subVariant.activities, dataSheet.categories.activity.fields || []  );
        this.setRowList( refTab.measure,       subVariant.measures, dataSheet.categories.measures.fields || []  );
        this.setRowList( refTab.generic,       subVariant.generics, dataSheet.categories.free.fields || [] );
        this.setRowList( refTab.fashion_studio,            subVariant.fashion_studio!, dataSheet.categories.fashion_studio?.fields || [] );
        this.setRowList( refTab.pattern_group, subVariant.pattern_groups!, dataSheet.categories.pattern_group!.fields || [] );
        this.setRowList( refTab.marker_group,  subVariant.marker_groups!, dataSheet.categories.marker_group!.fields || []);

        let subsubvars = subVariant["sub-variants"]! || [];
        for( let k = 0; k < subsubvars.length; k++) {
          let subSubVariant = subsubvars[k];

          refTab.material.variantNames += " " + String(subSubVariant.fields[0].value);

          this.setRowList( refTab.material,       subSubVariant.materials || [],  dataSheet.categories.material.fields || [] );
          this.setRowList( refTab.activity,       subSubVariant.activities || [], dataSheet.categories.activity.fields || []  );
          this.setRowList( refTab.measure,        subSubVariant.measures || [], dataSheet.categories.measures.fields || []  );
          this.setRowList( refTab.generic,        subSubVariant.generics || [], dataSheet.categories.free.fields || []  );
          this.setRowList( refTab.fashion_studio, subSubVariant.fashion_studio || [], dataSheet.categories.fashion_studio?.fields || [] );
          this.setRowList( refTab.pattern_group,  subSubVariant.pattern_groups || [], dataSheet.categories.pattern_group!.fields || [] );
          this.setRowList( refTab.marker_group,   subSubVariant.marker_groups || [], dataSheet.categories.marker_group!.fields || [] );
        }
      }
    }

    let i = 0;

    for( let img of dataSheet.garment.images!) {
      this.imagesModel.images.push(
        new ImageData("Modelo", img.url!, "Imagem do Modelo")
      );
      this.imagesModel.visibleIndex.push(i);
      i++;
      this.imagesModel.visibleIndex.push(i);
      i++;
    }

    this.setReferenceImages(dataSheet);
  }

  private setHeaderList( tables: TableData[], fields: CategoryField[] ) {
    for( let tab of tables) {
      for (let [index, field] of fields.entries()) {
        tab.header.push(field.label);
        tab.headerNames.push(field.name);
        if( !field.custom ) {
          tab.headerIndex.push(index);
        }
      }
    }
  }

  private setHeaderSummaryList( table: TableData, catFields: CategoryField[], filter: string[], isBlackList: boolean = false ) {

    let index = 0;

    for (const field of catFields) {
      const shouldIncludeField = isBlackList
        ? filter.indexOf(field.name) < 0
        : filter.indexOf(field.name) >= 0;

      if (shouldIncludeField) {
        table.header.push(field.label);
        table.headerNames.push(field.name);
        table.headerIndex.push(index);
        index++;
      }
    }
  }

  private setRowList(
    tab: TableData,
    garRef: Garment[] | Variant[] | Reference[],
    catFields: CategoryField[]
  ) {
    if (!garRef) return;

    for (let gR of garRef) {
      let values_str: string[] = [];

      for (let name of tab.headerNames) {
        let value = "";
        let symbol = "";

        for (let field of gR.fields) {
          if (field.name === name) {
            const category = this.dataSheetInstance.getCategoryField(field.name, catFields);

            if (category.type == 'DateTime' && field.value) {
              value = this.parseTimestampToDate(Number(field.value));
            }

            if (category.properties.measure) {
              symbol = this.dataSheetInstance.getMeasure(category.properties.measure);
            }

            if (category.type == "Currency") {
              symbol = this.dataSheetInstance.getCurrencySymbol();
            }

            if (!value) {
              value = field.color ? String(field.color?.name || field.color?.value || "") : Utils.formatNumberOrText(field.value as string);
            }
          }
        }

        let finalValue = symbol ? `${value} ${symbol}` : value;
        values_str.push(finalValue);
      }

      tab.rows.push(new RowData(values_str));
    }
  }

  private parseTimestampToDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();

  }


  private _getImages( origin: string, refs: Reference[] ) {
    let images:ImageData[] = [];

    if(!refs)
      return images;''

    for( let r of refs ) {
      if( r.images ) {
        let label = String(r.fields[0].value!);
        for( let img of r.images) {
          images.push(
            new ImageData(origin, img.url!, label)
          );
        }
      }
    }
    return images;
  }


  private setReferenceImages( dataSheet: Datasheet) {

    this.imagesReference.images.push( ...this._getImages("Materiais do modelo", dataSheet.garment.materials) );
    this.imagesReference.images.push( ...this._getImages("Atividades do modelo", dataSheet.garment.activities) );
    this.imagesReference.images.push( ...this._getImages("Referencias Genericas do modelo", dataSheet.garment.generics) );


    for( let variant of dataSheet.garment.variants ) {
      let variantName = variant.fields[0].value;

      this.imagesReference.images.push( ...this._getImages("Materiais de "+variantName, variant.materials) );
      this.imagesReference.images.push( ...this._getImages("Atividades de "+variantName, variant.activities) );
      this.imagesReference.images.push( ...this._getImages("Referencias Genericas de "+variantName, variant.generics) );

      let subvars = variant["sub-variants"]! || [];
      for( let subVariant of subvars) {
        let subVariantName = variantName + " - " + subVariant.fields[0].value;

        this.imagesReference.images.push( ...this._getImages("Materiais de "+subVariantName, subVariant.materials) );
        this.imagesReference.images.push( ...this._getImages("Atividades de "+subVariantName, subVariant.activities) );
        this.imagesReference.images.push( ...this._getImages("Referencias Genericas de "+subVariantName, subVariant.generics) );

        let subsubvars = subVariant["sub-variants"]! || [];
        for( let subSubVariant of subsubvars) {
          let subSubVariantName = subVariantName + " - " + subSubVariant.fields[0].value;

          this.imagesReference.images.push( ...this._getImages("Materiais de "+subSubVariantName, subSubVariant.materials) );
          this.imagesReference.images.push( ...this._getImages("Atividades de "+subSubVariantName, subSubVariant.activities) );
          this.imagesReference.images.push( ...this._getImages("Referencias Genericas de "+subSubVariantName, subSubVariant.generics) );
        }
      }
    }
    for(let i = 0; i < this.imagesReference.images.length; i++) {
      this.imagesReference.visibleIndex.push(i);
    }
  }

    public toggleIndexSelection(fromArray: number[], index: number) {

    let myIndex = fromArray.indexOf(index);
    if( myIndex === -1 ) {
      fromArray.push(index);
      fromArray = fromArray.sort((n1,n2) => n1 - n2);
    } else {
      fromArray.splice(myIndex, 1);
    }
  }

  public ToggleMaterialIndex(index: number) {
    this.toggleIndexSelection(this.modelReferences.material.headerIndex, index);

    for( let varit of this.variantReferences ) {
      this.toggleIndexSelection(varit.material.headerIndex, index);
    }
  }

  public ToggleVariantIndex(index:number){
    this.toggleIndexSelection(this.variants.variantIndex, index)
  }

  public ToggleMeasuresIndex( index: number) {
    this.toggleIndexSelection(this.modelReferences.measure.headerIndex, index);

    for( let varit of this.variantReferences ) {
      this.toggleIndexSelection(varit.measure.headerIndex, index);
    }
  }

  public ToggleActivitiesIndex( index: number) {
    this.toggleIndexSelection(this.modelReferences.activity.headerIndex, index);

    for( let varit of this.variantReferences ) {
      this.toggleIndexSelection(varit.activity.headerIndex, index);
    }
  }

  public ToggleGenericIndex( index: number) {
    this.toggleIndexSelection(this.modelReferences.generic.headerIndex, index);

    for( let varit of this.variantReferences ) {
      this.toggleIndexSelection(varit.generic.headerIndex, index);
    }
  }

  public ToggleVariantHeadersIndex( index: number) {
    this.toggleIndexSelection(this.variants.headerIndex, index);
  }

  public ToggleModelIndex( index: number) {
    this.toggleIndexSelection(this.model.headerIndex, index);
  }

  public ToggleModelImages(index: number){
    this.toggleIndexSelection(this.imagesModel.visibleIndex, index)
  }

  public ToggleReferencesImages(index: number){
    this.toggleIndexSelection(this.imagesReference.visibleIndex, index)
  }

  public hiddenAllModel(){
    this.model.selectNoneHeader();
  }

  public hiddenAllVariant(){
    this.variants.selectNoneVariants()
  }

  public hiddenAllVariantInfo(){
    this.variants.selectNoneHeader()
  }

  public hideAllMaterials(){
    this.modelReferences.material.selectNoneHeader();

    for(let variant of this.variantReferences){
      variant.material.selectNoneHeader();
    }
  }

  public hideAllActivity(){
    this.modelReferences.activity.selectNoneHeader();

    for(let variant of this.variantReferences){
      variant.activity.selectNoneHeader();
    }
  }

  public hideAllMeasures(){
    this.modelReferences.measure.selectNoneHeader();

    for(let variant of this.variantReferences){
      variant.measure.selectNoneHeader();
    }
  }

  public hideAllGeneric(){
    this.modelReferences.generic.selectNoneHeader();

    for(let variant of this.variantReferences){
      variant.generic.selectNoneHeader();
    }
  }

  public showAllModel(){
    this.model.selectAllHeader();
  }

  public showAllVariant(){
    this.variants.selectAllVariants();
  }

  public showAllVariantInfo(){
    this.variants.selectAllHeader();
  }

  public showAllMaterial(){
    this.modelReferences.material.selectAllHeader();

    for(let variant of this.variantReferences){
      variant.material.selectAllHeader();
    }
  }

  public showAllActivity(){
    this.modelReferences.activity.selectAllHeader();

    for(let variant of this.variantReferences){
      variant.activity.selectAllHeader();
    }
  }

  public showAllMeasures(){
    this.modelReferences.measure.selectAllHeader();

    for(let variant of this.variantReferences){
      variant.measure.selectAllHeader();
    }
  }

  public showAllGeneric(){
    this.modelReferences.generic.selectAllHeader();

    for(let variant of this.variantReferences){
      variant.generic.selectAllHeader();
    }
  }

  public toggleModelVisibility(){
    this.model.changeTableVisibility();
  }

  public toggleVariantVisibility(){
    this.variants.changeTableVisibility();
  }

  public toggleVariantInfoVisibility(){
    this.variants.changeTableVisibility();
  }

  public toggleMaterialVisibility(){
    this.modelReferences.material.changeTableVisibility();
    for(let variant of this.variantReferences){
      variant.material.changeTableVisibility();
    }
  }

  public toggleActivityVisibility(){
    this.modelReferences.activity.changeTableVisibility();
    for(let variant of this.variantReferences){
      variant.activity.changeTableVisibility();
    }
  }

  public toggleMeuasureVisibility(){
    this.modelReferences.measure.changeTableVisibility();
    for(let variant of this.variantReferences){
      variant.measure.changeTableVisibility();
    }
  }

  public toggleGenericVisibility(){
    this.modelReferences.generic.changeTableVisibility();;
    for(let variant of this.variantReferences){
      variant.generic.changeTableVisibility();
    }
  }

  public toggleModelImageVisibility(){
    this.imagesModel.changeImageVisibility()
  }
  public toggleReferenceImageVisibility(){
    this.imagesReference.changeImageVisibility();
  }

  public verifyAllModelInfochecked(){
    return this.model.verifyAllchecked()
  }

  public verifyAllVariantschecked(){
    return this.variants.verifyAllVariantchecked()
  }

  public verifyAllVariantInfoschecked(){
    return this.variants.verifyAllchecked()
  }

  public verifyAllMaterialchecked(){
    return this.modelReferences.material.verifyAllchecked();
  }

  public verifyAllActivitiesChecked(){
    return this.modelReferences.activity.verifyAllchecked();
  }

  public verifyAllMeasuresChecked(){
  return this.modelReferences.measure.verifyAllchecked();
  }

  public verifyAllGenericChecked(){
    return this.modelReferences.generic.verifyAllchecked();
  }

  public setLogo(logo : string){
    this.logo = logo;
  }

  public removeLogo(){
    this.logo = "";
  }
}
