<section class="stepper-container">

  <div class="stepper-container__header_locked" *ngIf="lockedParam">
    <div class="locked-container">
      <div class="locked-text-content">
        <mat-icon style="line-height: 28px;">warning</mat-icon> {{ 'lock.by' | translate }} <b>{{ lockedParam.lockedBy }}</b> {{ 'lock.at' | translate }} <b>{{lockedParam.lockedAt | date: 'dd/MM/yyyy'}}</b>
        <p *ngIf="lockedParam.lockedSystem == 'ISA'">{{ 'lock.isaMessage' | translate }}</p>
      </div>
      <button-misc class="locked-button" [isLoading]="fetchingEditMode" (click)="onEnableEtition(true)" style="float: right;">
        <mat-icon *ngIf="!fetchingEditMode">refresh</mat-icon>
      </button-misc>
    </div>
  </div>


  <div class="stepper-container__header_locked" *ngIf="!lockedParam && !stepper.editionMode" style="background-color: antiquewhite;">
    <button-misc [isLoading]="fetchingEditMode" (click)="onEnableEtition()" style="float: right;">
      <mat-icon *ngIf="!fetchingEditMode">edit</mat-icon>
      {{ 'lock.enableEdtion' | translate }}
    </button-misc>
  </div>

  <div class="stepper-container__header">
    <div class="stepper-container__title">

      <span class="thumb">
        <ng-container *ngIf="isLoadingIsa; else content">
          <div class="spinner"></div>
        </ng-container>
        <ng-template #content>
          <img [src]="thumb! | appRemoveS3Link" *ngIf="thumb!=''">
          <mat-icon title="Vincular a Isa" *ngIf="!hasIsaModelId" (click)="onLinkIsaClick()" style="cursor:pointer">add_link</mat-icon>
        </ng-template>
      </span>

      <div class="kanbam">
        {{ 'models.step' | translate }}: {{ kanbam }}
      </div>
    </div>
    <div class="stepper-container__buttons">
      <button-misc *ngIf="shouldShowButton === true && stepper.editionMode"
        (click)="showIdeaxDialog()"
        class="stepper-container__buttons_btn"
        matTooltip="{{ 'updateIdeaxModal.btnMsg' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="custom-hint">
        <mat-icon style="color: #FFC300;" class="stepper-container__icon">
          warning_amber
          </mat-icon>
      </button-misc>
      <button-misc  class="stepper-container__buttons_btn" (click)="navigateToAdvancedEditor()">
        {{ 'shared.actions.advancedEditor.label' | translate }}
      </button-misc>

      <button-misc class="erp_button" [isLoading]="fetchingErpDiffs" [disabled]="fetchingErpDiffs || !stepper.editionMode"
        (click)="erpDiffs.length > 0 ? openModal() : fetchErpRefsUpdate()"
        [badge]="erpDiffs.length > 0 ? erpDiffs.length : undefined">
        <mat-icon *ngIf="!fetchingErpDiffs" style="color: #E5B100;" matPrefix>refresh</mat-icon>
        {{ fetchingErpDiffs ? ("buttonUpdateRef.loading" | translate) : (erpDiffs.length === 0 ?( "buttonUpdateRef.noRefs" | translate) : ("buttonUpdateRef.hasRefs" | translate)) }}
      </button-misc>

      <button-misc (click)="historyToggler()" matTooltip="{{ 'shared.actions.history.label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="custom-hint"
        [matTooltipDisabled]="isDrawerOpened">
        <mat-icon class="stepper-container__icon">history</mat-icon>
      </button-misc>
      <button-misc class="stepper-container__buttons_btn" (click)="navigateToPrint()"
        matTooltip="{{ 'shared.actions.print.label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="custom-hint">
        <mat-icon class="stepper-container__icon">print</mat-icon>
      </button-misc>
      <button-misc *ngIf="showImportIdea" class="stepper-container__buttons_btn" (click)="importIdeax()"
        matTooltip="{{ 'shared.actions.ideax.import' | translate }}"
        matTooltipPosition="below"
        width="42px"
        matTooltipClass="custom-hint">
        <mat-icon class="stepper-container__icon">
          <img src="../../../assets/images/home/idea.png" width="24px" height="24px">
        </mat-icon>
      </button-misc>
    </div>
  </div>

  <div class="mobile_img">
    <app-editor-image-container></app-editor-image-container>
  </div>
  <app-datasheet-stepper #stepper></app-datasheet-stepper>
</section>

<mat-sidenav fixedInViewport="true" #historyDrawer mode="over" position="end" opened="false"  >
  <app-history-drawer
  [isDrawerOpened]="isDrawerOpened"
  (close_drawer)="closeDrawer()"
  ></app-history-drawer>
</mat-sidenav>

