import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const propEditorGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const debugMode = route.queryParamMap.get('debug') === 'true';

  if (debugMode) {
    return true;
  }

  const modelId = route.queryParamMap.get('model_id');
  if (!modelId) {
    router.navigate(['']);
    return false;
  }

  return true;
};
