<div class="home_container">
  <div class="gridContainer">
    <div class="onboardingContainer">
      <app-welcome-banner [user]="user"></app-welcome-banner>
    </div>
    <div class="configCardsContainer">
      <div class="configCardsContainer-inner">

        <div style="display: none;" class="config_card" (click)="newDatasheet()">
          <div class="config_card__icon" style="color: #f24495; font-size: 30px;">
            <img src="../../../assets/images/home/ficha-modelos.svg">
          </div>
          <div class="config_card__text">
            <p class="config_card__text__title">
              {{ 'homePage.cards.new.title' | translate }}
            </p>
            <p class="config_card__text__subtitle">
              {{ 'homePage.cards.new.subtitle' | translate }}
            </p>
          </div>
        </div>

        <div style="display: none;"class="config_card" (click)="newIdeaxDatasheet()">
          <div class="config_card__icon" style="color: #f24495; font-size: 30px;">
            <img src="../../../assets/images/home/idea.png">
          </div>
          <div class="config_card__text">
            <p class="config_card__text__title">
              {{ 'homePage.cards.newIdeax.title' | translate }}
            </p>
            <p class="config_card__text__subtitle">
              {{ 'homePage.cards.newIdeax.subtitle' | translate }}
            </p>
          </div>
        </div>

        <div class="config_card" (click)="showErpDialog()">
          <div class="config_card__icon">
            <img src="../../../assets/images/home/erp_materiais.svg">
          </div>
          <div class="config_card__text">
            <p class="config_card__text__title">
              {{ 'homePage.cards.erp.title' | translate }}
            </p>
            <p class="config_card__text__subtitle">
              {{ 'homePage.cards.erp.subtitle' | translate }}
            </p>
          </div>
        </div>


      </div>
    </div>
    <div class="filter_toolbar">
      <div class="filter_toolbar-inner">
        <div class="filter_toolbar-inner__title">
          <span class="filter_toolbar-inner__title__text">
            {{ 'homePage.recentlyEdited.label' | translate }}
          </span>
          <a class="button_dropdown_view-mode" [matMenuTriggerFor]="optionsMenu">
            <mat-icon *ngIf="viewMode === 'list'" class="icon">view_list</mat-icon>
            <mat-icon *ngIf="viewMode === 'grid'" class="icon">view_module</mat-icon>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </a>
        </div>
        <div class="filter_toolbar-inner__filters">

            <ng-container *ngIf="!isLoading && dsSummary">
              <div class="search-input-cont">
                <mat-form-field class="search-input" appearance="outline">
                  <mat-label>
                    {{ 'shared.inputs.search.label'  | translate  }} {{ getSelectedValue() }}
                  </mat-label>
                  <input matInput [(ngModel)]="searchTerm" (keyup.enter)="onSearchTermChange()"/>
                  <mat-icon matPrefix>search</mat-icon>
                  <button mat-icon-button matSuffix disableRipple [matMenuTriggerFor]="filter_menu">
                    <span class="material-icons-outlined">
                      filter_alt
                      </span>
                  </button>
                </mat-form-field>
                <div class="search-input-cont-orderBtn">
                  <button-misc [height]="42" style="width: 20px;" matSuffix [matMenuTriggerFor]="order_menu">
                    <mat-icon style="font-size: 28px!important;" >swap_vert</mat-icon>
                  </button-misc>
                </div>
              </div>
            </ng-container>

          <div>
            <mat-button-toggle-group class="button_togler_container" [(ngModel)]="viewMode">
              <mat-button-toggle class="list_togle_btn" value="list">
                <mat-icon class="icon">view_list</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle [disabled]="isLoading" class="grid_togle_btn" value="grid">
                <mat-icon class="icon">view_module</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
    <mat-menu class="option_menu-view" #optionsMenu="matMenu">
        <button [ngClass]="{'active-menu' : viewMode === 'list'}" mat-menu-item (click)="viewMode = 'list'">
          <mat-icon class="icon">view_list</mat-icon>
          {{'homePage.toogleView.list' | translate}}
        </button>
        <button [ngClass]="{'active-menu' : viewMode === 'grid'}" mat-menu-item (click)="viewMode = 'grid'">
          <mat-icon class="icon">view_module</mat-icon>
          {{'homePage.toogleView.grid' | translate}}
        </button>
    </mat-menu>
    <ng-container *ngIf="errorLoadingData; else noApiError">
      <app-mensage
        [isProcessing]="isProcessing"
        [messageType]="messageType.httpError"
        (retryClicked)="initialOrReetryGetHomeData()"
      ></app-mensage>
    </ng-container>
    <ng-template #noApiError>
      <div *ngIf="isLoading" class="card-container">
        <app-skeleton-loader [numberOfLines]="3" height="90px"></app-skeleton-loader>
      </div>
      <ng-container *ngIf="dsSummary as datasheets">
        <ng-container *ngIf="!isLoading && datasheets.length === 0">
          <app-mensage [messageType]="MessageType.noDatasheetFound"></app-mensage>
        </ng-container>
        <div class="card-container">
          <div *ngIf="viewMode === 'list'" class="cards_list-view">
            <div class="card-item"
              *ngFor="let card of datasheets | paginate :{ itemsPerPage: 10, currentPage: actualPage, totalItems: totalItems } , let i = index">
              <mat-expansion-panel class="panel" [disabled]="true" #mep="matExpansionPanel">
                <mat-expansion-panel-header  class="panel__header" (click)="navigate(card.fw.datasheet_uid)">
                  <div *ngIf="isLoadingImg" class="panel__header_image">
                    <app-skeleton-loader [numberOfLines]="1" height="80px"></app-skeleton-loader>
                  </div>
                  <img [style.display]="isLoadingImg ? 'none' : 'block'" class="panel__header_image"
                    (load)="hideImageLoader()" src="{{ card.image | appRemoveS3Link }}" (error)="onImageError($event)" alt="Image">
                  <div class="panel__header-content">
                    <span class="panel__header-content_title">{{ card.garmentName }}</span>
                    <div class="panel__header-content_cont">
                      <span class="panel__header-content_cont_last-modify">
                        {{ 'homePage.recentlyEdited.garmentInfo.lastUpdated' | translate }}
                        <b>{{ card.last_modification_date | date:'dd/MM/yyyy, HH:mm' }}</b>
                        {{ 'homePage.recentlyEdited.garmentInfo.updatedBy' | translate }}
                        <b>{{ card.last_modification_user }}</b>
                      </span>
                    </div>
                    <button  class="panel__header-content_btn " #t="matMenuTrigger" [matMenuTriggerFor]="menu"
                      (click)="$event.stopPropagation()"
                      [matMenuTriggerData]="{ uid: card.fw.datasheet_uid, isa_id: card.fw.isa_model_id }"
                      style="border:none;background-color:transparent;padding:0;height:40px;width:40px;"
                      [ngClass]="{'active': t.menuOpen ==  true}">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <button (click)="$event.stopPropagation()" (click)="mep.expanded = !mep.expanded" class="panel__header-content_btn " [class.rotate-180]="mep.expanded"
                      [ngClass]="{'rotate-180' : mep.expanded, 'active' : mep.expanded}"
                      style="border:none;background-color:transparent;padding:0;height:40px;width:40px; margin-right: 5px;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                  </div>
                </mat-expansion-panel-header>
                <mat-divider class="divider"></mat-divider>
                <div class="summary_container">
                  <div class="summary_container__header">
                    <div class="summary_container__header_1">
                      <span style="margin-right: 15px;"><b>
                        {{ 'propertyEditorPage.datasheetStepperComponent.forms.garment.inputs.description.label' | translate }}
                      </b> </span>
                      <span> {{ card.garmentDesc }} </span>
                      <p></p>
                      <span style="margin-right: 15px;"><b>
                        {{ 'shared.inputs.total.label' | translate }}
                      </b> </span>
                      <span> {{ card.garmentTotal.replace(',', '.') | number:'1.2-2' }} </span>
                    </div>
                    <div class="summary_container__header_2">
                      <span style="margin-right: 15px;"><b>
                        {{ 'models.variants' | translate }}
                      </b></span>
                      <span>
                        <ng-container *ngFor="let variant of card.allVariantNames, let last = last">
                          <span>
                            {{ variant }}
                            <span *ngIf="!last">,  </span>
                          </span>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="paginator">
              <pagination-controls   (pageChange)="onPageChange($event)" nextLabel="" previousLabel=""></pagination-controls>
            </div>
          </div>
          <div *ngIf="viewMode !== 'list'" class="cards_grid-view">
            <div class="card-item"
              *ngFor="let card of datasheets  | appFilter :searchTerm: 'card.garmentName', let i = index">
              <mat-card (click)="navigate(card.fw.datasheet_uid)">
                <mat-card-header>
                  <mat-card-title>
                    <span class="card_title">{{ card.garmentName }} </span>
                  </mat-card-title>
                  <button  class="card_btn " #t="matMenuTrigger" [matMenuTriggerFor]="menu"
                  (click)="$event.stopPropagation()"
                  [ngClass]="{'active': t.menuOpen ==  true}"
                  [matMenuTriggerData]="{ uid: card.fw.datasheet_uid, isa_id: card.fw.isa_model_id }"
                  style="border:none;background-color:transparent;padding:0;height:40px;width:40px;">
                  <mat-icon>more_vert</mat-icon>
                </button>
                </mat-card-header>
                <mat-card-subtitle>
                </mat-card-subtitle>
                <div *ngIf="isLoadingImg">
                  <app-skeleton-loader [numberOfLines]="1" height="100%"></app-skeleton-loader>
                </div>
                <img mat-card-image [ngStyle]="{ 'display': isLoadingImg ? 'none' : 'block' }"
                  (load)="hideImageLoader()" src="{{ card.image }}" (error)="onImageError($event)" alt="Image">
                <mat-card-content>
                  <span class="last-modify">
                    {{ 'homePage.recentlyEdited.garmentInfo.lastUpdated' | translate }}
                    <b> {{ card.last_modification_date | date:'dd/MM/yyyy, HH:mm' }} </b>
                    {{ 'homePage.recentlyEdited.garmentInfo.updatedBy' | translate }}
                    <b> {{ card.last_modification_user }}</b>
                  </span>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <mat-menu style="display: flex; flex-direction: column;" #filter_menu="matMenu">
      <mat-radio-group style="display: flex; flex-direction: column;" [(ngModel)]="selectedFilter">
        <mat-radio-button style="padding-right: 20px;" *ngFor="let option of filter_options" [value]="option.value"
        [checked]="selectedFilter === option.value" (click)="onFilterRadioClick(option.value, 'selectedFilter', $event)">
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-menu>
    <mat-menu style="display: flex; flex-direction: column;"  #order_menu="matMenu">
      <mat-radio-group style="display: flex; flex-direction: column;" [(ngModel)]="selectedOrder">
        <mat-radio-button style="padding-right: 20px;" *ngFor="let option of order_options " [value]="option.value"
        [checked]="selectedOrder === option.value" (click)="onOrderRadioClick(option.value, 'selectedOrder', $event)">
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-divider></mat-divider>
      <mat-radio-group style="display: flex; flex-direction: column;" [(ngModel)]="selectedOrder2">
        <mat-radio-button style="padding-right: 20px;" *ngFor="let option of order_options2" [value]="option.value"
        [checked]="selectedOrder2 === option.value" (click)="onOrderRadioClick(option.value, 'selectedOrder2', $event)">
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-menu>


    <mat-menu #order_menu="matMenu">
    </mat-menu>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-id="uid" let-isa_id="isa_id">
        <button class="menuItem" mat-menu-item [routerLink]="['/advanced-editor', id]" routerLinkActive="active"
          (click)="$event.stopPropagation()">
          {{ 'shared.actions.advancedEditor.label' | translate }}
        </button>
        <button class="menuItem"  mat-menu-item [routerLink]="['/print', id ]" routerLinkActive="active">
          {{ 'shared.actions.print.label' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button matTooltip="Permite visualizar e editar o modelo na Isa" style="color: #324fbe;" mat-menu-item (click)="goToIsa($event, isa_id)" target="_blank">
          {{ 'shared.actions.editIsa.label' | translate }}
        </button>
      </ng-template>
    </mat-menu>
  </div>
</div>
