import { switchMap, of, Subject, takeUntil, Subscription, take, finalize, tap, catchError } from 'rxjs';
import { DatasheetService } from './../../services/datasheet.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { ColumnFilteringService } from 'src/app/services/column-filtering.service';
import { ReferencesHandlerDialogComponent } from 'src/app/components/references-handler-dialog/references-handler-dialog.component';
import { ReferencesService } from 'src/app/services/references.service';
import { FunctionType } from 'src/app/models/enums/function-type';
import { ReferenceType } from 'src/app/models/enums/reference-type';
import { dataResolutionServiceService } from 'src/app/services/dataResolutionService.service';
import { MessageType } from 'src/app/models/enums/message-type';
import { TranslateService } from '@ngx-translate/core';
import { AddReferenceDialogComponent } from 'src/app/components/add-reference-dialog/add-reference-dialog.component';
import { ErpReferencesService } from 'src/app/services/erp-references.service';
import { ErpDiff } from 'src/app/models/datasheet';
import { ErpUpdateRefComponent } from 'src/app/components/erp-update-ref/erp-update-ref.component';
import { LockParam } from 'src/app/models/lockParam';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { SnackbarService } from 'src/app/components/snack-bar/snack-bar-service';
import { LoginService } from 'src/app/services/login.service';
import { AddFwReferenceComponent } from 'src/app/components/add-fw-reference/add-fw-reference.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-advanced-editor',
  templateUrl: './advanced-editor.component.html',
  styleUrls: ['./advanced-editor.component.scss'],
})
export class AdvancedEditorComponent implements OnInit, OnDestroy {
  public searchTerm: string = '';
  public referenceType: ReferenceType = ReferenceType.Material;
  public isExpanded: boolean = false;
  MessageType = MessageType;
  isProcessing: boolean = false;
  hasError:boolean = false;
  public fetchingErpDiffs: boolean = false;

  public itens = [
    { name: 'models.reference.materials', type: ReferenceType.Material, active: true },
    { name: 'models.reference.activities', type: ReferenceType.Activity, active: false },
    { name: 'models.reference.measurements', type: ReferenceType.Measures, active: false },
    { name: 'models.reference.generics', type: ReferenceType.Free, active: false },
    // { name: 'models.reference.markerGroup', type: ReferenceType.Marker, active: false },
    // { name: 'models.reference.patternGroup', type: ReferenceType.Pattern, active: false },
    // { name: 'models.reference.fashion_studio', type: ReferenceType.FashionStd, active: false }
  ];

  private destroy$ = new Subject<boolean>();
  public isLoading: boolean = false;
  public filterOpened: boolean = false;
  public columnNames: any = [];
  public filteredColumns: number = 0;
  public referencesSelected: number = 0;
  private subcptRoute?: Subscription;
  public uid: string = '';
  public erpDiffs: ErpDiff[] = [];
  
  public lockedParam?: LockParam | null;
  public fetchingEditMode: boolean = false;
  public editionMode: boolean = false;

  constructor(
    private _datasheetService: DatasheetService,
    private route: ActivatedRoute,
    private _selectReferencesService: ReferencesService,
    private _dialog: DialogService,
    private _columnFiltering: ColumnFilteringService,
    private _referencesServices: ReferencesService,
    private _route: ActivatedRoute,
    private dataResolutionService: dataResolutionServiceService,
    private translateService: TranslateService,
    private _erpReferencesService: ErpReferencesService,
    public _loginService: LoginService,
    private _dataStorage: DataStorageService,
    private _snack: SnackbarService,
    private _translateService: TranslateService,
    private _titleService: Title,
  ) {
    this.initSideMenuItems();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.subcptRoute = this._route.params.subscribe((params: Params) => {
      if (params['uid'] !== undefined) {
        this.uid = params['uid'];
      }
    });
    this.resolve();

    this._erpReferencesService.getErpDiffs()
    .pipe(takeUntil(this.destroy$))
    .subscribe(x => this.erpDiffs = x)
  }

  private resolve() {
    this.dataResolutionService.resolveData(this.uid!).subscribe({
      next: (resolvedData) => {
        this.handleHttpSucess(resolvedData);
        this.fetchErpRefsUpdate();
        
        this.lockedParam = this._datasheetService.isLocked( this._loginService.getCurrentUserMail() );
        
        if( this._datasheetService.getDatasheet().isStandAlone() ) {
          this.editionMode = true;
        }

        const modelName = this._datasheetService.getDatasheet().garment.fields[0].value;
        this._titleService.setTitle(`ISA360 - ${modelName}`);
      },
      error: (error) => {
        console.debug('Erro ao resolver dados:', error);
        this.handleHttpError();
      }
    });

    this._selectReferencesService
      .getSelectedReferences()
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedReferences) => {
        this.referencesSelected = selectedReferences.length;
      });

    this._columnFiltering.columnFilteringChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((changed) => {
        if (changed) {
          this.filteredColumns = 0;
          this.columnNames = this._columnFiltering.columns.map((cn) => {
            return { name: cn, checked: true };
          });
        }
      });

      this._datasheetService.dataSheetChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(dt => {
        this.updateItemType(this.referenceType);
        this._selectReferencesService.notifyChanges(true);
        this.lockedParam = this._datasheetService.isLocked( this._loginService.getCurrentUserMail() );
      });
  }


  onRetryButtonClick(): void {
    this.isLoading = true;
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subcptRoute?.unsubscribe();
    this.unlockOnUnload();
  }

  public filterColumns(value: any, event?: Event) {
    event?.stopPropagation();
    value.checked = !value.checked;

    const filteredColumnNames = this.columnNames
      .filter((cn: any) => cn.checked)
      .map((cn: any) => cn.name);
    this.filteredColumns = this.columnNames.length - filteredColumnNames.length;
    this._columnFiltering.setColumnNames(filteredColumnNames);
  }

  public removeReferences() {
    this.isLoading = true;
    this._referencesServices
      .removeReferences()
      .pipe(take(1))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public openDialog(title: string, functionType: FunctionType) {
    console.log(functionType);
    this._dialog
      .displayDialog(
        ReferencesHandlerDialogComponent,
        { title: title, function: functionType },
        '550'
      )
      .pipe(take(1))
      .subscribe(() => {
        // it happens when dialog is closed
        this._referencesServices
          .verifyChanges()
          .pipe(take(1))
          .subscribe((changed) => {
            if (changed) {
              this.referencesSelected = 0;

              // when hasChanges is triggered, its value is set to true and never is changed.
              this._referencesServices.notifyChanges(false);
            }
          });
      });
  }

  public unselect(): void {
    this._referencesServices.setSelectedReferences([]);
  }

  updateItemType(referenceType: any) {
    this.itens.forEach((item) => (item.active = item.type == referenceType));
    this._referencesServices.setReferenceType(referenceType);
    this._referencesServices.setSelectedReferences([]);
  }

  expandField() {
    this.isExpanded = true;
  }

  collapseField() {
    if (!this.searchTerm) {
      this.isExpanded = false;
    }
  }

  onInput() {
    if (this.searchTerm) {
      this.isExpanded = true;
    }
  }

  public retryFunction(){
    this.isProcessing = true;
    this.resolve();
  }

  private handleHttpError() {
    this.isLoading = false;
    this.hasError = true;
    this.isProcessing = false
  }

  private handleHttpSucess(data: any){
    this.isLoading = false;
    this.hasError = false;
    this.isProcessing = false;
  }

  private initSideMenuItems(): void {
    this.translateService.get('advancedEditorPage.sideMenu.items')
      .subscribe((obj: any) => {
        const keys = Object.getOwnPropertyNames(obj);
        this.itens.forEach(element => {
          keys.map((key) => {
            if(element.type == key) {
              element.name = obj[key];
            }
          })
        });
      });
  }

  public openModalAddReference(){
    const dialogRef = this._dialog.displayDialog(AddReferenceDialogComponent, {variantIndexes:[]}, '50%', '90%', 'dialog-responsive');
    dialogRef.pipe(take(1)).subscribe(result => {
      if (result) {
        this.editionMode = this._datasheetService.isDatasheetStandalone();
      }
    });
  }
  
  public openModalErpUpdateRef(){
    this._dialog.displayDialog(ErpUpdateRefComponent, {data: this.erpDiffs }, '900px', '', );
  };

  fetchErpRefsUpdate(){
    this.fetchingErpDiffs = true;
    this._erpReferencesService.verifyErpReferences()
    .pipe(takeUntil(this.destroy$), finalize(() => this.fetchingErpDiffs = false))
    .subscribe(x => {
      this.erpDiffs = x
      this.fetchingErpDiffs = false;
    });
  }

  public openModalAddFwReference(){
    const dialogRef = this._dialog.displayDialog(AddFwReferenceComponent, {variantIndexes:[]}, '50%', '90%', 'dialog-responsive');
    dialogRef.pipe(take(1)).subscribe(result => {
      if (result) {
        this.editionMode = this._datasheetService.isDatasheetStandalone();
      }
    });
  }

  get buttonUpdateRefKey(): string {
    if (this.fetchingErpDiffs) {
      return 'buttonUpdateRef.loading';
    } else if (this.erpDiffs.length === 0) {
      return 'buttonUpdateRef.noRefs';
    } else {
      return 'buttonUpdateRef.hasRefs';
    }
  }

  onEnableEtition( showMessage: boolean = false ) {
    this.fetchingEditMode = true;
    this._dataStorage.setEditionMode(this.uid)
    .pipe(
      take(1),
      tap((datasheet) => {
        this.editionMode = true;
        this.fetchingEditMode = false;
        this.lockedParam = null;
        this._datasheetService.setDataSheet(datasheet);
      }),
      catchError((error) => {
        this.editionMode = this._datasheetService.isDatasheetStandalone();
        this.fetchingEditMode = false;
        if (error.status === 423) {
          // 423 Locked
          console.error('Recurso está bloqueado:', error.error);
          this.lockedParam = error.error;

          if( showMessage ) {
            const lockByMsg1 = this._translateService.instant('lock.lockByMsg1');
            const lockByMsg2 = this._translateService.instant('lock.lockByMsg2');
            const lockByMsg3 = this._translateService.instant(`lock.${this.lockedParam!.lockedSystem}`);
            this._snack.failMessage( `${lockByMsg1} ${this.lockedParam!.lockedBy} ${lockByMsg2} ${lockByMsg3}`, '',);
          }

        } 
        else if (error.status === 418) {
          this._snack.failMessage( this._translateService.instant("editionMode.isa") +  error.error, '',);
        }
        else {
          let msg = error.error.message || JSON.stringify(error.error);
          this._snack.failMessage( this._translateService.instant("editionMode.error") +  msg, '',);
          console.error('Ocorreu um erro:', error);
        }
        return of(null); 
      })
    )
    .subscribe();
  }

  
  private unlockOnUnload() {
      this._dataStorage.lockDatasheet(this.uid, false)
      .pipe(
        take(1),
        tap((data) => {
          console.info('unlock');
        }),
        catchError((error) => {
          console.info('unlock fails', error);
          return of(null); 
        })
      )
      .subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    this.unlockOnUnload();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    this.unlockOnUnload();
  }

}


