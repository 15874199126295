import { PropertiesEditorComponent } from './properties-editor.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DatasheetResolverService } from '../../services/datasheet-resolver.service';
import { HomeComponent } from '../home/home.component';
import { NotSavedDatasheetGuard } from 'src/app/guards/not-saved-datasheet.guard';
import { propEditorGuard } from 'src/app/guards/prop-editor.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/', pathMatch: 'full' },
      {
        path: 'new',
        component: PropertiesEditorComponent,
        canActivate: [propEditorGuard],
        canDeactivate: [NotSavedDatasheetGuard]
      },
      {
        path: ':uid',
        component: PropertiesEditorComponent,
        canDeactivate: [NotSavedDatasheetGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class PropertiesEditorRoutingModule {}
