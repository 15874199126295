import { Injectable } from '@angular/core';
import { ErpServiceService } from './erp-service.service';
import { DatasheetService } from './datasheet.service';
import { DialogService } from './dialog.service';
import { Reference } from '../models/reference';
import { Observable, ReplaySubject, Subject, catchError, mergeMap, of, take, takeUntil, throwError, toArray } from 'rxjs';
import { Datasheet, ErpDiff } from '../models/datasheet';
import { MessageDialogComponent, MessageDialogType } from '../components/message-dialog/message-dialog/message-dialog.component';
import { DialogErpLoginComponent } from '../components/erp-login-form/erp-login-form.component';
import { DataStorageService } from './data-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ErpReferencesService {

  private destroy$ = new Subject<boolean>();
  private erpDiffs = new Subject<ErpDiff[]>();

  public retryChanged = new ReplaySubject<boolean>(1);

  constructor(
    private _erpService: ErpServiceService,
    private _datasheetService: DatasheetService,
    private _dialog: DialogService,
    private _dataStorage: DataStorageService,
  ) {}

  public verifyErpReferences(): Observable<any> {
    const erpRefs = this._datasheetService.getDatasheet().getAllErpReferences();
    const updateRefs = this.filterErpReferences(erpRefs);

    return this._erpService.scanDatasheet( updateRefs ).pipe(
      takeUntil(this.destroy$),
      mergeMap((result: any) => this.processErpData(result, updateRefs)),
      catchError((error: any) => {
        this.handleErpErrors(error);
        return throwError(error);
      })
    );
  }

  private filterErpReferences(erpRefs: any[]): Reference[] {
    return erpRefs.filter(ref => ref !== null) as Reference[];
  }

  private processErpData(result: any, updateRefs: Reference[]): Observable<any> {
    const erpLst = [];
    for (let response of result) {
      let resArray = this._erpService.getResult( response.body );
        if (resArray.length === 0) {
            console.log('----> no erp data for url:', response.url);
            continue;
        }
        let erpData = resArray[0];
        erpLst.push(erpData);
    }
    let erpDiffs = this._datasheetService.getDatasheet().compare(updateRefs, erpLst);

    this.erpDiffs.next(erpDiffs);

    return of(erpDiffs);
  }

public applyDiffsToDatasheet(erpDiffs: any[], callback: (success: boolean) => void) {
  for (let diff of erpDiffs) {
    this._datasheetService.getDatasheet().applyDiff(diff);
  }
  this._dataStorage.storeDataSheet(
    () => {
      this.erpDiffs.next([]);
      callback(true);
    },
    () => callback(false)
  );
}

private handleErpErrors(error: any) {
    console.log('Ocorreu um erro:', error);
    if (error.status == 0) {
        console.log('====== ERP MAL CONFIGURADO:', error);
        const dialogRef = this._dialog.displayDialog(MessageDialogComponent, { "type": MessageDialogType.ERP_BAD_CONFIGURED }, '560px');
        return;
    } else if (error.status == 401 || error.status == 403) {
        console.log('====== ERP NAO AUTORIZADO:', error);
        const dialogRef = this._dialog.displayDialog(DialogErpLoginComponent, null, '560px');
        dialogRef
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (result) {
                    this.retryChanged.next(true);
                }
            });
    } else {
        console.log('====== ERP FALHOU:', error);
        // this._snack.failMessage(this.translate.instant('erp.addRefForm.step1.findFail'));
    }
  }

  public getErpDiffs(): Observable<any> {
    return this.erpDiffs.asObservable();
  }
}
