import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './localStorage.service';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { DatasheetService } from './datasheet.service';
import { Datasheet, FW } from '../models/datasheet';
import { StatusType } from '../models/enums/status-type';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Garment } from '../models/garment';
import { Variant } from '../models/variant';
import { Reference } from '../models/reference';


@Injectable({
  providedIn: 'root'
})
export class IdeaService {

  private ideaDatasheet = new Subject<any>();

  constructor(
    private http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _datasheetService: DatasheetService,
    private _confirmationService: ConfirmationService,
    private _translate: TranslateService,
    private _localStorage: LocalStorageService,
  ) {}

  getIdeaDatasheet() {
    return this.ideaDatasheet.asObservable();
  }

  notifyideaDatasheet(ds: any[]) {
    this.ideaDatasheet.next(ds);
  }

  postideax( ideaxFile: FormData ) {
    const access_token = this._localStorage.getAuthorizationToken();

    const cpn = JSON.parse(this._localStorageService.get(LocalStorageKeys.USER_CURRENT_COMPANY));

    const url = environment.IDEAX_SERVICE;
    const fwBckEnd = environment.API_URL.indexOf("localhost") >= 0 ? "fw360-dev.azurewebsites.net" : environment.API_URL;
    return this.http.post(`${url}/api/upload_ideax?access_token=${access_token}&cpn=${cpn.id.toString()}`, ideaxFile, {
      headers: new HttpHeaders({
          'X-FW': fwBckEnd
        })
      });
  }

  async setDatasheet( datasheet: Datasheet ) : Promise<boolean> {
    let currImgs: any[] = [];
    if(this._datasheetService.getDatasheet()){
      currImgs = this._datasheetService.getDatasheet().garment?.images || [];
    }
    datasheet.garment.images = currImgs.concat( datasheet.garment.images || [] );
    datasheet.garment.fields[0].read_only = false;
    if( this._datasheetService.getStatus() !== StatusType.SAVED ) {
      let confirmed = await this.confirm("shared.actions.newDatasheet.question");
      if (confirmed) {
        this._setDatasheet( datasheet );
      }
      return confirmed;
    }
    this._setDatasheet( datasheet );

    const ds = this._datasheetService.getDatasheet();
    this.applyMeasureConversionOnAllFields(datasheet.garment, ds);

    return true;
  }

  private _setDatasheet( datasheet: Datasheet ) {
    datasheet.fw = { ...datasheet.fw, ...this._datasheetService.getDatasheet().fw} as FW;
    this._datasheetService.setDataSheet( datasheet, true );
    this._datasheetService.setStatus( false );
  }

  confirm(message: any): Promise<boolean> {
    const header = this._translate.instant('shared.actions.newDatasheet.header');
    const acceptLabel = this._translate.instant('shared.actions.newDatasheet.acceptLabel');
    const rejectLabel = this._translate.instant('shared.actions.newDatasheet.rejectLabel');
    return new Promise((resolve) => {
      this._confirmationService.confirm({
        message: this._translate.instant( message ),
        header: header,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        acceptLabel: acceptLabel,
        rejectLabel: rejectLabel,
        rejectButtonStyleClass: 'p-button-text',
        key :"myDialog",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  applyMeasureConversionOnAllFields(data: Garment | Variant | Reference | any, datasheet: Datasheet, parentKey: string = '') {
    for (const key of Object.keys(data)) {
      const value = data[key as keyof typeof data];
      const fullPath = parentKey ? `${parentKey}.${key}` : key;

      if (value !== null && typeof value === 'object') {
        if (key === 'fields' && Array.isArray(value)) {
          value.forEach((field: any) => {

            const value = datasheet.applyMeasureConvertion(field.value ? field.value.toString() : "", field.name, fullPath, true);
            field.value = value;
          });
        } else {
          this.applyMeasureConversionOnAllFields(value, datasheet, fullPath);
        }
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          if (item !== null && typeof item === 'object') {
            this.applyMeasureConversionOnAllFields(item, datasheet, fullPath);
          }
        });
      }
    }
  }

}
