import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageType } from 'src/app/models/enums/message-type';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { GetDatasheetSummaryGQL, GetDatasheetSummaryQuery } from 'src/app/graphql/generated_graphql';
import { Observable, map, tap } from 'rxjs';
import { LocalStorageKeys } from 'src/app/models/enums/local-storage-keys';
import { MatExpansionPanel } from '@angular/material/expansion';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/services/dialog.service';
import { ConfigurationDialogComponent } from 'src/app/components/configuration-dialog/configuration-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UpdateIdeaxModalComponent } from 'src/app/components/update-ideax-modal/update-ideax-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ImportIdeaxDialogComponent } from 'src/app/components/import-ideax-dialog/import-ideax-dialog.component';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public searchTerm!: string;
  viewMode: any;
  allDS!: GetDatasheetSummaryQuery['get_sumary'];
  user!: string;
  dsSummary?: any[];
  MessageType = MessageType;
  variantesAgrupadas :any;
  isLoading: boolean = true;
  isLoadingImg: boolean = true;
  errorLoadingData: boolean = false;
  isProcessing: boolean = false;
  teste: any
  public get messageType(): typeof MessageType {
    return MessageType;
  }

  filter_options = [
    { value: 'garmentName', label: 'Modelo' },
    { value: 'last_modification_user', label: 'Usuário' },
    { value: 'collection', label: 'Coleção' },
    { value: 'isa_owner_user', label: 'Responsável' }
  ];

  order_options = [
    { value: 'asc', label: 'Crescente' },
    { value: 'desc', label: 'Decrescente' },
  ];

  order_options2 = [
    { value: 'creation_date', label: 'Data de Criação' },
    { value: 'last_modification_date', label: 'Última Edição' }
  ]

  selectedFilter: string = 'garmentName';
  selectedOrder: string = 'desc';
  selectedOrder2?: string = 'last_modification_date';
  actualPage: number = 1;
  totalItems: number = 0;

  constructor(
    private route: ActivatedRoute,
    private _storage: LocalStorageService,
    private homeGQL: GetDatasheetSummaryGQL,
    private router: Router,
    private _dialog: DialogService,
    private translate: TranslateService,
    private _titleService: Title,
    // private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initialOrReetryGetHomeData();
    this.viewMode = 'list';
    this.getUserData();
    // this.isMobile();
    this.translateFilterOptions();
    this.translateOrderOptions();
    this.translateOrderOptions2();
    
    this._titleService.setTitle(`ISA360`);
  }

  initialOrReetryGetHomeData() {
    const currentCompany = this._storage.getCurrentCompanyParam();
    this.getHomeData(currentCompany);
  }

  // private isMobile = (): void => {
  //   this.viewMode = (this.breakpointObserver.isMatched('(max-width: 800px)')) ? 'card' : 'list';
  // }

  getHomeData(isaId: number) {
    this.isProcessing = true;
    this.homeGQL.watch(
        { cpn: isaId, pageNumber: this.actualPage, pageSize: 10, filter: { field: this.selectedFilter, value: this.searchTerm, orderBy: this.selectedOrder2, orderDirection: this.selectedOrder } },
        { fetchPolicy: 'network-only' }
      )
      .valueChanges
      .pipe(
        map((res) => res.data.get_sumary)
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.dsSummary = data.items;
          this.totalItems = data.totalItems;
          this.errorLoadingData = false;
          this.isProcessing = false;
          this.isLoading = false;
        },
        (error) => {
          this.errorLoadingData = true;
          this.isProcessing = false;
        }
      );
  }

  getUserData() {
    var data = this._storage.getUserInfo();
    this.user = data.given_name;
  }

  groupVariantsByType(variants: string[]): { [key: string]: string[] } {
    const groupedVariants: { [key: string]: string[] } = {};
    let currentType = '';
    for (const variantName of variants) {
      const variantNameSplt = variantName.split('{%}');
      const type = variantNameSplt[0];
      if (type !== currentType) {
        currentType = type;
        groupedVariants[type] = [];
      }
      groupedVariants[currentType].push( variantNameSplt.join(" ") ) ;
    }
    return groupedVariants;
  }

  goToIsa(event: MouseEvent, id:any) {
    event.preventDefault();
      const ISA_ITEM = environment.ISA_ITEM;
      const isaItemUrl = `${ISA_ITEM}${id}`;
      this.openInNewTab(isaItemUrl)
  }

  openInNewTab(url: string) {
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert('A abertura da nova aba foi bloqueada. Verifique as configurações do seu navegador.');
    }
  }

  onImageError(event: any) {
    event.target.src = '/assets/images/homeImgPlaceholder.svg';
    this.isLoadingImg = false;
  }

  hideImageLoader() {
    this.isLoadingImg = false;
  }

  showErpDialog(){
    this._dialog.displayDialog(ConfigurationDialogComponent, { title: "erp.forms.title", type: "erp" }, '55%', '', 'dialog-responsive');
  }

  newDatasheet() {
    this.router.navigate(['/properties-editor', 'new'] );
  }

  newIdeaxDatasheet() {
    const dialogRef = this._dialog.displayDialog(ImportIdeaxDialogComponent, null, '560px');
    dialogRef.subscribe(result => {
      if(result == true) {
        this.router.navigate(['/properties-editor', 'new'], {
          queryParams: {
            import: 'idea'
          }
        });
      }
    })
  }

  navigate(uid: string){
    this.router.navigate(['/properties-editor', uid] );
  }

  onOrderRadioClick(value: string, group: 'selectedOrder' | 'selectedOrder2', event: MouseEvent): void {
    if (this[group] === value) {
      event.preventDefault();
    }
    event.stopPropagation();

    if (this[group] === value) {
      this[group] = '';
    } else {
      this[group] = value;
    }
    this.isLoading = true;
    this.initialOrReetryGetHomeData();

  }

  onSearchTermChange() {
    this.dsSummary = undefined;
    this.isLoading = true;

    this.initialOrReetryGetHomeData();
  }

  onFilterRadioClick(value: string, group: 'selectedFilter' , event: MouseEvent): void {
    if (this[group] === value) {
      event.preventDefault();
    }

    if (this[group] === value) {
      this[group] = '';
    } else {
      this[group] = value;
    }

    if(this.searchTerm && this.searchTerm != ''){
      this.initialOrReetryGetHomeData();
    }
  }

  onPageChange(page: number) {
    this.dsSummary = undefined;
    this.isLoading = true;
    this.actualPage = page;
    this.initialOrReetryGetHomeData()
  }

  getSelectedValue(){
    return this.filter_options.find(option => option.value === this.selectedFilter)?.label;
  }


  translateFilterOptions() {
    this.translate.get('homePage.filter_options').subscribe((translations: any) => {
      this.filter_options = [
        { value: 'garmentName', label: translations.garmentName },
        { value: 'last_modification_user', label: translations.last_modification_user },
        { value: 'collection', label: translations.collection },
        { value: 'isa_owner_user', label: translations.isa_owner_user }
      ];
    });
  }

  translateOrderOptions() {
    this.translate.get('homePage.order_options').subscribe((translations: any) => {
      this.order_options = [
        { value: 'asc', label: translations.asc },
        { value: 'desc', label: translations.desc }
      ];
    });
  }

  translateOrderOptions2() {
    this.translate.get('homePage.order_options2').subscribe((translations: any) => {
      this.order_options2 = [
        { value: 'creation_date', label: translations.creation_date },
        { value: 'last_modification_date', label: translations.last_modification_date }
      ];
    });
  }
}
