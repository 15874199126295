import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[formatDecimal]'
})
export class DecimalDirective {
  private regexStr: string = `^\\d*[.,]?\\d{0,12}$`;
  private regex: RegExp = new RegExp( this.regexStr );
  private regexPaste: RegExp = new RegExp(`^\\d*[.,]?\\d{0,69}$`);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.formatInitialValue();
  }

  private formatInitialValue() {
    let currentValue = this.el.nativeElement.value;
    if (currentValue) {
      this.el.nativeElement.value = this.truncateDecimal(currentValue);
    }
  }

  private truncateDecimal(value: string): string {
    let numericValue = parseFloat(value.replace(',', '.'));

    if (isNaN(numericValue)) {
      return value;
    }

    const scientificNotation = numericValue.toExponential();
    const [mantissa, exponent] = scientificNotation.split('e');
    const truncatedMantissa = (+mantissa).toFixed(3);
    const formattedValue = parseFloat(`${truncatedMantissa}e${exponent}`).toFixed(12);

    return parseFloat(formattedValue).toString();
  }

  private countZeros(str: string): number {
    if (str.length < 2) return 0;
    
    let count0 = 0;
    for( const nro of str ) {
      if( nro == '0' ) {
        count0++
      } else {
        break;
      }
    }
    
    return count0 + 3;
  }

  private dynamic_regex( value: string ) : RegExp {
    const mantissa = value.replace(',', '.').split('.')[1];
    if( mantissa ) {
      const length = this.countZeros( mantissa );
      return new RegExp( this.regexStr.replace('12', `${length+1}`) );
    } else {
      return this.regex;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Permitir atalhos com Ctrl ou Cmd (como Ctrl+C, Ctrl+V, Ctrl+X, Ctrl+A)
    if ((event.ctrlKey || event.metaKey) && ['c', 'v', 'x', 'a'].includes(event.key.toLowerCase())) {
      return;
    }

    let current: string = this.el.nativeElement.value;

    const start = this.el.nativeElement.selectionStart;
    const end = this.el.nativeElement.selectionEnd;

    if (start === 0 && end === current.length) {
      current = '';
    }

    const newValue: string = current.slice(0, start) + event.key + current.slice(end);

    if (newValue && !String(newValue).match(this.dynamic_regex(newValue))) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedInput: string = clipboardData.getData('text');

    if (!pastedInput.match(this.regexPaste)) {
      event.preventDefault();
    } else {
      // Ajusta o valor colado
      const formattedValue = this.truncateDecimal(pastedInput);
      
      this.emitBlurEvent();
      setTimeout(() => {
        this.el.nativeElement.value = formattedValue;
        this.emitBlurEvent();
      });
    }
  }

  private emitBlurEvent(): void {
    this.el.nativeElement.blur(); // Tira o foco do elemento
    const event = new Event('blur', { bubbles: true, cancelable: true });
    this.el.nativeElement.dispatchEvent(event); // Dispara o evento
  }
}