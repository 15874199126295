import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class IsaService {

  private isacollection = new Subject<any[]>();
  private isaShapes: any[] = [];

  constructor( 
    private http: HttpClient,
    private _translate: TranslateService,
  ) {}

  getIsacollection() {
    return this.isacollection.asObservable();
  }

  notifyIsacollection(ds: any[]) {
    this.isacollection.next(ds);
  }

  setIsaShapes(shapes : any[]) {
    this.isaShapes = shapes;
  }

  getIsaItemName(item: any) {
    if( item.name ) {
      return item.name;
    }
    
    for( let sh of this.isaShapes) {
      if (!item.name && item.shapeId == sh.id) {
        return this._translate.instant(`isaShapes.${sh.name}`);
      }
    }

    if( item.shape ) {
      return this._translate.instant(`isaShapes.${item.shape.name}`)
    }
    return '';
  }

  getIsaAllCollections(access_token: string, offset?: number) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
    query paginatedCollections($where: CollectionFilter, $offset: Int!, $limit: Int!, $ordenation: CollectionOrdenation!, $isDescending: Boolean) {
      paginatedCollections(where: $where, offset: $offset, limit: $limit, ordenation: $ordenation, isDescending: $isDescending) {
        collections {
          ...basicCollectionFields
        }
        offset
        limit
        currentPage
        pageCount
      }
    }

    fragment uploadedResourcesFields on UploadedResource {
      id
      reference
      previewReference
      pdfReference
      filename
      rawFilename
      version
      latest
      thumbReference
    }

    fragment basicSubCollectionFields on SubCollection {
      id
      name
      startDate
      endDate
      itemsNumber
      items {
        ...itemFieldsWithoutHistory
      }
      allCategories {
        id
        name
        items {
          ...itemFieldsWithoutHistory
        }
        parent {
          id
        }
      }
    }

    fragment itemFieldsWithoutHistory on Item {
      id
      name
      description
      position
      collectionId
      coverReferenceSize
      assignee { id name email }
      colors {
        id
        name
        hexadecimalCode
        position
      }
      coverReferences {
        id
        coverReference
        thumbReference
        position
        itemSrcId
        coverReferenceSize
      }
      coverImageReference
      coverThumbReference
      approvedIn
      shapeId
      shape {
        id
        name
        coverReference
        fit
      }
      datasheetUid
      sourceItemId
      variantParentId
      variants {
        id
        name
      }
      steps {
        id
        name
        status
      }
    }

    fragment basicCollectionFields on Collection {
      id
      status
      name
      season
      releaseYear
      startedAt
      colors {
        id
        name
        hexadecimalCode
        position
      }
      coverImage {
        ...uploadedResourcesFields
      }
      responsible {
        id
        name
      }
      subCollections {
        ...basicSubCollectionFields
      }
      steps {
        id
        name
      }
    }
  `;

  const variables = {
    "offset": offset,
    "limit": 1,
    "ordenation": "Lastmodified",
    "isDescending": true
  }

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )
  }

  getIsaShapes(access_token: string) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
    query getAllCompanyShapes {
      shapes {
        ...shapeFields
      }
    }

    fragment shapeFields on Shape {
      id
      name
      coverReference
      fit
    }
  `;

  const variables = {};

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )
  }

  getIsaItem(access_token: string, isaModelId: string) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
    query getItem($itemId: ID!) {
      item(itemId: $itemId) {
        ...itemFields
      }
    }

    fragment itemFields on Item {
      id
      name
      description
      position
      collectionId
      coverReferenceSize
      coverReferences {
        id
        coverReference
        thumbReference
        position
        itemSrcId
        coverReferenceSize
      }
      coverImageReference
      coverThumbReference
      approvedIn
      properties {
        key
        value
        choices
      }
      assignee {
        id
        name
        email
      }
      shapeId
      shape {
        id
        name
        coverReference
        fit
      }
      sourceItemId
      variantParentId
      variants {
        id
        name
        shapeId
      }
      steps {
        id
        name
      }
      createdAt
      datasheetUid
    }
  `;

  const variables = {
    "itemId": isaModelId
  };

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )
  }

  getIsaCollection(access_token: string, isaCollectionId: string) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
      query collections($collectionsIDs: [ID]!) {
        collections(ids: $collectionsIDs) {
          ...collectionFields
        }
      }

      fragment collectionFields on Collection {
        id
        status
        name
        season
        releaseYear
        budget
        currency
        sequential
        targetPrice
        startedAt
        archivedDate
      }
  `;

  const variables = {
    "collectionsIDs": [isaCollectionId]
  };

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )
  }

  postIsaItem(access_token: string, isaModelId: string, datasheetUid?: string|null, modelName?: string|null, modelDescription?: string|null, imgList?: any[]|null) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
      mutation UpdateItemIntegration(
        $itemId: ID!,
        $datasheetUid: ID,
        $modelName: String,
        $modelDescription: String,
        $imgList: [ImgInput]
      ) {
        updateItemIntegration(
          itemId: $itemId,
          datasheetUid: $datasheetUid,
          modelName: $modelName,
          modelDescription: $modelDescription,
          imgList: $imgList
        )
      }
    `;

    const variables = {
      "itemId": isaModelId,
      "datasheetUid": datasheetUid,
      "modelName": modelName,
      "modelDescription": modelDescription,
      "imgList": imgList,
    };

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )
  }

  getIsaModelFields(access_token: string, isaModelId: string){
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `

    query GetCollectionPropertiesForItem($itemId: ID!) {
      getCollectionPropertiesForItem(itemId: $itemId) {
        key
        value
        type
        choices
        defaultChoices
      }
    }
    `;

    const variables ={
      "itemId": isaModelId,
    }

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers}
    )

  }

  putIsaModelFields(access_token: string, isaModelId: string, properties: any) {
    const headers = {
      'Authorization': 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    };

    const query = `
      mutation setIntegrationFWItemProperties(
          $itemId: ID!
          $properties: [FWIdeaxTechsheetPropertiesInput]
        ) {
          setIntegrationFWItemProperties(itemId: $itemId, properties: $properties)
        }
    `;

    const variables = {
      itemId: isaModelId,
      properties: properties
    };

    const body = {
      query,
      variables
    };

    return this.http.post<any>(
      environment.ISA_GRAPHQL,
      body,
      { headers: headers }
    );
  }

  handleImages( item: any ) {
    let images = item.coverImageReference ? [item.coverImageReference] : [''];

    for( const img of item.coverReferences || [] ){
      if( img.coverReference )
        images.push(img.coverReference);
    }
    
    return images;
  }

}
