<div class="drawer_print_container" *ngIf="(printData | json) != ({} | json)">
  <ng-container>
    <div class="drawer_print_container__header">
      <span class="drawer_print_container__header_title">
        {{ 'printPage.title'  | translate }}
      </span>
      <button mat-icon-button (click)="closerDrawer()">
        <mat-icon >close</mat-icon>
      </button>
    </div>
    <mat-tab-group class="drawer_print_container__tab-group" animationDuration="0ms">
      <mat-tab label="{{ 'printPage.tabMenu.tabs.layout.label' | translate }}">
        <div class="drawer_print_container__tab-group_layout-container">
          <!-- <div class="drawer_print_container__tab-group_layout-container_orientation">
            <div class="drawer_print_container__tab-group_layout-container_tittle">
              <span>Orientação da página</span>
            </div>
            <mat-form-field ng-size=8 class="drawer_print_container__tab-group_layout-container_orientation_select" appearance="outline">
              <mat-select [(ngModel)]="selectedOrientation">
                <mat-select-trigger>
                  <div class="selected-icon-container">
                    <mat-icon class="material-icons-round selected-icon" *ngIf="selectedOrientation === 'portrait'">crop_portrait</mat-icon>
                    <mat-icon class="material-icons-round selected-icon" *ngIf="selectedOrientation === 'landscape'">crop_landscape</mat-icon>
                    <span>{{ selectedOrientation === 'portrait' ? 'Retrato' : 'Paisagem' }}</span>
                  </div>
                </mat-select-trigger>
                <mat-option value="portrait">
                  <div class="option-container">
                    <mat-icon matPrefix class="material-icons-round">crop_portrait</mat-icon>
                    <span>Retrato</span>
                  </div>
                </mat-option>
                <mat-option value="landscape">
                  <div class="option-container">
                    <mat-icon matPrefix class="material-icons-round">crop_landscape</mat-icon>
                    <span>Paisagem</span>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="drawer_print_container__tab-group_layout-container_logo">
            <div class="drawer_print_container__tab-group_layout-container_tittle">
              <span style="font-weight: bold;">
                {{ 'printPage.tabMenu.tabs.layout.addLogo' | translate }}
              </span>
            </div>
            <div (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)"
              [class.drag-over]="isDragOver"
              class="drawer_print_container__tab-group_layout-container_logo_selector">
              <div *ngIf="!imageUrl">
                <div>
                  <img *ngIf="!imageUrl" class="drawer_print_container__tab-group_layout-container_logo_selector_img"
                    src="../../../assets/images/Upload-FW.svg">
                </div>
                <span>{{ 'printPage.tabMenu.tabs.layout.fileInput.releaseText' | translate }} </span>
                <label for="fileInput" class="file-label"><span style="font-weight: bold; cursor: pointer;"> {{ 'printPage.tabMenu.tabs.layout.fileInput.selectImageText' | translate }}</span></label>
                <input id="fileInput" type="file" (change)="onFileSelected($event)" accept="image/*"
                  style="display: none;">
                <p>{{ 'printPage.tabMenu.tabs.layout.fileInput.imageRecommendationText' | translate }}</p>
              </div>
              <div *ngIf="imageUrl" style="position: relative;">
                <img [src]="imageUrl" alt="Imagem Selecionada"
                  class="drawer_print_container__tab-group_layout-container_logo_selector_thumb">
                <button class="remove-icon" (click)="removeLogo()"><span class="material-icons-round" >
                    delete_outline
                  </span></button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'models.garment' | translate }}">
        <div class="drawer_print_container__tab-group_expand">
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <div class="drawer_print_container__tab-group_expand_header">
                <mat-checkbox class="" color="primary" (click)="togleTableVisibility($event, 'Model')"
                  [checked]=true></mat-checkbox>
                <span class="drawer_print_container__tab-group_expand_title">
                  {{ 'printPage.tabMenu.tabs.garment.accordions.garmentInfo.label' | translate }}
                </span>
              </div>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.model.header.length > 5"(searchTermEmmit)="searchTermHandler($event, 'model' )"></app-serch-bar>
              <mat-checkbox class="" color="primary"
                class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Model') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Model') === 'checked'" (change)="onCheckboxChange($event, 'Model')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let modelHeaders of printData.model.header | appFilter :searchTerms['model'], let i = index">
                <mat-checkbox class="" color="primary"
                  class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.model.headerIndex.includes(i)" (click)="toggleItem('Model', i)">
                  <span [innerHTML]="modelHeaders | translate | highlight : searchTerms['model']"></span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <div class="drawer_print_container__tab-group_expand_header">
                <mat-checkbox color="primary" (click)="togleTableVisibility($event,'ModelImg')"
                  [checked]=true></mat-checkbox>
                <span class="drawer_print_container__tab-group_expand_title">
                  {{ 'printPage.tabMenu.tabs.garment.accordions.garmentImages.label' | translate }}
                </span>
              </div>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
            </div>
            <ng-container *ngFor="let modelImages of printData.imagesModel.images, let i = index">
              <div class="image-card">
                <mat-checkbox class="checkbox" color="primary"
                  [checked]="printData.imagesModel.visibleIndex.includes(i)"
                  (click)="toggleItem('ModelImages', i)"></mat-checkbox>
                <img src={{modelImages.url | appRemoveS3Link }} class="image">
              </div>
            </ng-container>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'models.variants' | translate }}">
        <div class="drawer_print_container__tab-group_expand">
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <div class="drawer_print_container__tab-group_expand_header">
                <mat-checkbox class="" color="primary" (click)="togleTableVisibility($event,'Variant')"
                  [checked]="printData.variants.headerIndex.length != 0">
                </mat-checkbox>
                <span class="drawer_print_container__tab-group_expand_title">
                  {{ 'shared.actions.selectVariants.label' | translate }}
                </span>
              </div>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.variants.rows.length > 5" (searchTermEmmit)="searchTermHandler($event, 'variant' )"></app-serch-bar>
              <mat-checkbox class="" color="primary"
                class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Variant') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Variant') === 'checked'"
                (change)="onCheckboxChange($event, 'Variant')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let variantInfo of printData.variants.rows | appFilter :searchTerms['variantRow'], let i = index">
                <mat-checkbox class="" color="primary"
                  class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.variants.variantIndex.includes(i)" (click)="toggleItem('Variant', i)">
                  <span [innerHTML]="variantInfo.row[0] | translate | highlight : searchTerms['variant']"></span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <mat-panel-title>
                <div class="drawer_print_container__tab-group_expand_header">
                  <!-- <mat-checkbox color="primary" (click)="togleTableVisibility($event,'VariantInfo')"  [checked]=true> -->
                  <!-- </mat-checkbox> -->
                  <span class="drawer_print_container__tab-group_expand_title">
                    {{ 'printPage.tabMenu.tabs.variants.accordions.variantsInfo.label' | translate }}
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar  *ngIf="printData.variants.header.length > 5" (searchTermEmmit)="searchTermHandler($event, 'variantInfo' )"></app-serch-bar>
              <mat-checkbox class="" color="primary"
                class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('VariantInfo') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('VariantInfo') === 'checked'"
                (change)="onCheckboxChange($event, 'VariantInfo')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let variantInfo of printData.variants.header | appFilter :searchTerms['variantInfo'], let i = index">
                <mat-checkbox class="" color="primary"
                  class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.variants.headerIndex.includes(i)" (click)="toggleItem('VariantInfo', i)">
                  <span [innerHTML]="variantInfo | translate | highlight : searchTerms['variantInfo']"></span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'models.references' | translate }}">
        <div class="drawer_print_container__tab-group_expand">
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <div class="drawer_print_container__tab-group_expand_header">
                <mat-checkbox class="" color="primary" (click)="togleTableVisibility($event,'Material')" [checked]=true>
                </mat-checkbox>
                <span class="drawer_print_container__tab-group_expand_title">
                  {{ 'models.reference.materials' | translate }}
                </span>
              </div>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.modelReferences.material.header.length > 5" (searchTermEmmit)="searchTermHandler($event, 'material' )"></app-serch-bar>
              <mat-checkbox color="primary" class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Material') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Material') === 'checked'"
                (change)="onCheckboxChange($event, 'Material')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let materialsHeader of printData.modelReferences.material.header | appFilter :searchTerms['material'], let i = index">
                <mat-checkbox class="" color="primary"
                  class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.modelReferences.material.headerIndex.includes(i)"
                  (click)="toggleItem('Material', i)">
                  <span [innerHTML]="materialsHeader | translate | highlight : searchTerms['material']">{{materialsHeader | translate}}</span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <mat-panel-title>
                <div class="drawer_print_container__tab-group_expand_header">
                  <mat-checkbox color="primary" (click)="togleTableVisibility($event,'Activity')" [checked]=true>
                  </mat-checkbox>
                  <span class="drawer_print_container__tab-group_expand_title">
                    {{ 'models.reference.activities' | translate }}
                  </span>
                </div>
              </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.modelReferences.activity.header.length > 5" (searchTermEmmit)="searchTermHandler($event, 'activity' )"></app-serch-bar>
              <mat-checkbox class="" color="primary"
                class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Activity') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Activity') === 'checked'"
                (change)="onCheckboxChange($event, 'Activity')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let activityHeader of printData.modelReferences.activity.header | appFilter :searchTerms['activity'], let i = index">
                <mat-checkbox class="" color="primary"
                  class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.modelReferences.activity.headerIndex.includes(i)"
                  (click)="toggleItem('Activities', i)">
                  <span [innerHTML]="activityHeader | translate | highlight : searchTerms['activity']">{{activityHeader | translate}}</span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <mat-panel-title>
                <div class="drawer_print_container__tab-group_expand_header">
                  <mat-checkbox color="primary" (click)="togleTableVisibility($event,'Measures')" [checked]=true>
                  </mat-checkbox>
                  <span class="drawer_print_container__tab-group_expand_title">
                    {{ 'models.reference.measurements' | translate }}
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.modelReferences.measure.header.length > 5" (searchTermEmmit)="searchTermHandler($event, 'measure')"></app-serch-bar>
              <mat-checkbox class="" color="primary"
                class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Measures') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Measures') === 'checked'"
                (change)="onCheckboxChange($event, 'Measures')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let measuresHeader of printData.modelReferences.measure.header | appFilter :searchTerms['measures'], let i = index">
                <mat-checkbox color="primary" class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.modelReferences.measure.headerIndex.includes(i)"
                  (click)="toggleItem('Measures', i)">
                  <span [innerHTML]="measuresHeader | translate | highlight : searchTerms['measure']">{{measuresHeader | translate}}</span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <mat-panel-title>
                <div class="drawer_print_container__tab-group_expand_header">
                  <mat-checkbox color="primary" (click)="togleTableVisibility($event,'Generic')" [checked]=true>
                  </mat-checkbox>
                  <span class="drawer_print_container__tab-group_expand_title">
                    {{ 'models.reference.generics' | translate }}
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">
              <app-serch-bar *ngIf="printData.modelReferences.generic.header.length > 5" (searchTermEmmit)="searchTermHandler($event, 'generic' )"></app-serch-bar>
              <mat-checkbox color="primary" class="drawer_print_container__tab-group_expand_content-container_content"
                [indeterminate]="verifyCheckBoxStatus('Generic') === 'indeterminate'"
                [checked]="verifyCheckBoxStatus('Generic') === 'checked'"
                (change)="onCheckboxChange($event, 'Generic')">
                <span>
                  {{ 'shared.actions.checkUncheckAll.label' | translate }}
                </span>
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <div class="drawer_print_container__tab-group_expand_content-container">
              <ng-container
                *ngFor="let genericHeader of printData.modelReferences.generic.header | appFilter :searchTerms['generic'], let i = index">
                <mat-checkbox color="primary" class="drawer_print_container__tab-group_expand_content-container_content"
                  [checked]="printData.modelReferences.generic.headerIndex.includes(i)"
                  (click)="toggleItem('Generic', i)">
                  <span [innerHTML]="genericHeader | translate | highlight : searchTerms['generic']">{{genericHeader | translate}}</span>
                </mat-checkbox>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
          <mat-expansion-panel>
            <mat-expansion-panel-header style="padding: 0 12px">
              <mat-panel-title>
                <div class="drawer_print_container__tab-group_expand_header">
                  <mat-checkbox color="primary" (click)="togleTableVisibility($event,'ReferenceImg')" [checked]=true>
                  </mat-checkbox>
                  <span class="drawer_print_container__tab-group_expand_title">
                    {{ 'models.reference.images' | translate }}
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="drawer_print_container__tab-group_expand_search">

            </div>
            <div class="card-container">
              <ng-container *ngFor="let referenceImages of printData.imagesReference.images, let i = index">
                <div class="image-card">
                  <mat-checkbox #checkboxes[i] class="checkbox" color="primary"
                    [checked]="printData.imagesReference.visibleIndex.includes(i)"
                    (click)="toggleItem('ReferencesImages', i)"></mat-checkbox>
                  <img src={{referenceImages.url | appRemoveS3Link}} class="image">
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="drawer_print_container__footer">
      <button class="drawer_print_container__footer_btn" mat-raised-button color="primary" (click)="print()">
        <mat-icon>print</mat-icon>
        {{ 'shared.actions.print.label' | translate }}
      </button>
    </div>
  </ng-container>
</div>
